import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import {
  toLocalTime
} from '../../../../functions';
import {
  selectedRentalTimeSlot
} from '../../../../atoms/Atoms';

function RentalSlotCard({ timeSlot }) {
  const { t } = useTranslation();
  const setSelectedTimeSlot = useSetRecoilState(selectedRentalTimeSlot);

  return (
    <button
      type="button"
      key={timeSlot.id}
      onClick={() => setSelectedTimeSlot(timeSlot)}
      className={`${'text-accent'} w-full truncate my-4 disabled:cursor-not-allowed flex text-left text-xs font-medium hover:text-accent outline-none focus:outline-none transition duration-500 ease-in-out line-clamp-4`}
    >
      <div className={`mb-0 mx-4 ${timeSlot.isFull ? 'bg-red-200' : 'bg-green-200'} rounded-xl p-4 border ${timeSlot.isFull ? 'border-red-400' : 'border-green-400'}  text-gray-700 text-md`}>
        <div className="text-md">{`${toLocalTime(timeSlot.startTime).format('h:mm A')}-${toLocalTime(timeSlot.endTime).format('h:mm A')}`}</div>
        <div className="text-md">{`${dayjs(timeSlot.endTime).diff(dayjs(timeSlot.startTime), 'minutes')} minutes`}</div>
        <div className="text-md">
        <div
          className={`${timeSlot.isFull ? 'text-red-400' : 'text-green-600'} disabled:cursor-not-allowed text-md font-medium tracking-tight text-left flex items-center space-x-2 w-full mt-2 leading-none hover:text-accent transition duration-500 easy-in-out truncate line-clamp-1`}
        >
          <span>{`${timeSlot.isFull ? t('booked') : t('available')}`}</span>
        </div>
        </div>
      </div>
    </button>
  );
}

export default RentalSlotCard;

/* eslint-disable no-confusing-arrow,react/jsx-curly-newline */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import envConfig from '../envConfig';

function ProtectedRoute({ component: Component, ...rest }) {
  const [user] = useLocalStorage(`${envConfig.StorageKey}-user`);

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Route
            {...rest}
            render={(routeProps) => <Component {...routeProps} />}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;

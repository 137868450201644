import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom } from '../../../atoms/Atoms';

function BookingsHome() {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);

  const faqs = [
    {
      question: t('faqQ1'),
      answer:
        t('faqA1'),
    },
    {
      question: t('faqQ2'),
      answer:
      t('faqA2'),
    },
    {
      question: t('faqQ3'),
      answer:
      t('faqA3'),
    },
    {
      question: t('faqQ4'),
      answer:
      t('faqA4'),
    },
    {
      question: t('faqQ5'),
      answer:
      t('faqA5'),
    },
    // More questions...
  ]

  return (
    <div className="bg-white py-24 pb-24 overflow-auto sm:py-32">
      <div className="relative bg-gray-900">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
        <img
          className="h-full w-full object-cover"
          src="https://mecacomplexstorage.blob.core.windows.net/sharedassets/esbriclasses.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&blend=6366F1&sat=-100&blend-mode=multiply"
          alt=""
        />
        <svg
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute -bottom-24 left-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">{t('classes_header')}</h2>
          <p className="mt-2 leading-7 text-gray-200">
          {t('learn_with_us')}
          </p>
          <p className="mt-2 text-xl font-semibold tracking-tight text-white sm:text-xl">{t('classes_subheader')}</p>
        </div>
      </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center hidden">
          <h2 className="text-lg text-base font-semibold leading-7 text-indigo-600">{t('classes_header')}</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {t('learn_with_us')}
          </p>
        </div>
        <p className="hidden mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">
          {t('classes_subheader')}
        </p>
        <div className="mt-20 flow-root">
          <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-y-0 lg:divide-x xl:-mx-4">
            <div>
              <div key="drop-in" className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id="drop-in" className="text-base font-semibold leading-7 text-gray-900">
                  Drop-In
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$35</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600 lower">/{t('class').toLowerCase()}</span>
                </p>
                <p className="mt-3 text-sm leading-6 text-gray-500">{t('subject_availability')}</p>
                <a
                  href="/classes/dropin"
                  aria-describedby="drop-in"
                  className="mt-10 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t('explore_availability')}
                </a>
                <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{t('dropin_description')}</p>
                <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                    <li className="flex gap-x-3">
                    {t('dropin_bullet_1')}
                    </li>
                    <li className="flex gap-x-3">
                    {t('dropin_bullet_2')}
                    </li>
                    <li className="flex gap-x-3">
                    {t('dropin_bullet_3')}
                    </li>
                    <li className="flex gap-x-3">
                    {t('dropin_bullet_4')}
                    </li>
                </ul>
              </div>
            </div>
            <div>
              <div key="paquetes" className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id="paquetes" className="text-base font-semibold leading-7 text-gray-900">
                  {t('packages')}
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$200</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">/{t('month')}</span>
                </p>
                <p className="mt-3 text-sm leading-6 text-gray-500">{t('reserve_your_spot')}</p>
                {/* <a
                  href="/subscriptions"
                  aria-describedby="paquetes"
                  className="mt-10 block rounded-md bg-indigo-600 py-2 px-3 text-center
                   text-sm font-semibold leading-6 text-white shadow-sm 
                   hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                    focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t('explore_availability')}
                </a> */}
                <a
                  href="mailto:bookings@mecacomplex.com"
                  aria-describedby="paquetes"
                  className="mt-10 block rounded-md bg-indigo-600 py-2 px-3 text-center 
                   text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500
                   focus-visible:outline focus-visible:outline-2
                    focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t('contact_us')}
                </a>
                <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{t('package_description')}</p>
                <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                    <li className="flex gap-x-3">
                     {t('package_bullet_1')}
                    </li>
                    <li className="flex gap-x-3">
                     {t('package_bullet_2')}
                    </li>
                    <li className="flex gap-x-3">
                     {t('package_bullet_3')}
                    </li>
                </ul>
              </div>
            </div>
            <div>
              <div key="private" className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id="private" className="text-base font-semibold leading-7 text-gray-900">
                  {t('private_and_semi_private_classes')}
                </h3>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$80</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600 ">/{t('class').toLocaleLowerCase()}</span>
                </p>
                <p className="mt-3 text-sm leading-6 text-gray-500">{t('classes_group_price')}</p>
                <a
                  href="mailto:bookings@mecacomplex.com"
                  aria-describedby="paquetes"
                  className="mt-10 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t('contact_us')}
                </a>
                <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{t('privates_description')}</p>
                <ul className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                    <li className="flex gap-x-3">
                    {t('privates_bullet_1')}
                    </li>
                    <li className="flex gap-x-3">
                    {t('privates_bullet_2')}
                    </li>
                    <li className="flex gap-x-3">
                    {t('privates_bullet_3')}
                    </li>
                    <li className="flex gap-x-3">
                    {t('privates_bullet_4')}
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white mt-8">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">{t('faq_header')}</h2>
            <p className="mt-4 text-base leading-7 text-gray-600">
              {t('faqsubheader')}{' '}
              <a href="mailto:bookings@mecacomplex.com" className="font-semibold text-indigo-600 hover:text-indigo-500">
                {t('bookings_team')}
              </a>.
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
                <div>
                  <dt className="font-semibold leading-7 text-purple-600">{t('faqQ1')}</dt>
                  <dd className="mt-2 leading-7 text-gray-600">{t('faqA1')}</dd>
                  <dt className="font-semibold leading-7 text-purple-600">{t('faqQ2')}</dt>
                  <dd className="mt-2 leading-7 text-gray-600">{t('faqA2')}</dd>
                  <dt className="font-semibold leading-7 text-purple-600">{t('faqQ3')}</dt>
                  <dd className="mt-2 leading-7 text-gray-600">{t('faqA3')}</dd>
                  <dt className="font-semibold leading-7 text-purple-600">{t('faqQ4')}</dt>
                  <dd className="mt-2 leading-7 text-gray-600">{t('faqA4')}</dd>
                  <dt className="font-semibold leading-7 text-purple-600">{t('faqQ5')}</dt>
                  <dd className="mt-2 leading-7 text-gray-600">{t('faqA5')}</dd>
                </div>
            </dl>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default BookingsHome;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import envConfig from '../../../../envConfig';

function SocialFooter1() {
  return (
    <div className="flex flex-col items-center justify-center text-xs text-gray-500 p-1">
      <div className="space-x-2 text-lg text-gray-400 hover:text-gray-600 transition duration-500 ease-in-out">
        <a
          target="_blank"
          rel="noreferrer"
          hidden={envConfig.FacebookLink === ''}
          href={envConfig.FacebookLink}
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          hidden={envConfig.InstagramLink === ''}
          href={envConfig.InstagramLink}
        >
          <FontAwesomeIcon icon={faInstagramSquare} />
        </a>
      </div>
    </div>
  );
}

export default SocialFooter1;

import React, { useState } from 'react';
import _ from 'lodash';
import {
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
} from '@fortawesome/pro-duotone-svg-icons';
import useBreakpoint from '../../../hooks/useBreakpoint';
import Image1 from './shared-components/images/image_1.png';
import Image2 from './shared-components/images/image_2.png';
import Image3 from './shared-components/images/image_3.png';

function ImageCarousel() {
const { t } = useTranslation();
const [activeItemIndex, setActiveItemIndex] = useState(0);
const breakpoint = useBreakpoint();
const images = [    
  {
    key: 1,
    alt: 'one',
    imageUrl: Image1,
    type: 'image'
  },
  {
    key: 2,
    alt: 'two',
    imageUrl: Image2,
    type: 'image'
  },
  {
      key: 3,
      alt: 'three',
      imageUrl: Image3,
      type: 'image'
    },
];

  return (
    <div className="flex justify-center space-y-4 py-4 bg-white">
       {images && (
        <div className="w-1/4" hidden={breakpoint && breakpoint < 4}>
          <ItemsCarousel
            gutter={10}
            infiniteLoop
            activePosition="center"
            chevronWidth={50}
            disableSwipe={false}
            alwaysShowChevrons
            numberOfCards={1}
            slidesToScroll={1}
            showSlither            
            firstAndLastGutter
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={
              <button
                type="button"
                hidden={images && images.length < 2}
                className="flex items-center justify-center h-10 w-10 bg-base shadow rounded-full text-white hover:bg-accentdark transition duration-300 ease-linear"
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </button>
            }
            leftChevron={
              <button
                type="button"
                hidden={images && images < 2}
                className="flex items-center justify-center h-10 w-10 bg-base shadow rounded-full text-white hover:bg-accentdark transition duration-300 ease-linear"
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </button>
            }
          >
            {_.map(images,
              (s) => (
                <div key={s.key} className="mx-auto">
                  {!s.action && (
                    <img
                      alt={s.name}
                      src={s.imageUrl}
                      className="h-52 mx-auto"
                    />
                  )}
                  {s.action && (
                    <button
                      type="button"
                      className="m-5 mx-auto"
                      onClick={() => {
                        if (s.action.includes('openlink?url=')) {
                          const url = s.action.replace('openlink?url=', '');
                          window.open(url, '_blank').focus();
                        }
                      }}
                    >
                      <img
                        alt={s.name}
                        src={s.imageUrl}
                        className="h-auto mx-auto"
                      />
                    </button>
                  )}
                </div>
              )
            )}
          </ItemsCarousel>
        </div>
      )}
    </div>
  );
}

export default ImageCarousel;

import React from 'react';
import _ from 'lodash';

function Placeholder({ quantity, initial }) {
  return (
    <div
      className={`${
        initial ? 'mt-0' : 'mt-4'
      } grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4`}
    >
      {_.times(quantity, (e) => (
        <div
          key={e}
          className="h-72 flex flex-col justify-center items-center bg-white rounded-xl shadow p-4"
        >
          <div className="bg-gray-100 h-32 w-32 mb-2 rounded-md animate-pulse" />
          <div className="bg-gray-100 h-2 w-10 rounded-full mx-auto mb-1 animate-pulse" />
          <div className="bg-gray-100 h-2 w-full rounded-full mx-auto mb-1 animate-pulse px-4" />
          <div className="bg-gray-100 h-2 w-16 rounded-full mx-auto mb-1 animate-pulse" />
          <div className="bg-gray-100 h-2 w-10 rounded-full mx-auto mb-1 animate-pulse" />
        </div>
      ))}
    </div>
  );
}

export default Placeholder;

import React, { useEffect } from 'react';
import _ from 'lodash';
// import Banner from '../../shared-components/banners/Banner';
import envConfig from '../../../../envConfig';
import Checkout1 from './CheckoutV2';
import Checkout10 from './CheckoutV10';


function Checkout() {
  if (envConfig.StoreVersion === '1') {
    return (
      <Checkout1 />
    )
  } 
  if (envConfig.StoreVersion === '10') {
    return (
      <Checkout10 />
    )
  } 
  return (
    <Checkout10 />
  )
} 

export default Checkout;

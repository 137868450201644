import React, { useRef, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamation,
  faTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react/cjs/react.production.min';
import { XIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import {
  alertAtom,
  locationAtom,
  orderAtom,
  serviceAtom,
  sidebarAtom,
  storeAtom,
} from '../../../../atoms/Atoms';
import Counter from '../../counter/Counter';
import { backdropVariants, sidebarVariants } from '../../../../utils';
import EmptyCart from '../../illustrations/EmptyCart';
import { minimumSelector } from '../../../../atoms/Selectors';
import envConfig from '../../../../envConfig';
import { thousand } from '../../../../functions';


function SidebarV10() {
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const [open, setOpen] = useRecoilState(sidebarAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const store = useRecoilValue(storeAtom);
  const minimum = useRecoilValue(minimumSelector);
  const setAlert = useSetRecoilState(alertAtom);
  const service = useRecoilValue(serviceAtom);
  const location = useRecoilValue(locationAtom);

  const getTotal = (id) => {
    const filter = _.filter(order, (e) => e.id === id);
    const price = filter[0].activePrice;
    let amount = 0;
    for (let i = 0; i < filter.length; i++) {
      amount += filter[i].amount;
    }
    return {
      subtotal: price.toFixed(2),
      units: amount,
      total: (price * amount).toFixed(2),
    };
  };

  const getOrderTotal = () => {
    let total = 0;
    _.forEach(order, (e) => {
      total += e.activePrice * e.amount;
    });
    return total.toFixed(2);
  };

  useClickAway(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);


  return (
    <>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">{t('shopping_cart')}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <br />
                      {order.length > 0 && (
                      <div className="space-y-4">
                        <div className="mt-8">
                          <div className="flow-root">
                            <ul className="-my-2 divide-y divide-gray-200">
                              {_.map(_.orderBy(_.uniqBy(order, 'id'), 'name'),
                                (e, index) => (
                                <li key={e.id} className="flex py-6">
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={e.imageUrl}
                                      alt={e.name}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>
                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <h3>
                                          {e.brand}
                                        </h3>                                       
                                        <p className="ml-4">${thousand(getTotal(e.id).total)}</p>
                                      </div>
                                      <p className="mt-1 text-xs text-gray-600">{e.name}</p>
                                      <p className="mt-1 text-xs text-gray-500">{e.description}</p>
                                    </div>
                                    <div className="flex flex-1 items-end justify-between text-sm">
                                      <p className="text-gray-500">Qty {getTotal(e.id).units}</p>
                                      <div className="flex">
                                      <button
                                        type="button"
                                        onClick={(x) => {
                                          x.stopPropagation();
                                          let newOrder = JSON.parse(JSON.stringify(order));
                                          newOrder = _.filter(order, (p, i) => p.id !== e.id);
                                          setOrder(newOrder);
                                        }}
                                        className="font-medium text-indigo-600 hover:text-indigo-500"

                                      >
                                        {t('remove')}
                                      </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="border-t border-gray-200 px-4 py-6 sm:px-6" style={{ justifyContent: 'space-between' }}>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <p>Subtotal</p>
                              <p>${thousand(getOrderTotal())}</p>
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500">{t('shipping_calculated_checkout')}</p>
                            <div className="mt-6">
                              <button
                                type="button"
                                onClick={() => {
                                  history.push('/checkout');
                                  setOpen(false);
                                }}
                                disabled={minimum > getOrderTotal()}
                                className="mb-2 block w-full rounded bg-indigo-600 disabled:bg-blue-200 disabled:cursor-not-allowed px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                              >
                                {t('checkout')}
                              </button>
                            </div>
                            <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                              <p>
                                {t('or')} {' '}
                                <button
                                  type="button"
                                  className="font-medium text-indigo-600 hover:text-indigo-500"
                                  onClick={() => {
                                    history.push(`/stores/${envConfig.WebstoreId}`);
                                    setOpen(false);
                                  }}
                                >
                                  {t('continue_shopping')}
                                  <span aria-hidden="true"> &rarr;</span>
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      )}
                      {order.length === 0 && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            duration: 1,
                           delay: 0.5,
                         }}
                          className="flex flex-col flex-1 items-center justify-center overflow-x-hidden"
                        >
                          {/* <EmptyCart className="h-32 mb-5" /> */}
                         <div className="text-gray-800 text-sm font-medium">
                            {t('empty_cart')}
                         </div>
                         <div className="text-gray-400 text-xs text-center">
                          {t('empty_cart_content')}
                          <br />
                         {t('empty_cart_prompt')}
                         </div>
                         <br />
                         <br />
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => {
                              history.push(`/stores/${envConfig.WebstoreId}`);
                              setOpen(false);
                            }}
                          >
                            {t('continue_shopping')}
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </motion.div>
                      )}
                      <AnimatePresence>
                        {order.length > 0 && getOrderTotal() < minimum && (
                          <motion.div
                            initial={{ opacity: 0, y: 160 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 160 }}
                            transition={{
                              duration: 1,
                              delay: 1,
                              type: 'spring',
                            }}
                            className="flex items-center space-x-4 p-4 bg-gray-800 text-white text-xs"
                          >
                            <div className="flex flex-shrink-0 text-white flex items-center justify-center w-6 h-6 bg-rose-500 rounded-full">
                              <FontAwesomeIcon
                                icon={faExclamation}
                                className="text-xs"
                              />
                            </div>
                            <div>
                              {t('minimum_prompt')}{' '}
                              <span className="text-accent font-bold">
                                ${minimum.toFixed(2)}
                              </span>
                              , {t('you_have')}{' '}
                              <span className="text-accent font-bold">
                                ${getOrderTotal()}
                              </span>{' '}
                            {t('in_your_cart')}.
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
      </Transition.Root>
    </>
  );
}

export default SidebarV10;

import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import {
  faReceipt,
  faTimesCircle,
  faCreditCard,
  faCreditCardFront,
} from '@fortawesome/pro-duotone-svg-icons';
import { backdropVariants, modalVariants } from '../../../../../../utils';
import {
  notificationsAtom,
  selectedBookingAtom,
  reloadBookingsAtom,
  loadingBookingsAtom,
  userPaymentsAtom,
  userAtom
} from '../../../../../../atoms/Atoms';
import { Spinner } from '../../../../../shared-components/Spinner';
import Button from '../../../../../shared-components/buttons/Button';
import api from '../../../../../../api/api';
import bookingApi from '../../../../../../api/bookingApi';
import { getEventBookingStatus, toLocalTime } from '../../../../../../functions';
import envConfig from '../../../../../../envConfig';

function BookingDetailsModal() {
  const user = useRecoilValue(userAtom);
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const paymentsRef = useRef();
  const setReloadBookings = useSetRecoilState(reloadBookingsAtom);
  const loadingBookings = useRecoilValue(loadingBookingsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [selectedBooking, setSelectedBooking] = useRecoilState(selectedBookingAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const agreementsList = [
    { text: t('rental_agreement_option_1'), index: '1' },
    { text: t('rental_agreement_option_2'), index: '2' },
    { text: t('rental_agreement_option_3'), index: '3' }
  ];

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }
  
  const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };

  const closeModal = () => {
    if (showPaymentsModal) {
      closePaymentsModal();
    } else {
      setSelectedBooking(null);
    }
  };

  useClickAway(ref, () => {
    closeModal();
  });

  useClickAway(paymentsRef, () => {
    closePaymentsModal();
  });

  function fetchPayments() {
    setLoading(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setPayment({ ...payment, cards: response.data.data.results });
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function cancelBooking() {
    setLoading(true);
    bookingApi.delete(`events/booking/${selectedBooking.id}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          setReloadBookings(true);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function completePayment(card, payPalInvoice, payPalReference, athMovilReference) {
    setLoading(true);
    bookingApi.post('purchases/apply/payment', {
      PurchaseId: selectedBooking?.purchases[0]?.id,
      UserCardId: card?.id,
      PaypalInvoiceNumber: payPalInvoice,
      PaypalReferenceNumber: payPalReference,
      AthMovilReferenceNumber: athMovilReference,
      Amount: selectedBooking?.purchases[0]?.amount - selectedBooking?.purchases[0]?.amountPaid
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setNotifications([
            ...notifications,
            {
              title: '',
              description: response.data.message,
              error: true,
            },
          ]);
          closePaymentsModal();
          setReloadBookings(true);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  useEffect(() => {
    if (showPaymentsModal) {
      fetchPayments();
    }
  }, [showPaymentsModal]);

  return (
    <AnimatePresence>
      {selectedBooking && !showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={ref}
              className="flex flex-col bg-white rounded-xl mx-auto w-5/6"
            >
              <header className="flex items-center justify-between bg-accent text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  <FontAwesomeIcon icon={faReceipt} />
                  <div>{t('booking_details')}</div>
                </div>
                <button
                  type="button"
                  onClick={() => closeModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              <main className="group relative flex flex-1 overflow-y-auto p-2 bg-gray-50 rounded-b-xl">
                <div hidden={!loadingBookings}>
                  <div
                    className="absolute w-full h-full bg-gray-50 opacity-50"
                  />
                  <Spinner
                    spinning
                    className="absolute inset-2/4"
                  />
                </div>
                <section className="flex flex-1 flex-col overflow-y-auto rounded-b-xl">
                  <div className="grid grid-cols-1 md:grid-cols-2 text-gray-600 text-sm tracking-tight divide-y md:divide-y-0 md:divide-x divide-gray-200 bg-gray-50 ">
                    <div className="divide-y divide-gray-200">
                      <div className="py-2 px-4">
                        <div className="text-gray-800">{selectedBooking?.event?.name}</div>
                        <div className="text-xs">{selectedBooking?.event?.description}</div>
                      </div>
                      <div className={`${selectedBooking.event.type === 0 ? 'hidden' : 'py-2 px-4'}`}>
                        <div className="text-xs">{t('host')}:</div>
                        <div className="text-gray-800 flex items-center">
                          <img
                            alt={`${selectedBooking?.event?.host?.firstName} ${selectedBooking?.event?.host?.lastName}`}
                            src={selectedBooking?.event?.host?.avatar}
                            className="rounded-full h-8 w-8 mr-2 bg-gray-200"
                          />
                          {`${selectedBooking?.event?.host?.firstName} ${selectedBooking?.event?.host?.lastName}`}
                        </div>
                        <div className="text-xs">{selectedBooking?.event?.host?.email}</div>
                        <div className="text-xs">{selectedBooking?.event?.host?.phoneNumber}</div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">{t('location')}:</div>
                        <div className="text-gray-800">{selectedBooking?.event?.location?.name}</div>
                      </div>
                      <div className="py-2 px-4">
                        <div className="text-xs">{t('date')}:</div>
                        <div className="text-gray-800">
                          {toLocalTime(selectedBooking?.eventTimeSlot?.startTime)
                            .locale(i18n.language)
                            .format('dddd, MMMM D, YYYY')}
                        </div>
                        <div className="text-gray-800">
                          {`${toLocalTime(selectedBooking?.eventTimeSlot?.startTime)
                            .locale(i18n.language)
                            .format('h:mm A')}-${toLocalTime(selectedBooking?.eventTimeSlot?.endTime)
                            .locale(i18n.language)
                            .format('h:mm A')}`}
                        </div>
                      </div>
                    </div>
                    <div className="divide-y divide-gray-200">
                      <div className="flex">
                        <div className="py-2 px-4">
                          <div className="text-xs">{t('status')}:</div>
                          <div className="text-gray-800">{t(getEventBookingStatus(selectedBooking?.status))}</div>
                        </div>
                        {selectedBooking?.status === 0 && (
                          <div className="ml-auto py-2 px-4">
                            <Button
                              text={t('cancel')}
                              textColor="text-white"
                              disabled={loading}
                              loading={loading}
                              onClick={() => cancelBooking()}
                              loaderColor="bg-white"
                              className="bg-red-600 hover:bg-accent w-20"
                            />
                          </div>
                        )}
                      </div>
                      {selectedBooking?.status === 0 && selectedBooking?.purchases?.length > 0 && (
                      <div className="flex flex-col">
                        <div>
                          <div className="flex flex-col md:mx-4 items-start mt-1 md:mt-8 bg-green-100 rounded-xl p-4 border-t border-gray-100">
                            <label className="text-xs md:text-md font-semibold">{t('rental_terms')}</label>
                            <button 
                              hidden
                              type="button"
                              onClick={handleToggle}
                            >
                              {isExpanded ? 'show less' : 'show less'} List
                            </button>
                          {isExpanded && (
                            <ul className="text-xs md:text-sm">
                              {agreementsList.map((item) => (
                              <li key={item.index}>-{item.text}</li>
                              ))}
                            </ul>
                          )}
                          <div className="text-sm md:text-md mt-4 hidden md:block">
                            <span>{t('if_cancel_booking_contact')}
                            </span>
                            <a href="mailto:bookings@mecacomplex.com" className="ml-1 font-medium text-blue-600 dark:text-blue-500 hover:underline">bookings@mecacomplex.com</a>
                          </div>
                          </div>          
                          <div className="flex items-center mx-1 mt-2 md:mt-8 md:mb-4 md:mx-4">
                            <input
                              type="checkbox"
                              className="md:h-5 md:w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="agreement" className="ml-2 text-xs md:text-sm">
                              {t('booking_i_agree_terms')}
                            </label>
                          </div>
                        </div>
                          <div className="py-2 px-4 md:block hidden">
                            <div className="text-xs font-bold">{t('total')}:</div>
                            <div className="text-gray-800 font-bold">
                              ${selectedBooking?.event?.price?.toFixed(2)}
                            </div>
                          </div>
                          <div className="ml-auto py-2 md:px-4">
                            <Button
                              text={`${t('complete_payment')} $${selectedBooking?.event?.price?.toFixed(2)}`}
                              textColor="text-white"
                              disabled={loading || !isChecked}
                              loading={loading}
                              onClick={() => {
                                setShowPaymentsModal(true);
                              }}
                              loaderColor="bg-white"
                              className="bg-green-600 hover:bg-accent"
                            />
                          </div>
                      </div>
                      )}
                      <div className="py-2 px-4">
                        <div className="text-xs">{t('note')}:</div>
                        <div className="text-gray-800">
                          {selectedBooking?.comments
                            ? selectedBooking?.comments
                            : `${t('none')}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
      {showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="payments"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={paymentsRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  {t('amount_to_pay')}: ${(selectedBooking?.purchases[0]?.amount - selectedBooking?.purchases[0]?.amountPaid).toFixed(2)}
                </div>
                <button
                  type="button"
                  onClick={() => closePaymentsModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {loading && <Spinner className="mx-auto mt-2" />}
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  <div>{t('payment_method_description')}</div>
                  <Spinner
                    spinning={loading}
                    className="mx-auto my-4"
                  />
                  {envConfig.AllowCreditCardCreate === 'true' && envConfig.AllowCreditCardsInBooking === 'true' && (
                    <button
                      type="button"
                      onClick={() => {
                        setPayment({ ...payment, modal: true });
                      }}
                      className="text-left w-full mt-4 transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
                    >
                      <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
                        <FontAwesomeIcon icon={faCreditCardFront} />
                      </div>
                      <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                        <div className="font-semibold">{t('add_new_payment')}</div>
                        <div>{t('new_card')}</div>
                      </div>
                    </button>
                  )}
                  {envConfig.PayPalForBookings === 'true' && !loading && (
                    <div className="mt-6">
                      <PayPalScriptProvider options={{ 'client-id': envConfig.PayPalKey }}>
                        <PayPalButtons
                          createOrder={(data, actions) => actions.order.create({
                            purchase_units: [
                                {
                                    description: `${selectedBooking?.event?.name} ${dayjs(selectedBooking?.eventTimeSlot?.startTime).add(-4, 'hour').format('MMM D YYYY')} ${dayjs(selectedBooking?.eventTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedBooking?.eventTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`,
                                    amount: {
                                        value: `${(selectedBooking?.purchases[0]?.amount - selectedBooking?.purchases[0]?.amountPaid).toFixed(2)}`,
                                    },
                                    custom_id: `${user?.id} ${selectedBooking?.eventTimeSlot?.id}`
                                },
                            ],
                            application_context: { brand_name: 'MECA Complex', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                          })}
                          onApprove={(data, actions) => actions.order.capture().then((details) => {
                            const refNumber = details.id;
                            const invoiceNumber =
                              details.purchase_units[0]?.payments?.captures[0]?.id;
                            completePayment(null, invoiceNumber, refNumber, null);
                          })}
                        />
                      </PayPalScriptProvider>
                    </div>
                  )}
                  {envConfig.AthMovilForBookings === 'true' && !loading && (
                    <></>
                  )}
                  {envConfig.AllowCreditCardsInBooking === 'true' && _.map(payment.cards, (card) => (
                    <button
                      type="button"
                      onClick={() => completePayment(card, null, null, null)}
                      key={card.nickname}
                      className="w-full"
                    >
                      <div
                        className="divide-x mt-4 divide-purple-200 flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none"
                      >
                        <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                        <div className="flex flex-col flex-1 items-start justify-start text-xs p-2">
                          <div className="font-semibold">{card.brand}</div>
                          <div>**** {card.last4}</div>
                          <div>{card.expirationDate}</div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default BookingDetailsModal;

import React, { useState } from 'react';
import _ from 'lodash';
import { useSetRecoilState } from 'recoil';
import { modalAtom, productAtom } from '../../../../atoms/Atoms';
import Counter from '../../counter/Counter';
import ItemTimeConstraintWarning from '../../ItemTimeConstraintWarning';
import SpecialsBanner from '../../../../assets/img/specials/specials_banner.png';


function CardV10({
  id,
  name,
  image,
  price,
  imageAlt,
  small,
  description,
  brand,
  className,
  product,
}) {
  const [valid, setValid] = useState(true);
  const setModal = useSetRecoilState(modalAtom);
  const setProduct = useSetRecoilState(productAtom);

    return (
        <div 
          className="rounded-xl border border-cardBorder p-2"
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            setModal(true);
            setProduct(product);
          }}
          onClick={() => {
            setModal(true);
            setProduct(product);
          }}
        >
                <div key={id} className="group relative">
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-300 lg:aspect-none group-hover:opacity-75 lg:h-80">
                  {image && valid && (
                    <img
                      src={image}
                      alt={name}
                      className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                    />
                    )}
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-sm font-semibold text-gray-700">
                        <p className="mt-1">{brand}</p>                       
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">{name}</p>
                      <p className="mt-1 text-sm text-gray-500">{description}</p>
                    </div>
                    <p className="text-sm font-medium text-gray-900">{`$${price?.toFixed(2)}`}</p>
                  </div>
                </div>
        </div>
          )
    }

export default CardV10;

import React from 'react';

function SpecialPrice({ price, hasRandomWeight, regularPrice, savings, isUnitPriceMethod }) {
  if (hasRandomWeight) {
    return (
      <div className="font-extrabold font-light tracking-tight py-2">
        <div className="">${parseFloat(price).toFixed(2)} lb.</div>
      </div>
    );
  }

  return (
      <div className="flex flex-row items-center">
        <div 
          style={{ textDecoration: 'line-through' }}
          className="text-center text-md font-extrabold text-gray-500"
        >
          ${regularPrice?.toFixed(2)}
        </div> 
        <div className="font-extrabold text-xl font-light tracking-tight text-red-700 ml-2 py-2">
          ${parseFloat(price).toFixed(2)}
        </div>
        {isUnitPriceMethod && (
          <div className="text-sm font-light tracking-tight text-red-700 ml-2 py-2">
            Ahorro de ${parseFloat(savings).toFixed(2)}
          </div>
        )}
      </div>
    
  );
}

export default SpecialPrice;

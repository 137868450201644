import React from 'react';

function Service10({ title, subTitle, body, image, note, onClick, enabled,
   buttonText, dark, visible }) {
  return (
    <div className="relative space-y-4 flex flex-col items-start justify-center text-sm bg-white shadow-sm rounded-xl p-2 md:p-4 w-full">
      {/* <div className="font-semibold tracking-tight">{title}</div>
      <div>{body}</div>
      <div className="text-xs text-rose-500">{note}</div> */}
        <div key={title} className="group relative">
          <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
            <button
              type="button"
              onClick={onClick}
            >
              <img
                src={image}
                alt={title}
                className="h-full w-full object-cover object-center"
              />
            </button> 
          </div>
          <h3 className="mt-6 text-sm text-gray-500">
            <button
              type="button"
              onClick={onClick} 
              disabled={!enabled} 
            >
              {title}
            </button>
          </h3>
          <p className="text-base font-semibold text-gray-900">{subTitle}</p>
        </div>
    </div>
  );
}

export default Service10;

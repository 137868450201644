import React from 'react';
import Options from './shared-components/options/Options';
import StripeModal from './shared-components/StripeModal';
import Items from './shared-components/Items';

function Payments() {
  return (
    <>
      <div className="flex flex-col flex-1 px-4 overflow-auto mb-4">
        <Options />
        <Items />
      </div>
    </>
  );
}

export default Payments;

import React, { useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Link, useHistory } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import is from 'is_js';
import { orderAtom, tokenAtom, userAtom, storeAtom, userPaymentsAtom, serviceAtom, contactAtom, referenceOrderAtom, isAdditionalItemOrderAtom, promoCodeAtom, notificationsAtom, successMessageAtom, orderSuccess } from '../../../../atoms/Atoms';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import Button from '../../../shared-components/buttons/Button';
import Payment from './Payment';
import useAnalyticsEventTracker from '../../../../useAnalyticsEventTracker';
import { checkoutSelector } from '../../../../atoms/Selectors';
import Hours from './Hours';
import Contact from './Contact';
import { thousand } from '../../../../functions';
import PromoCode from './PromoCode';

function OrderSummary() {
  const { t } = useTranslation();
  const eventTracker = useAnalyticsEventTracker('User Actions');
  const checkout = useRecoilValue(checkoutSelector);
  const [order, setOrder] = useRecoilState(orderAtom);
  const store = useRecoilValue(storeAtom);
  const user = useRecoilValue(userAtom);
  const token = useRecoilValue(tokenAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [submitting, setSubmitting] = useState(false);
  const service = useRecoilValue(serviceAtom);
  const contact = useRecoilValue(contactAtom);
  const [referenceOrder, setReferenceOrder] = useRecoilState(
    referenceOrderAtom
  );
  const isAdditionalItemOrder = useRecoilValue(isAdditionalItemOrderAtom);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom)
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const setMessage = useSetRecoilState(successMessageAtom);
  const setSuccess = useSetRecoilState(orderSuccess);
  const [showSecondOrderAlertAfterSubmitPopup,
    setShowSecondOrderAlertAfterSubmitPopup]
    = useState(false);
  const history = useHistory();
  const width = window.innerWidth;

  const sendOrder = () => {
    setSubmitting(true);
    eventTracker('Submit Order', '');
    const mappedItems = _.map(checkout.order, (p) => ({
      businessItemId: p.id,
      amount: p.amount,
      price: p.activePrice,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isEBT: p.isEBT,
      itemId: p.id,
      acceptsSubstitute: true,
      hasRandomWeight: p.hasRandomWeight,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      itemImage: p.imageUrl,
      specialInstruction: null
    }));

    const orderObject = {
      OGuid: uuid(),
      AppVersion: '1.0',
      PaymentType: payment.card.last4 ? 0 : payment.card.paymentType,
      OrderLat: null,
      OrderLon: null,
      BusinessId: store.id,
      CardId: payment.card.last4 ? payment.card.id : null,
      PaymentOptionId: payment.card.last4 ? null : payment.card.id,
      DeliveryLocationId: null,
      DeliveryTotal: 0,
      SubTotal: parseFloat(checkout.subtotal),
      MunicipalTax: parseFloat(checkout.muniTax),
      StateTax: parseFloat(checkout.stateTax),
      TaxTotal: parseFloat(checkout.muniTax) + parseFloat(checkout.stateTax),
      ProcessingFee:
        service === 0 ? parseFloat(store.locations[0].pickupFee) : 0,
      OrderTotal: parseFloat(checkout.total),
      LocationId: store.locations[0].id,
      PickUpName: `${contact?.name} ${contact?.phone}`,
      SpecialInstruction: contact.instructions,
      Type: service,
      items: mappedItems,
      DesiredDateTime: checkout.time,
      StartSlotTime: checkout.startSlotTime,
      EndSlotTime: checkout.endSlotTime,
      BypassMinimum: referenceOrder !== null,
      IsAdditionalItemOrder: referenceOrder !== null && isAdditionalItemOrder,
      ReferenceOrderId: referenceOrder?.id,
      CouponId: promoCode?.id
    };
    api
      .post('orders', orderObject)
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error_submitting_order'),
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setMessage(response.data);
          setSuccess(true);
          if (referenceOrder) {
            setReferenceOrder(null);
          }
          if (
            orderObject.PaymentOptionId &&
            !payment.card.allowNonFoodProducts &&
            _.filter(checkout.allItems, (product) => !product.isEBT)
          ) {
            setReferenceOrder(response.data.data);
            setOrder(_.filter(checkout.allItems, (product) => !product.isEBT));
            if (envConfig.ShowEbtAfterPopup === 'true') {
              setShowSecondOrderAlertAfterSubmitPopup(true);
            }
          } else {
            setOrder([]);
          }

          localStorage.removeItem(`${envConfig.StorageKey}-order`);
          const tempPayment = JSON.parse(JSON.stringify(payment));
          tempPayment.card = null;
          localStorage.removeItem(`${envConfig.StorageKey}-card`);
          localStorage.removeItem(`${envConfig.StorageKey}-payment`);
          setPayment(tempPayment);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: t('error_submitting_order'),
            description: error.message,
            error: true,
          },
        ]);
        setSubmitting(false);
      });
  };

  console.log(checkout);

    return (

              <AnimatePresence>
                <div
                  aria-labelledby="summary-heading"
                  className="flwx flex-col flex-grow space-y-4 lg:col-span-6 lg:mt-0 lg:p-8 bg-gray-50"
                  style={{ overflowY: 'scroll', height: '50%' }}
                >
                  <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                    {t('order_summary')}
                  </h2>
                  <p className="text-gray-500">{t('pickup')} @ {store?.name}</p>
                  <dl className="mt-6 space-y-4">
                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">{t('subtotal')}</dt>
                      <dd className="text-sm font-medium text-gray-900">${thousand(checkout.subtotal)}</dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="flex text-sm text-gray-600">
                        <span>{t('municipal_tax')}</span>
                      </dt>
                      <dd className="text-sm font-medium text-gray-900">${checkout.muniTax}</dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="flex text-sm text-gray-600">
                        <span>{t('state_tax')}</span>
                      </dt>
                      <dd className="text-sm font-medium text-gray-900">${checkout.stateTax}</dd>
                    </div>
                    {checkout.discountTotal !== 0 && (
                      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                        <dt className="flex text-sm text-gray-600">{t('promo_code')}</dt>
                        <dd className="text-sm font-medium text-gray-900">${(thousand(checkout.discountTotal.toFixed(2)))}</dd>
                      </div>
                    )}
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="text-base font-medium text-gray-900">{t('order_total')}</dt>
                      <dd className="text-base font-medium text-gray-900">${thousand(checkout.total)}</dd>
                    </div>
                  </dl>
                  <div className="mt-6">
                    <br />
                    <PromoCode />
                    <br />
                    <Contact />
                    <br />
                    <Hours />
                    <br />
                    <Payment />
                  </div>
                  <br />
                  <div className={`${width < 1024 ? 'grid grid-cols-3 place-content-center' : 'flex flex-row-reverse px-2'}`}>
                    <div>{' '}</div>
                    <div>
                      <Button
                        text={t('complete_payment')}
                        textColor="text-white"
                        disabled={
                          is.any.falsy(
                            token,
                            user,
                            payment.card,
                            store,
                          ) || submitting
                        }
                        className="hover:shadow-md w-full rounded-xl px-4 text-sm font-semibold tracking-tight text-white ring-2 ring-accent ring-offset-2 bg-accent hover:bg-accent disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                        onClick={() => sendOrder()}
                        loading={submitting}
                        loaderColor="bg-white"
                      />
                    </div>
                    <div>{' '}</div>
                  </div>
                  <br />
                </div>
              </AnimatePresence>
    
  )
}
export default OrderSummary;

import _ from 'lodash';
import dayjs from 'dayjs';
import visa from './assets/img/credit_cards/visa.svg';
import amex from './assets/img/credit_cards/amex.svg';
import diners from './assets/img/credit_cards/diners.svg';
import mastercard from './assets/img/credit_cards/mastercard.svg';
import discover from './assets/img/credit_cards/discover.svg';
import unknown from './assets/img/credit_cards/unknown.svg';

export const animationTransition = {
  duration: 0.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const fadeInMotion = {
  opacity: 1,
  transition: animationTransition,
};

export const fadeOutMotion = {
  opacity: 0,
  transition: animationTransition,
};

export const toLocalTime = (date) => {
  if (date) {
    return dayjs(date).subtract(4, 'hour');
  }
  return dayjs.utc().subtract(4, 'hour')
};

export const getType = (card) => {
  switch (card) {
    case 'visa':
      return visa;
    case 'amex':
      return amex;
    case 'american express':
      return amex;
    case 'mastercard':
      return mastercard;
    case 'discover':
      return discover;
    case 'diners':
      return diners;
    default:
      return unknown;
  }
};

export const mapPaymentType = (type) => {
  switch (type) {
    case 0:
      return 'Stripe';
    case 1:
      return 'ATH Movil';
    case 7:
      return 'ATH';
    case 3:
      return 'Other';
    case 4:
      return 'EBT';
    case 5:
      return 'Paypal';
    case 10:
      return 'MCS';
    case 11:
      return 'MMM';
    case 16:
      return 'SSS';
    case 17:
      return 'Humana';
    default:
      return type;
  }
};

export const mapOrderType = (type) => {
  switch (type) {
    case 0:
      return 'Recogido';
    case 1:
      return 'Entrega';
    case 6:
      return 'Deli Pickup';
    case 7:
      return 'Deli Delivery';
    case 5: 
      return 'In Store';
    default:
      return 'Undefined';
  }
};

export const mapOrderStatus = (status) => {
  switch (status) {
    case 0:
      return 'waiting_selection';
    case 2:
      return 'delivery_assigned';
    case 3:
      return 'waiting_selection';
    case 6:
      return 'customer_pickup';
    case 7:
      return 'delivered';
    case 8:
      return 'cancelled_by_store';
    case 9:
      return 'cancelled';
    case 10:
      return 'payment_declined';
    case 5:
      return 'on_its_way';
    case 16:
      return 'selecting_process';
    case 17:
      return 'selecting_finished';
    case 18:
      return 'packing';
    case 20:
      return 'ready_for_pickup';
    case 21:
      return 'waiting_for_driver';
    case 27:
      return 'paypal_payment_pending';
    case 30:
      return 'payment_pending';
    case 32:
      return 'under_review';
    case 33:
      return 'ivu_control_failure';
    default:
      return 'unknown';
  }
};

export function formatNumber(number) {
  if (number) {
    return number.toLocaleString();
  }
  return '';
}

export const eventTypes = [
  { type: 0, name: 'rental' },
  { type: 1, name: 'class' },
  { type: 2, name: 'other' }
];

export const bookingStatus = [
  { type: 0, name: 'pending' },
  { type: 1, name: 'confirmed' },
  { type: 2, name: 'cancelled' }
];

export const getEventBookingStatus = (type) => {
  const filtered = _.filter(bookingStatus, (e) => e.type === type);
  if (filtered.length > 0) {
    return filtered[0].name;
  }
  return '';
};

export const getEventTypeName = (type) => {
  const filtered = _.filter(eventTypes, (e) => e.type === type);
  if (filtered.length > 0) {
    return filtered[0].name;
  }
  return '';
};

export const thousand = (number) => {
  if (number === null || number === undefined) {
    return '0';
  }
  if (number) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const result = parts.join('.');
    return result;
  }
  return '0';
};

export function hasActiveSubscription(subObj) {
  if (subObj?.length === 0) {
    return false
  }
  if (subObj?.length > 0) {
    subObj.map((sub) => {
      if (sub.isActive) {
        return true;
      }
      return false;
    })
  }
  return false;
}

export function getRecurrence(rec) {
  if (rec === 0) return 'none'
  if (rec === 1) return 'daily'
  if (rec === 2) return 'weekly'
  if (rec === 3) return 'bi-weekly'
  if (rec === 4) return 'monthly'
  if (rec === 5) return 'bi-monthly'
  if (rec === 6) return 'quarterly'
  if (rec === 7) return 'semi-yearly'
  if (rec === 8) return 'yearly'
  return 'unknown'  
}

export function getRecurrenceLength(rec) {
  if (rec === 0) return 'none'
  if (rec === 1) return 'day'
  if (rec === 2) return 'week'
  if (rec === 3) return 'two weeks'
  if (rec === 4) return 'month'
  if (rec === 5) return 'two Months'
  if (rec === 6) return 'quarter'
  if (rec === 7) return 'half Year'
  if (rec === 8) return 'year'
  return 'unknown'  
}

export function getDateExtensionType(rec) {
  if (rec === 0) return 'none';
  if (rec === 1) return 'day(s)'
  if (rec === 2) return 'week(s)'
  if (rec === 3) return 'month(s)'
  if (rec === 4) return 'year(s)'
  return 'unknown'  
}

import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { storeAtom } from '../../../atoms/Atoms';
import colgate from './shared-components/assets/banners/colgate-family.jpg';
import colgateLogo from './shared-components/assets/logos/colgate-logo.png';
import goya from './shared-components/assets/banners/goya-products.jpg';
import goyaLogo from './shared-components/assets/logos/goya-logo.png';
import alheli from './shared-components/assets/banners/alheli-products.jpg';
import alheliLogo from './shared-components/assets/logos/alheli_logo.jpg';

function BrandCard() {
const store = useRecoilValue(storeAtom);
const { t } = useTranslation();
const history = useHistory();
const data = [{
    name: 'Colgate',
    imageUrl: colgate,
    logoUrl: colgateLogo,
    brand: 'COLGATE',
    message: 'Encuentra lo mejor para tu higiene',
    },
    {
    name: 'Goya',
    imageUrl: goya,
    logoUrl: goyaLogo,
    brand: 'GOYA',
    message: 'Si es Goya tiene que ser bueno'
    },
    {
    name: 'Alheli',
    imageUrl: alheli,
    logoUrl: alheliLogo,
    brand: 'ALHELI',
    message: 'Excelencia como ninguna otra.',
}];

return (
<div 
  className="bg-white"
>
    <div className="font-bold text-2xl ml-4 mt-4">{t('brands_header')}
    </div>
    <div 
      key="container"
      className="flex flex-row px-4 w-auto h-72"
    >
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            history.push(`/stores/${store.id}/brand/0/${data[0].brand}`);
          }}          
          key="colgate" 
          className="flex flex-col border rounded-lg m-5 mx-auto"            
          style={{ position: 'static' }}
        >
            <img 
              style={{ position: 'relative', bottom: 0, right: 0 }}
              className="h-40 md:h-52 rounded-tl-lg rounded-tr-lg" 
              alt="promo" 
              src={data[0].imageUrl} 
            />
            <div className="flex flex-row m-2">
            <img             
              className="w-10 mr-4" 
              alt="logo" 
              src={data[0].logoUrl} 
            />
            <div className="text-sm">
                {data[0].message}
            </div>
            </div>
        </div>
        <div     
          role="button"
          tabIndex={-1}
          onClick={() => {
             history.push(`/stores/${store.id}/brand/0/${data[1].brand}`);
          }}          
          key="goya" 
          className="flex flex-col border rounded-lg m-5 mx-auto px-4 hidden md:block"            
          style={{ position: 'static' }}
        >
            <img 
              style={{ position: 'relative', bottom: 0, right: 0 }}
              className="h-52 rounded-tl-lg rounded-tr-lg" 
              alt="promo" 
              src={data[1].imageUrl} 
            />
            <div className="flex flex-row m-2 ">
            <img             
              className="w-12 mr-4" 
              alt="logo" 
              src={data[1].logoUrl} 
            />
            <div className="text-sm">
                {data[1].message}
            </div>
            </div>
        </div>
        <div   
          role="button"
          tabIndex={-2}
          onClick={() => {
             history.push(`/stores/${store.id}/brand/0/${data[2].brand}`);
          }}          
          key="Alheli" 
          className="flex flex-col border rounded-lg m-5 mx-auto px-4 hidden lg:block"            
          style={{ position: 'static' }}
        >
            <img 
              style={{ position: 'relative', bottom: 0, right: 0 }}
              className="h-52 rounded-tl-lg rounded-tr-lg" 
              alt="promo" 
              src={data[2].imageUrl} 
            />
            <div className="flex flex-row m-2 ">
            <img             
              className="w-12 mr-4" 
              alt="logo" 
              src={data[2].logoUrl} 
            />
            <div className="text-sm">
                {data[2].message}
            </div>
            </div>
        </div>
    </div>
</div>
);
}

export default BrandCard;

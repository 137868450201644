import React, { useEffect, useState, useRef } from 'react';
import { useMount } from 'react-use';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import api from '../../../api/api';
import { Spinner } from '../../shared-components/Spinner';

function Gallery() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(null);

  function getImages() {
    setLoading(true);
    api
      .get('utility/gallery/getList')
      .then((response) => {
        setImages(response.data.data.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useMount(() => {
    getImages();
  })

 
  return (
    <AnimatePresence>
      <div className="flex-1 overflow-y-auto bg-white">
        <div className="flex">
          <div>
            <br />
            <h2
              className="text-5xl font-medium leading-tight"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '15px' }}
            >
              {t('gallery')}
            </h2>
          </div>
          <div className="ml-auto overflow-y-hidden">  
            <br />
            <button
              key="reload"
              type="button"
              hidden={loading}
              onClick={() => {
              getImages();
              }}
              className="md:text-md text-xs bg-green-500 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-400 rounded shadow md:mx-2"
            >
              <span>{t('refresh')}{loading ? <Spinner className="ml-2 inline" /> : <></>}</span>
            </button>
          </div>
        </div>
        <div>
          <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-24">
            <div className="-m-1 flex flex-wrap md:-m-2">
            {_.map(images, (x) => (
              <div className="flex w-1/3 flex-wrap">
                <div className="w-full p-1 md:p-2">
                  <img
                    alt="gallery"
                    className="block h-full w-full rounded-lg object-cover object-center"
                    src={x}
                  />
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>        
      </div>
    </AnimatePresence>
  )
}

export default Gallery;

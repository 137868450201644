import React from 'react';
import { motion } from 'framer-motion';

function Banner({ title, image }) {
  return (
    <div
      className={`flex flex-shrink-0 text-white bg-center bg-cover h-28 md:h-72 ${image}`}
    >
      <div className="flex flex-col flex-1 justify-end bg-gradient-to-r from-gray-900 bg-opacity-50 p-4">
        <motion.div
          initial={{
            x: '-100%',
          }}
          animate={{
            x: 0,
            transition: {
              duration: 0.5,
              ease: 'easeInOut',
            },
          }}
          exit={{
            x: '-100%',
            transition: {
              duration: 0.5,
              ease: 'easeInOut',
            },
          }}
          className="text-3xl md:text-4xl font-extrabold tracking-tight"
        >
          {title}
        </motion.div>
      </div>
    </div>
  );
}

export default Banner;

import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import is from 'is_js';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  departmentAtom,
  departmentPageAtom,
  catalogAtom,
  departmentsAtom,
} from '../../../../atoms/Atoms';
import Placeholder from './Placeholder';
import Breadcrumb from './Breadcrumb';
import Banner from '../../../shared-components/banners/Banner';
import api from '../../../../api/api';
import envConfig from '../../../../envConfig';
import MainLogo from '../../../../assets/img/logo/main_logo.png';

function Departments10() {
  const { i18n, t } = useTranslation();
  const departments = useRecoilValue(departmentsAtom);
  const setCatalog = useSetRecoilState(catalogAtom);
  const setDepartment = useSetRecoilState(departmentAtom);
  const setDepartments = useSetRecoilState(departmentsAtom);
  const [state, setState] = useRecoilState(departmentPageAtom);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    api.get(`catalog/${id}`)
      .then((response) => {
        setCatalog(response.data.data);
        setDepartments(response.data.data.departments);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);


  return (
    <div className="flex-1 overflow-y-auto">
      <motion.div
        initial={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        animate={{
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
        exit={{
          y: 100,
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: [0.43, 0.13, 0.23, 0.96],
          },
        }}
      >
        {/* <Banner title={t('departments')} image="bg-store" /> */}
        <Breadcrumb />
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-4 p-4">
          {is.not.null(departments) &&
            _.map(departments, (d) => (
              <div key={d.name} className="group relative">
                <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                  <button
                    type="button"
                    onClick={() => {
                      setDepartment(d);
                      history.push(`/stores/${id}/departments/${d.id}`);
                     }}
                  >
                    <img
                      src={MainLogo}
                      alt={d.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </button>
                </div>
                <h3 className="mt-6 text-sm text-gray-500">
                  <button
                    type="button"
                    onClick={() => {
                      setDepartment(d);
                      history.push(`/stores/${id}/departments/${d.id}`);
                   }}
                  >
                    <p className="text-xl">{d.name}</p>
                  </button>
                </h3>
                <p className="text-base font-semibold text-gray-900">{d.description}</p>
              </div>
            ))}
          {is.null(departments) && _.times(9, (e) => <Placeholder key={e} />)}
        </div>
      </motion.div>
    </div>
  ) 
}

export default Departments10;

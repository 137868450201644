import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import api from '../../../../../../api/api';
import { getType } from '../../../../../../functions';
import { backdropVariants, modalVariants } from '../../../../../../utils';
import {
  notificationsAtom,
  tokenAtom,
  userPaymentsAtom,
} from '../../../../../../atoms/Atoms';
import Input from '../../../../../shared-components/form/Input';
import CleaveInput from '../../../../../shared-components/form/CleaveInput';
import Button from '../../../../../shared-components/buttons/Button';
import Form from '../../../../../shared-components/form/Form';

function StripeModal() {
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const token = useRecoilValue(tokenAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const formik = useFormik({
    initialValues: {
      'cc-type': '',
      'cc-name': '',
      'cc-number': '',
      'cc-exp': '',
      'cc-csc': '',
      'cc-zip': '',
    },
    validationSchema: Yup.object().shape({
      'cc-name': Yup.string().required('Campo requerido'),
      'cc-number': Yup.string().required('Campo requerido'),
      'cc-exp': Yup.string().required('Campo requerido'),
      'cc-csc': Yup.string().required('Campo requerido'),
      'cc-zip': Yup.string().required('Campo requerido'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      api
        .post(
          'users/me/cards',
          {
            AddressZip: values['cc-zip'],
            Cvc: values['cc-csc'],
            ExpMonth: values['cc-exp'].slice(0, 2),
            ExpYear: values['cc-exp'].slice(3, 7),
            Name: values['cc-name'],
            Number: values['cc-number'],
          }
        )
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('card_not_added')}`,
                description:
                `${t('card_error')}`,
                error: true,
              },
            ]);
          } else {
            setPayment({
              ...payment,
              modal: false,
              reload: true,
              cards: [...payment.cards, response.data.data],
            });
            setNotifications([
              ...notifications,
              {
                title: `${t('card_added')}`,
                description:
                  `${t('card_success')}`,
                error: false,
              },
            ]);
            formik.resetForm();
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setNotifications([
            ...notifications,
            {
              title: `${t('card_not_added')}`,
              description: `${t('card_error')} - ${error.message}`,
              error: true,
            },
          ]);
          setSubmitting(false);
        });
    },
  });

  useClickAway(ref, () => {
    setPayment({ ...payment, modal: false, edit: null });
    formik.setValues({
      'cc-type': '',
      'cc-name': '',
      'cc-number': '',
      'cc-exp': '',
      'cc-csc': '',
      'cc-zip': '',
    });
    formik.resetForm();
  });

  useEffect(() => {
    if (payment.edit) {
      formik.setValues({
        'cc-type': payment.edit.brand,
        'cc-name': payment.edit.cardholderName,
        'cc-number': '',
        'cc-exp': payment.edit.expirationDate,
        'cc-csc': '',
        'cc-zip': payment.edit.zipcode,
      });
    }
  }, [payment.edit]);

  return (
    <AnimatePresence>
      {payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-center justify-center"
        >
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="text-gray-800 antialiased justify-center items-center flex overflow-hidden fixed inset-0 z-50"
          >
            <div className="relative max-h-full w-98 px-4 overflow-y-auto">
              <div
                ref={ref}
                className="rounded-xl space-y-4 shadow-lg relative flex flex-col w-full bg-white overflow-y-auto p-6"
              >
                <div className="flex items-center justify-between">
                  <div className="font-semibold tracking-tight">
                    {t('payment_method')}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setPayment({ ...payment, modal: false, edit: null });
                      formik.setValues({
                        'cc-type': '',
                        'cc-name': '',
                        'cc-number': '',
                        'cc-exp': '',
                        'cc-csc': '',
                        'cc-zip': '',
                      });
                      formik.resetForm();
                    }}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div className="flex flex-col justify-between h-48 p-4 text-white text-sm w-full rounded-lg bg-gradient-to-r from-rose-500 to-fuchsia-900">
                  <div className="flex justify-between items-center">
                    <div className="h-8 w-10 bg-gray-100 bg-opacity-25 rounded" />
                    <img
                      alt={formik.values['cc-type']}
                      src={getType(formik.values['cc-type'])}
                      className="h-8 w-10"
                    />
                  </div>
                  <div className="text-base font-semibold">
                    {formik.values['cc-number'] || '**** **** **** ****'}
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="font-semibold">
                      {formik.values['cc-name'] || ''}
                    </div>
                    <div className="flex flex-col justify-end items-center">
                      <div className="capitalize text-xs tracking-tighter">
                        Valid Thru
                      </div>
                      <div className="font-semibold">
                        {formik.values['cc-exp'] || 'MM/YYYY'}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Form>
                    <CleaveInput
                      label={t('card_number')}
                      name="cc-number"
                      type="text"
                      placeholder="**** **** **** 3742"
                      required
                      autoComplete="cc-number"
                      value={formik.values['cc-number']}
                      onChange={formik.handleChange}
                      options={{
                        creditCard: true,
                        onCreditCardTypeChanged(type) {
                          formik.setFieldValue('cc-type', type);
                        },
                      }}
                      error={
                        formik.errors['cc-number'] &&
                        formik.touched['cc-number'] &&
                        formik.errors['cc-number']
                      }
                      className="col-span-1 md:col-span-2"
                    />
                    <Input
                      label={t('card_name')}
                      name="cc-name"
                      type="text"
                      placeholder="Juan del Pueblo"
                      required
                      autoComplete="cc-name"
                      value={formik.values['cc-name']}
                      onChange={formik.handleChange}
                      error={
                        formik.errors['cc-name'] &&
                        formik.touched['cc-name'] &&
                        formik.errors['cc-name']
                      }
                      className="col-span-1 md:col-span-2"
                    />
                    <CleaveInput
                      label={t('card_expiration_date')}
                      name="cc-exp"
                      type="text"
                      placeholder="MM/YYYY"
                      required
                      autoComplete="cc-exp"
                      value={formik.values['cc-exp']}
                      onChange={formik.handleChange}
                      options={{
                        date: true,
                        datePattern: ['m', 'Y'],
                      }}
                      error={
                        formik.errors['cc-exp'] &&
                        formik.touched['cc-exp'] &&
                        formik.errors['cc-exp']
                      }
                    />
                    <Input
                      label={t('card_cvv')}
                      name="cc-csc"
                      type="text"
                      placeholder="****"
                      required
                      autoComplete="cc-csc"
                      value={formik.values['cc-csc']}
                      onChange={formik.handleChange}
                      error={
                        formik.errors['cc-csc'] &&
                        formik.touched['cc-csc'] &&
                        formik.errors['cc-csc']
                      }
                    />
                    <Input
                      label={t('card_postal_code')}
                      name="cc-zip"
                      type="text"
                      placeholder="00907..."
                      required
                      autoComplete="postal-code"
                      value={formik.values['cc-zip']}
                      onChange={formik.handleChange}
                      error={
                        formik.errors['cc-zip'] &&
                        formik.touched['cc-zip'] &&
                        formik.errors['cc-zip']
                      }
                    />
                  </Form>
                  <Button
                    text={t('save')}
                    textColor="text-white"
                    disabled={formik.isSubmitting}
                    onClick={() => formik.submitForm()}
                    loading={formik.isSubmitting}
                    loaderColor="bg-white"
                    className="bg-accent hover:bg-accent w-20"
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default StripeModal;

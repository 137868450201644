import React from 'react';
import { motion } from 'framer-motion';

function Delivery({ className }) {
  return (
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="135"
        viewBox="0 0 150 135"
        className={className}
      >
        <g fill="none">
          <path
            fill="#06B6D4"
            d="M57.5471698,79.245283 L22.0760993,79.245283 L21.7000537,68.4475114 C21.6671258,67.5002368 22.054595,66.5861468 22.7604705,65.945851 C23.4663459,65.3055552 24.4199073,65.0032037 25.3701552,65.1183823 L46.0119338,67.6208593 L46.0144616,67.6208593 C52.3858655,67.6378548 57.5429963,72.7541942 57.5471698,79.0623473 L57.5471698,79.245283 Z"
          />
          <path
            fill="#CCC"
            d="M41.7973998,39.6226415 L16.6931437,39.6226415 C16.0188548,39.6226415 15.372131,39.8902407 14.8953367,40.3670353 C14.4185424,40.8438299 14.1509434,41.4905541 14.1509434,42.1648434 L14.1509434,67.2691151 C14.1509434,67.9434048 14.4185423,68.5901294 14.8953365,69.0669246 C15.3721306,69.5437197 16.0188544,69.8113208 16.6931437,69.8113208 L41.7973998,69.8113208 C42.4716927,69.8113208 43.1184217,69.543727 43.5952205,69.0669314 C44.0720194,68.5901358 44.3396226,67.9434084 44.3396226,67.2691151 L44.3396226,42.1648434 C44.3396226,41.4905505 44.0720192,40.8438234 43.5952203,40.3670284 C43.1184214,39.8902334 42.4716924,39.6226415 41.7973998,39.6226415 Z"
          />
          <path
            fill="#3F3D56"
            d="M36.5235316,58.9865616 L24.8283253,61.2714729 C23.3838842,61.5519951 21.9856907,60.6065315 21.7028512,59.1580157 L20.8038387,54.5264457 C20.5242275,53.0773 21.4666216,51.674553 22.9104336,51.3907939 L34.6056398,49.1058862 C36.0500815,48.8253619 37.4482763,49.7708264 37.731114,51.2193435 L38.6301228,55.8509097 C38.9097363,57.3000546 37.9673433,58.7028025 36.5235316,58.9865616 Z"
          />
          <path
            fill="#FFF"
            d="M33.44506 53.0621379L24.3464998 54.7059344C23.9999459 54.7685448 23.6642996 54.5586632 23.5968128 54.2371509 23.5293259 53.9156386 23.7555544 53.6042457 24.1021083 53.5416353L33.2006722 51.8978388C33.5472261 51.8352295 33.8828716 52.0451118 33.9503574 52.3666241 34.0178432 52.6881364 33.7916139 52.9995285 33.44506 53.0621379L33.44506 53.0621379zM31.5864106 55.8058159L27.1353993 56.5935391C26.8076336 56.6515459 26.490184 56.4570964 26.4263559 56.1592242 26.3625278 55.8613519 26.5764915 55.572855 26.9042573 55.5148482L31.355272 54.727125C31.5672993 54.6896018 31.785248 54.7577276 31.9270189 54.90584 32.0687898 55.0539525 32.1128446 55.2595498 32.0425882 55.4451855 31.9723318 55.6308212 31.7984379 55.7682927 31.5864106 55.8058159L31.5864106 55.8058159z"
          />
          <path
            fill="#B3B3B3"
            d="M44.3396226,43.0137668 L44.3396226,46.2264151 L14.1509434,46.2264151 L14.1509434,43.0137668 C14.1509434,42.3645353 14.4185426,41.7418445 14.8953372,41.2827685 C15.3721317,40.8236924 16.0188559,40.5658634 16.6931453,40.5660377 L41.797417,40.5660377 C42.4717067,40.5658634 43.1184313,40.8236923 43.5952265,41.2827683 C44.0720216,41.7418442 44.3396226,42.3645349 44.3396226,43.0137668 Z"
          />
          <path
            fill="#FFB8B8"
            d="M104.529027,47.6190994 C104.179893,46.4939646 103.349498,45.5810074 102.261286,45.1258875 C101.173074,44.6707675 99.93883,44.7202364 98.8906653,45.2609828 L64.7128843,33.0188679 L63.2075472,41.5429069 L97.2765378,50.8961621 C98.3262224,52.6319874 100.510462,53.3096784 102.360762,52.473614 C104.211061,51.6375495 105.142572,49.5519956 104.529027,47.6190994 L104.529027,47.6190994 Z"
          />
          <path
            fill="#000"
            d="M104.529027,47.6190994 C104.179893,46.4939646 103.349498,45.5810074 102.261286,45.1258875 C101.173074,44.6707675 99.93883,44.7202364 98.8906653,45.2609828 L64.7128843,33.0188679 L63.2075472,41.5429069 L97.2765378,50.8961621 C98.3262224,52.6319874 100.510462,53.3096784 102.360762,52.473614 C104.211061,51.6375495 105.142572,49.5519956 104.529027,47.6190994 L104.529027,47.6190994 Z"
            opacity=".2"
          />
          <path
            fill="#06B6D4"
            d="M70.636033 33.8735443L67.3930819 42.2545923C67.206363 42.7371206 66.8165763 43.1153532 66.3252921 43.2907304 65.8340079 43.4661076 65.2898874 43.4212587 64.8347171 43.1678702L56.9531669 38.7795691C54.3911099 37.7926347 53.1193875 34.9409858 54.1097851 32.403707 55.1001826 29.8664282 57.9792298 28.6003053 60.5469056 29.5728449L69.3479944 31.5038011C69.8578636 31.615828 70.2931888 31.9418513 70.540532 32.3969152 70.7878752 32.851979 70.822737 33.3910093 70.636033 33.8735443L70.636033 33.8735443zM106.792451 55.6603774L94.3396226 61.193077 95.974562 67.2093347C99.1317581 78.8271467 99.9696362 90.9306753 98.4423955 102.858308L98.4423955 102.858308 107.547171 104.716981 115.09434 85.9058022 112.452831 64.5126968 106.792451 55.6603774z"
          />
          <path
            fill="#3F3D56"
            d="M130.188679,83.0188679 C122.049272,83.0188679 115.416216,88.8830189 115.09434,96.2264151 L145.283019,96.2264151 C144.961138,88.883004 138.328083,83.0188679 130.188679,83.0188679 Z"
          />
          <path
            fill="#3F3D56"
            d="M124.4669,83.6978709 C124.04788,50.8221363 105.205275,53.8466257 105.205275,53.8466257 C105.205275,53.8466257 105.557748,64.0797354 105.727098,64.397979 C117.734315,86.9721614 97.9178662,113.437141 72.6933215,108.568437 C72.2177331,108.476642 71.7608191,108.383518 71.3225794,108.289066 C64.6890938,106.912899 60.2735185,100.651623 61.2359595,93.9862615 C63.7207634,74.1587654 51.361987,74.5096381 51.361987,74.5096381 L33.2905248,74.5096381 L23.9056981,66.1168021 C23.2330704,65.5152717 22.3040394,65.2843042 21.4255883,65.5002191 C20.5471371,65.716134 19.8335973,66.3508296 19.5207465,67.1945812 L18.3133724,70.4508505 C18.3133724,70.4508505 0.489392424,72.2957624 1.97472409,89.6379335 L7.48524514,89.6379335 C7.47193987,90.1306773 7.49180842,90.6237537 7.54471782,91.113863 L48.6701799,90.7816165 C51.0808249,90.7621416 53.2087036,92.7031586 53.2186368,95.0986031 C53.2236192,96.2769654 52.7458151,97.4066079 51.8951556,98.2276272 C51.0444961,99.0486465 49.8942315,99.4903444 48.7090102,99.451097 L42.0786901,99.231475 C40.0363591,108.271543 45.0493535,114.359752 50.5729306,118.187944 C55.7734467,121.740017 61.9444429,123.623342 68.2549351,123.584906 L86.2673071,123.584906 C109.661281,123.584906 113.745943,104.028246 113.745943,104.028246 C125.999918,98.8624742 124.4669,83.6978709 124.4669,83.6978709 Z"
          />
          <circle cx="129.245" cy="114.151" r="20.755" fill="#3F3D56" />
          <circle cx="129.717" cy="114.623" r="5.189" fill="#FFF" />
          <circle cx="24.057" cy="110.849" r="24.057" fill="#3F3D56" />
          <circle cx="24.528" cy="110.377" r="5.66" fill="#FFF" />
          <path
            fill="#3F3D56"
            d="M94.403583,62.2641509 L89.9384751,50.3368527 C89.4685062,49.0814327 89.524886,47.6938194 90.0952111,46.4792824 C90.6655363,45.2647454 91.7030868,44.3227771 92.9796058,43.860607 L105.78055,39.2259967 C108.026562,38.412579 110.50914,38.5099731 112.682136,39.4967528 C114.855132,40.4835326 116.540541,42.2788645 117.367586,44.4877888 C117.736825,45.469491 117.925413,46.5080013 117.924528,47.5547145 C117.91483,50.2462542 116.673462,52.7904579 114.544613,54.4818965 C113.785269,55.081006 112.931677,55.554338 112.017435,55.8832621 L94.403583,62.2641509 Z"
          />
          <path
            fill="#06B6D4"
            d="M117.924528,47.1567155 C117.922482,49.7292735 116.746067,52.1623797 114.725251,53.7735849 C111.627094,52.9625715 109.045292,50.8400429 107.665847,47.9699695 C106.286402,45.0998961 106.248998,41.7729051 107.563574,38.8730011 C110.089125,38.3279422 112.727554,38.9465938 114.740247,40.5557676 C116.752941,42.1649415 117.923608,44.5917113 117.924528,47.1567155 L117.924528,47.1567155 Z"
          />
          <ellipse
            cx="5.189"
            cy="85.849"
            fill="#06B6D4"
            rx="5.189"
            ry="6.604"
          />
          <polygon
            fill="#FFB8B8"
            points="73.586 108.491 78.06 108.491 80.189 97.17 73.585 97.17"
          />
          <path
            fill="#2F2E41"
            d="M72.6415094 107.547526L81.2842234 107.54717 81.2845814 107.54717C82.7453567 107.54717 84.1463155 108.12465 85.1792481 109.152678 86.2121806 110.180706 86.7924528 111.575027 86.7924528 113.02889L86.7924528 113.207027 72.6417815 113.207547 72.6415094 107.547526zM79.4586758 103.773585C79.4062576 103.773585 79.3536289 103.771078 79.3007899 103.766064L73.0736767 103.313071C72.5632791 103.265758 72.1038001 102.984354 71.8300118 102.551399 71.5562235 102.118445 71.4991637 101.583021 71.6755689 101.102176L80.00939 82.3955226C80.1297605 82.0583627 80.1036422 81.6862877 79.9373471 81.3692144 79.7714265 81.0482377 79.4761043 80.8134171 79.1257933 80.723921 75.2069976 79.700484 65.1524958 76.9753584 56.4487743 73.7868443 52.7198543 72.4207045 50.3713001 70.4465125 49.4684384 67.9190595 48.2781243 64.5869635 50.0390453 61.548155 50.1145823 61.4206636L50.1736326 61.3207547 58.3636394 62.0645232 67.2423502 62.8190357 86.6994952 73.243253C90.0804172 75.069961 91.4892333 79.1853989 89.9361238 82.698102L80.9657454 102.795618C80.7006247 103.390224 80.1102006 103.773585 79.4586758 103.773585L79.4586758 103.773585z"
          />
          <circle cx="58.962" cy="14.623" r="8.962" fill="#FFB8B8" />
          <polygon
            fill="#FFB8B8"
            points="71.699 109.434 76.173 109.434 78.302 98.113 71.698 98.114"
          />
          <path
            fill="#2F2E41"
            d="M69.8113208 108.490922L78.4540348 108.490566 78.4543927 108.490566C79.9151681 108.490566 81.3161269 109.068046 82.3490594 110.096074 83.3819919 111.124103 83.9622642 112.518423 83.9622642 113.972286L83.9622642 114.150423 69.8115928 114.150943 69.8113208 108.490922zM76.6284871 104.716981C76.5760689 104.716981 76.5234402 104.714474 76.4706012 104.70946L70.2434881 104.256467C69.7330904 104.209154 69.2736114 103.92775 68.9998231 103.494795 68.7260348 103.061841 68.668975 102.526417 68.8453802 102.045572L77.1792014 83.3389188C77.2995718 83.001759 77.2734536 82.6296839 77.1071584 82.3126106 76.9412379 81.9916339 76.6459156 81.7568134 76.2956046 81.6673173 72.3768089 80.6438803 62.3223072 77.9187547 53.6185856 74.7302406 49.8896656 73.3641007 47.5411115 71.3899087 46.6382497 68.8624557 45.4479356 65.5303597 47.2088566 62.4915512 47.2843936 62.3640598L47.3434439 62.2641509 55.5334508 63.0079194 64.4121615 63.7624319 83.8693066 74.1866492C87.2502285 76.0133573 88.6590446 80.1287951 87.1059351 83.6414982L78.1355567 103.739014C77.870436 104.33362 77.280012 104.716981 76.6284871 104.716981L76.6284871 104.716981z"
          />
          <path
            fill="#06B6D4"
            d="M62.1799117,28.8354876 L52.5437931,25.4716981 C52.5437931,25.4716981 46.4945344,30.1585782 49.4005654,41.785539 C50.91769,47.7854908 50.4757747,54.1188677 48.1404242,59.8454446 C47.8592368,60.5539698 47.5350868,61.2443764 47.1698113,61.9127511 C47.1698113,61.9127511 57.9177898,74.9941548 67.9245283,65.2765407 L64.0330189,46.401944 C64.0330189,46.401944 70.1482406,33.8811719 62.1799117,28.8354876 Z"
          />
          <path
            fill="#2F2E41"
            d="M60.9156138,24.4350822 C59.6083296,22.4716498 58.5766259,19.749106 60.0159542,17.8809725 C61.4367219,16.0369263 64.2389673,16.308601 66.4149159,15.492624 C69.4456318,14.3561109 71.203361,10.7834798 70.6551657,7.58395754 C70.1069704,4.38443529 67.5472944,1.73212736 64.4979769,0.646888642 C61.4486593,-0.438350079 58.0100645,-0.0854072399 55.023953,1.16431174 C51.3480094,2.70273049 48.1946887,5.67869288 46.8724117,9.44845863 C45.5501347,13.2182244 46.2569939,17.7445498 49.0000797,20.6420733 C51.9409699,23.7485332 56.5565101,24.5764131 60.8262892,24.5261782"
          />
          <path
            fill="#2F2E41"
            d="M51.6103502,7.7141058 C49.9749879,9.02259605 47.4799284,8.44142068 45.7244656,7.29305662 C43.9690028,6.14469256 42.5445561,4.50949482 40.645191,3.60913259 C37.2966716,2.02182821 33.2481879,3.07701461 29.9892546,4.83523532 C26.7303214,6.59345603 23.8414204,9.02248293 20.3990229,10.4022385 C16.9566254,11.7819941 12.5638752,11.8501978 9.95725806,9.24491245 C8.64303032,12.987342 9.85832563,17.1378848 12.9954835,19.6212256 C16.1326414,22.1045663 20.5175438,22.3870804 23.9576931,20.3275078 C27.7295214,18.0693534 29.8129298,13.3346401 34.0434447,12.0882707 C36.3844552,11.3985772 38.8982228,11.9540708 41.2642381,12.5557317 C43.6302533,13.1573927 46.0973009,13.8074944 48.4850129,13.2952927 C50.8727249,12.7830911 53.1061467,10.6741596 52.8023777,8.29075639 L51.6103502,7.7141058 Z"
          />
          <path
            fill="#FFB8B8"
            d="M103.404303,46.2748306 C102.914986,45.2127021 101.970773,44.417544 100.825735,44.1033193 C99.6806976,43.7890946 98.4524576,43.9880817 97.4715129,44.6467366 L61.7645736,36.7924528 L61.3207547,45.3449478 L96.5590631,50.3761605 C97.8250847,51.952502 100.094014,52.3516809 101.840415,51.3053234 C103.586817,50.2589659 104.258681,48.0978086 103.404303,46.2748306 Z"
          />
          <path
            fill="#06B6D4"
            d="M68.815041,38.0785456 L66.6173247,46.7659217 C66.4907841,47.2660905 66.1472609,47.6877755 65.6762713,47.9210929 C65.2052816,48.1544103 64.6534769,48.1762501 64.1646477,47.9809213 L55.7002076,44.5980444 C53.002258,43.9348654 51.3640658,41.2669604 52.0384041,38.634554 C52.7127425,36.0021476 55.445053,34.3989567 58.1458581,35.0509882 L67.22326,35.8905164 C67.7491571,35.9393129 68.2272291,36.2089217 68.5328993,36.6290878 C68.8385695,37.0492539 68.9415614,37.5783599 68.8150373,38.0785308 L68.815041,38.0785456 Z"
          />
          <circle cx="100" cy="50.943" r="2.83" fill="#06B6D4" />
        </g>
      </svg>
    </motion.div>
  );
}

export default Delivery;

import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { orderAtom } from '../../../atoms/Atoms';
import CounterV10 from './shared-components/CounterV10';
import envConfig from '../../../envConfig';

function Counter({ product }) {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [order, setOrder] = useRecoilState(orderAtom);

  useEffect(() => {
    if (order.length > 0) {
      const filter = _.filter(order, (p) => p.id === product.id);
      let amount = 0;
      for (let i = 0; i < filter.length; i++) {
        amount += filter[0].amount;
      }
      setCount(amount);
    } else {
      setCount(0);
    }
  }, [order]);

  if (count === 0) {
    return (
      <button
        type="button"
        disabled={!product.isAvailable}
        onClick={(e) => {
          e.stopPropagation();
          const tempProduct = JSON.parse(JSON.stringify(product));
          if (tempProduct.hasRandomWeight) {
            tempProduct.amount = product.minQuantity;
            setOrder([...order, { ...tempProduct, uuid: uuidv4() }]);
          } else {
            let newOrder = order;
            for (let i = 0; i < tempProduct.minQuantity; i++) {
              tempProduct.uuid = uuidv4();
              tempProduct.amount = 1;
              newOrder = newOrder.concat(tempProduct);
            }
            setOrder(newOrder);
          }
        }}
        className="w-24 h-8 text-xs rounded-md font-semibold text-white bg-accent disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-accent transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        {product.isAvailable ? t('add') : t('not_available')}
      </button>
    );
  }

  if (envConfig.CounterVersion === '10') { 
    return <CounterV10 product={product} />
  } 
  return (
    <div className="w-24 flex items-center rounded-md">
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          if (count !== 0) {
            let newOrder = JSON.parse(JSON.stringify(order));
            if (product.hasRandomWeight) {
              const index = _.findIndex(order, (o) => o.id === product.id);
              if (newOrder[index].amount - product.stepAmount < product.minQuantity) {
                newOrder = _.filter(order, (p, i) => i !== index);
              } else {
                newOrder[index].amount -= product.stepAmount;
              }
            } else {
              const tempAmount = _.filter(order, (p) => p.id === product.id).length;
              if (tempAmount - 1 < product.minQuantity) {
                newOrder = _.filter(order, (p, i) => p.id !== product.id);
              } else {
                const index = _.findIndex(order, (o) => o.id === product.id);
                newOrder = _.filter(order, (p, i) => i !== index);
              }
            }
            setOrder(newOrder);
          }
        }}
        className="bg-white w-8 h-8 border rounded-l-md font-semibold text-gray-500 hover:text-gray-400 disabled:cursor-not-allowed transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        -
      </button>
      <div className="bg-white text-xs flex items-center justify-center font-extrabold tracking-tight w-8 h-8 border-t border-b">
        {count}
      </div>
      <button
        type="button"
        disabled={!product.isAvailable}
        onClick={async (e) => {
          e.stopPropagation();
          const tempProduct = JSON.parse(JSON.stringify(product));
          const index = _.findIndex(order, (o) => o.id === product.id);
          if (index > -1) {
            if (tempProduct.hasRandomWeight) {
              if (order[index].amount + tempProduct.stepAmount <= tempProduct.maxQuantity 
                && order[index].amount + tempProduct.stepAmount <= tempProduct.quantityOnHand) {
                const newOrder = JSON.parse(JSON.stringify(order));
                newOrder[index].amount += tempProduct.stepAmount;
                setOrder(newOrder);
              }
            } else {
              const tempAmount = _.filter(order, (p) => p.id === product.id).length;
              if (tempAmount + 1 <= tempProduct.maxQuantity 
                && tempAmount + 1 <= tempProduct.quantityOnHand) {
                tempProduct.amount = 1;
                setOrder([...order, { ...tempProduct, uuid: uuidv4() }]);
              }
            }
          }
        }}
        className="bg-white w-8 h-8 border rounded-r-md font-semibold text-gray-500 hover:text-gray-400 disabled:cursor-not-allowed transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        +
      </button>
    </div>
  );
}

export default Counter;

import Recoil, { selector } from 'recoil';
import _ from 'lodash';
import {
  orderAtom,
  pageAtom,
  paymentAtom,
  referenceOrderAtom,
  serviceAtom,
  sizeAtom,
  storeAtom,
  stateAtom,
  locationAtom,
  timeAtom,
  promoCodeAtom,
  timeSlotAtom,
  tokenAtom,
  refreshStoresAtom
} from './Atoms';
import api from '../api/api';

export const bannerAdsSelector = selector({
  key: 'bannerAds',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const response = await api({
      method: 'GET',
      url: 'businesses/bannerads'
    });
    if (response.error) {
      throw response.error;
    }
    return response;
  }
});

export const bannerAdsListSelector = selector({
  key: 'bannerAdsList',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const response = await api({
      method: 'GET',
      url: 'businesses/list/bannerads'
    });
    if (response.error) {
      throw response.error;
    }
    return response;
  }
});

export const businessesSelector = selector({
  key: 'businesses',
  get: async ({ get }) => {
    get(refreshStoresAtom);
    const token = get(tokenAtom);
    const response = await api({
      method: 'GET',
      url: 'businesses/fast',
    });

    if (response.error) {
      throw response.error;
    }
    return response;
  },
  set: ({ set }, value) => {
    if (value instanceof Recoil.DefaultValue) {
      set(refreshStoresAtom, (v) => v + 1);
    }
  }
});

export const minimumSelector = selector({
  key: 'minimum',
  get: ({ get }) => {
    const service = get(serviceAtom);
    const store = get(storeAtom);

    if (store) {
      if (service === 0) {
        return store.minimumPickupOrderAmount;
      }
      return store.minimumDeliveryOrderAmount;
    }
  },
});

export const checkoutSelector = selector({
  key: 'checkout',
  get: ({ get }) => {
    const payment = get(paymentAtom);
    const order = get(orderAtom);
    const state = get(stateAtom);
    const location = get(locationAtom);
    const store = get(storeAtom);
    const service = get(serviceAtom);
    const time = get(timeAtom);
    const promoCode = get(promoCodeAtom);
    const timeSlot = get(timeSlotAtom);
    const referenceOrder = get(referenceOrderAtom);
    let serviceCost = 0;
    let processingFee = 0;
    let deliveryTotal = 0;
    let subtotal = 0;
    let entireSubtotal = 0;
    let paymentType = 0;
    let items;
    let excludedItems;
    let taxExcluded = false;
    let allowNonFoodProducts = true;
    let muniTax = 0;
    let stateTax = 0;
    let itemCount = 0;
    let discount = 0;

    if (store && store?.locations) {
      processingFee =
        service === 0
          ? store.locations[0].pickupFee
          : store.locations[0].deliveryFee;
    }

    if (payment.card && !payment.card.last4) {
      paymentType = payment.card.paymentType;
      taxExcluded = payment.card.taxExcluded;
      allowNonFoodProducts = payment.card.allowNonFoodProducts;
    } else if (payment.card) {
      taxExcluded = payment.card.taxExcluded;
      allowNonFoodProducts = payment.card.allowNonFoodItems;
    }

    if (allowNonFoodProducts) {
      items = order;
      excludedItems = [];
    } else {
      items = _.filter(order, (product) => product.isEBT);
      excludedItems = _.filter(order, (product) => !product.isEBT);
    }

    _.forEach(items, (e) => {
      const price = e.weightType === 4 ?
        (e.activePrice / e.unitPriceFactor).toFixed(2) : e.activePrice;
      if (!taxExcluded) {
        muniTax += price * e.munTaxPct * e.amount;
        stateTax += price * e.stateTaxPct * e.amount;
      }
      subtotal += price * e.amount;
      entireSubtotal += price * e.amount;
      if (e.hasRandomWeight && e.weightType !== 4) {
        itemCount += 1;
      } else {
        itemCount += e.amount;
      }
    });
    _.forEach(excludedItems, (e) => {
      const price = e.weightType === 4 ?
        (e.activePrice / e.unitPriceFactor).toFixed(2) : e.activePrice;
      entireSubtotal += price * e.amount;
      if (e.hasRandomWeight && e.weightType !== 4) {
        itemCount += 1;
      } else {
        itemCount += e.amount;
      }
    });

    if (state?.isOrderServiceFeeFixed) {
      processingFee = state?.orderServiceFee;
    } else if (state) {
      processingFee = state?.orderServiceFee * parseFloat(subtotal);
    }

    if (service === 0 && store?.locations) {
      if (store?.locations[0].pickupFeeType === 2) {
        processingFee += (store?.locations[0].pickupFee * subtotal);
      } else if (store.locations[0].pickupFeeType === 1) {
        processingFee += store?.locations[0].pickupFee;
      }
    } else if (service === 1 && location.location !== null) {
      deliveryTotal += location.location.deliveryCost;
    }

    let minimum = store?.minimumPickupOrderAmount;
    if (service === 1) {
      minimum = store?.minimumDeliveryOrderAmount;
    }

    if (promoCode && !(
      (promoCode?.appliesTo === 0 && service !== 0) ||
      (promoCode?.appliesTo === 1 && service !== 1) ||
      promoCode?.minimumAmount > subtotal
    )) {
      if (promoCode.type === 0) {
        // free delivery
        discount = deliveryTotal;
      } else if (promoCode.type === 4) {
        // gift card
        discount = promoCode.value;
      }
    }

    serviceCost = processingFee + deliveryTotal;

    return {
      type: paymentType,
      serviceCost,
      processingFee,
      deliveryTotal,
      order: items,
      excludedItems,
      allItems: order,
      time: referenceOrder !== null ? referenceOrder.startSlotTime : time,
      startSlotTime:
        referenceOrder !== null ? referenceOrder.startSlotTime : timeSlot?.time,
      endSlotTime:
        referenceOrder !== null
          ? referenceOrder.endSlotTime
          : timeSlot?.endTime,
      payment: payment.card,
      muniTax: muniTax.toFixed(2),
      stateTax: stateTax.toFixed(2),
      subtotal: subtotal.toFixed(2),
      discountTotal: discount,
      total: ((subtotal + muniTax + stateTax + serviceCost) - discount).toFixed(2),
      hasMinimum: entireSubtotal >= minimum || referenceOrder !== null,
      itemCount
    };
  },
});

export const ordersSelector = selector({
  key: 'orders',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const page = get(pageAtom);
    const size = get(sizeAtom);

    if (token) {
      const response = await api({
        method: 'POST',
        url: 'users/v2/orders',
        data: {
          Page: page,
          Size: size,
          Query: '',
        },
      });

      if (response.error) {
        throw response.error;
      }

      return response;
    }

    return null;
  },
});

export const infoCardsSelector = selector({
  key: 'infocards',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const response = await api({
      method: 'GET',
      url: 'users/start/cards',
    });

    if (response.error) {
      throw response.error;
    }
    return response;
  },
});

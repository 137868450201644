import React from 'react';
import { motion } from 'framer-motion';

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.5,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const loadingCircleVariants = {
  start: {
    scale: 1,
    opacity: 1,
  },
  end: {
    scale: 1.25,
    opacity: 0.25,
  },
};

const loadingCircleTransition = {
  duration: 1,
  repeat: Infinity,
  repeatType: 'mirror',
  ease: 'easeInOut',
};

function Loader({ color }) {
  return (
    <motion.div
      className="flex space-x-1"
      variants={loadingContainerVariants}
      initial="start"
      animate="end"
    >
      <motion.span
        className={`${color || 'bg-gray-500'} block w-2 h-2 rounded-full`}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span
        className={`${color || 'bg-gray-500'} block w-2 h-2 rounded-full`}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span
        className={`${color || 'bg-gray-500'} block w-2 h-2 rounded-full`}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
    </motion.div>
  );
}

export default Loader;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _, { get } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { envAtom, orderDetailsAtom, userAtom } from '../../../atoms/Atoms';
import OrderDetails from '../profile/shared-components/orders/shared-components/details-modal/shared-components/OrderDetails';
import api from '../../../api/api';

require('dayjs/locale/es');

dayjs.locale('en');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Order() {
  const { code } = useParams();
  const env = useRecoilValue(envAtom);
  const user = useRecoilValue(userAtom);
  const [order, setOrder] = useState(null);
  const [err, setError] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const setOrderDetails = useSetRecoilState(orderDetailsAtom);
  const { t } = useTranslation();

  // Fetch order details if order id exists
  useEffect(() => {
    if (code) {
      api.get(`order/${code}`)
        .then((response) => {
          if (response.status === 200 && response.data.error) {
            setError(response.data.error);
          } else if (response.status === 200) {
            setOrder(response.data.data);
            setOrderDetails(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [code]);

  useEffect(() => {
    if (user && order) {
      const role = _.filter(user.roles, (r) => r === 'SuperAdmin');
      const customerID = user.id;
      const orderID = order.customerId;
      if (customerID === orderID || role.length > 0) {
        setShowChat(true);
      }
    }
  }, [user, order]);

  return (
    <div className="mt-12">
      <OrderDetails />
    </div>
  );
}

export default Order;

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Disclosure, RadioGroup, Tab } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import SpecialsBanner from '../../../assets/img/specials/specials_banner.png';
import api from '../../../api/api';
import { notificationsAtom, storeAtom } from '../../../atoms/Atoms';
import ItemTimeConstraintWarning from '../../shared-components/ItemTimeConstraintWarning';
import Counter from '../../shared-components/counter/Counter';
import { backdropVariants } from '../../../utils';
import Price from '../../shared-components/modal/shared-components/Price';
import SpecialPrice from '../../shared-components/modal/shared-components/SpecialPrice';
import ItemTimeConstraint from '../../shared-components/ItemTimeConstraint';

function Product() {
  const [valid, setValid] = useState(true);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const { productId } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [store, setStore] = useRecoilState(storeAtom);
  const ref = useRef();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function searchProduct() {
    if (productId) {
      api.get(`catalog/item/${productId}`)
        .then((response) => {
          setLoading(false);
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('event_not_found')}`,
                description: response.data.error,
                error: true,
              },
            ]);
            history.push(`/stores/${store && store.id}`); 
          } else {
            setProduct(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useMount(() => {
    searchProduct();
  });

  useEffect(() => {
    searchProduct();
  }, [productId])

  return (
    <AnimatePresence>
      <div className="bg-white flex flex-col overflow-y-auto">
        <div>
          <div
            ref={ref}
            className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
          >              
            <div className="flex">
              {product && product?.hasActivePromo && (
                <div style={{ position: 'relative', top: 0, left: 0 }}>
                  <img alt={product?.imageUrl} name={product?.name} src={product?.imageUrl} />
                  <img alt={product?.name} className="mt-0 md:mt-4" style={{ position: 'absolute', top: 0, left: 0 }} name={product?.name} src={SpecialsBanner} /> 
                </div>)}
              {product && !product.hasActivePromo && (
                <div className="bg-white w-1/2">
                  <div className="lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-6">
                      {/* Image gallery */}
                      <Tab.Group as="div" className="flex flex-col-reverse">
                        {/* Image selector */}
                        <div className="mx-auto mt-4 hidden w-full max-w-2xl sm:block lg:max-w-none">
                          <Tab.List className="grid grid-cols-3 gap-6">
                            {product?.images.map((image) => (
                              <Tab
                                key={image.id}
                                className="relative flex h-64 w-64 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                              >
                                {({ selected }) => (
                                  <>
                                    <span className="sr-only">{image.name}</span>
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                      <img src={image.url} alt="" className="h-full w-full object-cover object-center" />
                                    </span>
                                    <span
                                      className={classNames(
                                        selected ? 'ring-indigo-500' : 'ring-transparent',
                                        'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </Tab>
                            ))}
                          </Tab.List>
                        </div>

                        <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                          {product?.images.map((image) => (
                            <Tab.Panel key={image.id}>
                              <img
                                src={image.url}
                                alt={image.name}
                                width="75%"
                                height="75%"
                                className="object-cover object-center sm:rounded-lg"
                              />
                            </Tab.Panel>
                          ))}
                        </Tab.Panels>
                      </Tab.Group>
                  </div>
                </div>
                  )}
                  <div className="flex flex-col flex-1">
                    <div className="text-gray-500 text-sm mb-1">
                      {product?.sectionName}
                    </div>
                    <div className="font-normal tracking-tight text-sm md:text-sm uppercase">
                      {product?.brand}
                    </div>
                    <div className="font-extrabold tracking-tight text-2xl md:text-3xl capitalize">
                      {_.toLower(product?.name)}
                    </div>                   
                    <div className="capitalize font-light tracking-tight text-sm md:text-sm">
                      {_.toLower(product?.description)}
                    </div>
                    {product?.activePrice && !product.hasActivePromo && (
                      <Price
                        className="text-sm md:text-lg"
                        hasRandomWeight={product?.hasRandomWeight}
                        weightType={product?.weightType}
                        unitPriceFactor={product?.unitPriceFactor}
                        price={product?.activePrice}
                      />
                    )}
                    {product?.activePrice && product.hasActivePromo && (
                      <SpecialPrice
                        className="text-sm md:text-lg"
                        hasRandomWeight={product?.hasRandomWeight}
                        weightType={product?.weightType}
                        isUnitPriceMethod={product?.baseUnitPriceMethod === 0
                          && product?.promoUnitPriceMethod === 0}
                        unitPriceFactor={product?.unitPriceFactor}
                        price={product?.activePrice}
                        savings={product?.savings}
                        regularPrice={product?.regularPrice}
                      />
                    )}
                    {product?.activePrice && !product.isAvailable && (
                      <div className="mb-4">
                        <span className="text-sm font-semibold tracking-tight rounded-full bg-rose-200 text-rose-500 px-2 py-1 ring-2 ring-rose-500 ring-offset-2">
                          {t('not_available')}
                        </span>
                      </div>
                    )}
                    {product?.activePrice && (
                      <div className="md:block">
                        <div className="text-gray-500 text-sm mb-4">
                          {product?.description2}
                        </div>
                        <Counter product={product} />
                        <div className="text-gray-600 text-sm mt-4">{`${t('in_stock')}: ${product?.quantityOnHand}`}</div>
                        <ItemTimeConstraint product={product} />
                      </div>
                    )}
                  </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  )
}

export default Product;

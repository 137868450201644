import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { departmentAtom, departmentDrawerAtom, storeAtom } from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';

function Breadcrumb() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const department = useRecoilValue(departmentAtom);
  const [openDepts, setDeptsOpen] = useRecoilState(departmentDrawerAtom);

  return (
    <div className="container mx-auto flex flex-shrink-0 items-center w-full text-xs tracking-tight px-4 space-x-1 truncate">
      {/* <NavLink
        to="/"
        className="hover:text-accent transition duration-500 ease-in-out"
      >
        {t('home')}
      </NavLink>
      <FontAwesomeIcon icon={faChevronRight} /> */}
      <NavLink
        to={`/stores/${store && store.id}`}
        className="hover:text-accent transition duration-500 ease-in-out"
      >
        {store && store.name}
      </NavLink>
      <FontAwesomeIcon icon={faChevronRight} />
      <NavLink
        to={`/stores/${store && store.id}/departments`}
        className={`${envConfig.DepartmentsStyle === 'sidebar-right' || envConfig.DepartmentsStyle === 'sidebar-left' ? 'hidden' : ''} hover:text-accent transition duration-500 ease-in-out`}
      >
        {t('departments')}
      </NavLink>
      <button
        type="button"
        key="deptdraw"
        className={`${envConfig.DepartmentsStyle === 'sidebar-right' || envConfig.DepartmentsStyle === 'sidebar-left' ? '' : 'hidden'} py-3 flex items-center space-x-2 text-xs tracking-tight transition-colors duration-200 ease-linear hover:bg-gray-100 ring-0 focus:ring-0 outline-none focus:outline-none`}
        onClick={() => {      
        setDeptsOpen(!openDepts); 
        }}
      >
      <div className="truncate hover:underline">{t('departments')}</div>
      </button>
      {department && (
        <>
          <FontAwesomeIcon icon={faChevronRight} />
          <div className="text-gray-400">{department && department.name}</div>
        </>
      )}
    </div>
  );
}

export default Breadcrumb;

import { XIcon } from '@heroicons/react/outline'
import React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { faBagsShopping } from '@fortawesome/pro-duotone-svg-icons';
import { orderAtom } from '../../../../atoms/Atoms';
import Counter from '../../../shared-components/counter/Counter';
import { thousand } from '../../../../functions';

function CheckoutItems() {
  const { t } = useTranslation();
  const [order, setOrder] = useRecoilState(orderAtom);

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };
  
  const getTotal = (id) => {
    const filter = _.filter(order, (e) => e.id === id);
    const price = filter[0].activePrice;
    let amount = 0;
    for (let i = 0; i < filter.length; i++) {
      amount += filter[i].amount;
    }
    return {
      subtotal: price.toFixed(2),
      units: amount.toFixed(2),
      total: (price * amount).toFixed(2),
    };
  };

    return (
      <div className="bg-white lg:col-span-6" style={{ overflowY: 'auto', maxHeight: '50%' }}>
        <section aria-labelledby="cart-heading">
          <h2 id="cart-heading" className="sr-only">
            {t('items_in_cart')}
          </h2>
          <ul className="divide-y divide-gray-200 border-b border-t border-gray-200">
            {_.map(_.orderBy(_.uniqBy(order, 'id'), 'name'),
            (e, index) => (
              <li key={e.id} className="flex py-6 sm:py-10">
                <motion.div
                  variants={variants}
                  key={index}
                  className="flex space-x-4"
                >
                  <div className="flex-shrink-0">
                    <img
                      src={e.imageUrl}
                      alt={e.name}
                      className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                    />
                  </div>
                  <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                    <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                      <div>
                        <div className="flex justify-between">
                          <h3 className="text-sm">
                            {e.brand}
                          </h3>
                        </div>
                        <div className="mt-1 flex text-sm">
                          <p className="text-gray-500">{e.name}</p>
                          <p className="ml-4 border-l border-gray-200 pl-4 text-gray-500">{e.description}</p>
                        </div>
                        <p className="mt-1 text-sm font-medium text-gray-900">${thousand(getTotal(e.id).total)}</p>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:pr-9">
                        <div className="absolute right-0 top-0">
                          <button
                            type="button"
                            className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                            onClick={(x) => {
                              x.stopPropagation();
                              let newOrder = JSON.parse(JSON.stringify(order));
                              newOrder = _.filter(order, (p, i) => p.id !== e.id);
                              setOrder(newOrder);
                            }}
                          >
                            <span className="sr-only">{t('remove')}</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <Counter product={e} />
                  </div>
                </motion.div>
              </li>
            ))}
          </ul>
        {order?.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 1,
              delay: 0.5,
            }}
            className="h-full pt-40 flex flex-col flex-1 items-center justify-center"
          >
            <FontAwesomeIcon icon={faBagsShopping} className="text-base" />
            <div>{t('store_alert_title')}</div>
            <div className="text-gray-400 text-xs text-center">
              {t('empty_cart_content')}
            </div>
          </motion.div>
        )}
        </section>
      </div>    
  )
}
export default CheckoutItems;

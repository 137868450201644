import React from 'react';
import { HostedField } from 'react-braintree-fields';

function BrainTreeInput({
  required,
  placeholder,
  type,
  label,
  error,
  className,
  uppercase,
  options
}) {
  return (
    <div className={`${className} w-full text-xs tracking-tight space-y-1`}>
      {label && (
        <div className="font-semibold">
          {label} {required && <span className="text-red-400">*</span>}
        </div>
      )}
      <HostedField
        type={type}
        options={options}
        placeholder={placeholder}
        className={`${uppercase ? 'uppercase' : ''} h-14 disabled:bg-gray-100 disabled:text-gray-400 placeholder-gray-400 text-gray-900 text-sm rounded-md p-3 w-full appearance-none focus:ring-1 focus:ring-accent border border-gray-200 focus:border-accent outline-none focus:outline-none transition ease-in-out duration-500`}
      />
      {error && <div className="text-xs font-light text-red-400">{error}</div>}
    </div>
  );
}

export default BrainTreeInput;

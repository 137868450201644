import React from 'react';
import { useHistory } from 'react-router-dom';
import Img from '../../../assets/img/banners/10.png';
import './shared-resources/banner.css';

function BannerV2({ title }) {
  const history = useHistory();

  return (
    <div className="bannerBackground">
    {/* <button
      type="button"
      onClick={() => history.push('/event/MVk')}
    >
    <img
      src={Img} 
      alt="40 Plus Tournament" 
      className="bannerImage"
    />
    </button> */}
    <button
      type="button"
      // onClick={() => history.push('/event/MVk')}
    >
    <img
      src={Img} 
      alt="4King and Queen Tournament" 
      className="bannerImage"
    />
    </button>
    {/* <button
      type="button"
      onClick={() => history.push('/event/PgW')}
    >
    <img
      src={Img} 
      alt="Open Play at MECA" 
      className="bannerImage"
    />
    </button> */}
    {/* <button
      id="floatingButton"
      type="button"
      className="registerButton"
      onClick={() => history.push('/events')}
    >Register
    </button> */}
      {/* <Carousel className="">
        <Image
          src={Img}
          alt="Lechon Bowl"
          onClick={() => history.push('/events')}
          width="100%"
          className="max-h-52"
          preview={false}
        />
      </Carousel> */}
    </div>
  );
}

export default BannerV2;

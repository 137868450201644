import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getEventTypeName
} from '../../../../functions';
import TimeSlotCard from './TimeSlotCard';

function TimeSlotDay({ day }) {
  const { t } = useTranslation();
  return (
    <div
      className="mx-2 text-left rounded-xl bg-white border h-5/6 overflow-y-hidden"
    >
        <div className="flex items-center space-x-2 text-gray-600 font-extrabold tracking-tight border-b border-gray-100 leading-none mb-2 p-4">
          <div className="line-clamp-1">{`${dayjs(day?.startDate).add(4, 'hour').format('dddd, MMMM DD, YYYY')}`}</div>
          <div className={`${dayjs().format('DD') !== dayjs(day?.startDate).format('DD') && 'hidden'} ml-2`}>({t('today')})</div>
        </div>
        <div className="text-md ml-4 hidden">{`Type: ${t(getEventTypeName(day?.type))}`}</div>
        <div className="text-sm ml-6">{`Slots: ${day?.timeSlots?.length}`}</div>
        <div className="h-full overflow-y-auto pb-20">
          {day?.timeSlots &&
            _.map(_.sortBy(day?.timeSlots, 'startTime'), (x, i) => (
              <TimeSlotCard key={i} timeSlot={x} />
          ))}
        </div>
    </div>
  );
}

export default TimeSlotDay;

import React, { useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';
import _ from 'lodash';
import { CheckIcon, ShareIcon, StarIcon, TicketIcon } from '@heroicons/react/outline';
// import ItemsCarousel from 'react-items-carousel';
// import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Spin } from 'antd';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { userAtom } from '../../../atoms/Atoms';
import bookingApi from '../../../api/bookingApi';
import { Spinner } from '../../shared-components/Spinner';
import useBreakpoint from '../../../hooks/useBreakpoint';
import DetailsDrawer from './shared-components/Drawer';
import './shared-components/subscriptions.css';
import { getRecurrenceLength, getRecurrence } from '../../../functions';

function Subscription() {
  const { i18n, t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleButtonClick = (index) => {
    setSelectedSubscription(index);
    openDrawer();
  };

      function getSubscriptions() {
        setLoading(true);
        bookingApi
          .get('subscriptions/active?includeBenefits=true')
          .then((response) => {
            setLoading(false);
            console.log('r', response);
            setSubscriptions(response.data.data);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }

      function getUserSubscriptions() {
        if (!user) return;
        setLoading(true);
        bookingApi
          .get(`subscriptions/${user.id}/active`)
          .then((response) => {
            setLoading(false);
            setUserSubscriptions(response.data.data);
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }

      useMount(() => {
        getSubscriptions();
      });

      useEffect(() => {
        getSubscriptions();
        getUserSubscriptions();
      }, [user]);

      return (
        <div className="overflow-auto bg-gray-100">
          {loading ? <Spinner /> : null}
          <div className="mx-auto max-w-7xl px-8 pb-96 pt-8 text-center sm:pt-12 lg:px-8">
            <div className="mx-auto max-w-2xl">
              <p className="mt-2 text-2xl md:text-4xl font-bold tracking-tight text-black sm:text-3xl">
                {t('class_packages')}
              </p>
              <p className="mt-2 text-lg font-semibold md:text-2xl  tracking-tight text-black sm:text-lg">
                {t('subscription_desc')}
              </p>
              <p className="mt-2 text-md font-light  md:text-xl  tracking-tight text-black sm:text-md">
                {t('subscription_desc2')}
              </p>
            </div>
          </div>
          <div className="flow-root bg-white pb-24 sm:pb-32">
            <div className="-mt-80">
              <div className="mx-auto max-w-7xl px-8 lg:px-10">
                <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-3">
                  {subscriptions.map((sub) => (
                    <div
                      key={sub.id}
                      className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                    >
                      <div>
                        {sub.isOnPromo && (
                          <div className="mb-2 flex flex-row">
                            <FontAwesomeIcon className="promo-icon" icon={faClock} />
                            <div className="promo-text">Limited Offer</div>
                          </div>
                        )}
                        <h3 id={sub.id} className="text-base font-semibold leading-7 text-indigo-600">
                          {sub.nameEn}
                        </h3>
                        
                        <p className="mt-2 text-sm text-gray-600">{i18n.language === 'es' ? sub.descriptionEs : sub.descriptionEn}</p>
                        {!sub.isOnPromo && (
                        <div className="mt-4 flex items-baseline gap-x-2">
                          <span className="text-3xl md:text-5xl font-bold tracking-tight text-gray-900">${sub.price}</span>
                          <span className="text-base font-semibold leading-7 text-gray-600">/{getRecurrenceLength(sub.recurrence)}</span>
                        </div>)}
                        {sub.isOnPromo && (
                        <div className="mt-4 flex items-baseline gap-x-2 flex-col">
                          <div>
                            <span className="text-3xl md:text-5xl font-bold tracking-tight text-gray-900">${sub.promoPrice}</span>
                            <span className="text-base font-semibold leading-7 text-gray-600">/{getRecurrenceLength(sub.recurrence)}</span>
                          </div>
                          <div>
                            <span className="text-md md:text-lg line-through font-light tracking-tight text-gray-900">was</span>
                            <span className="text-md md:text-lg line-through font-normal tracking-tight text-gray-900"> ${sub.price}</span>
                            <span className="text-md md:text-lg line-through font-semibold leading-7 text-gray-600">/{getRecurrenceLength(sub.recurrence)}</span>
                          </div>
                        </div>)}
                       
                        <ul className="mt-5 space-y-4 text-sm leading-6 text-gray-600">
                          {sub?.limit > 0 && sub?.limit && (
                            <li key="limit" className="flex gap-x-3">
                              <TicketIcon className="mt-1 h-4 w-5 flex-none text-indigo-600" aria-hidden="true" />
                              {`${sub.limit} spots per class`}
                            </li>)}
                          {sub.benefits.map((benefit) => (
                            <div>
                             {benefit?.limit && benefit.limit > 0 && (
                              <li key={benefit} className="flex gap-x-3">
                              <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                              {`${benefit?.nameEn} (${benefit?.limit} ${getRecurrence(benefit.recurrence)})`}                       
                              </li>)}
                            </div>
                          ))}
                        </ul>
                      </div>
                      <Button
                        type="primary"
                        className="sub-button mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => { console.log('clicked', sub); handleButtonClick(sub); }}
                      >
                        {t('purchase_now')}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <DetailsDrawer
            isOpen={isDrawerOpen}
            closeDrawer={closeDrawer}
            selectedSubscription={selectedSubscription}
          />
        </div>
      )
    }

    export default Subscription;

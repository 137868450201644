import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import 'cleave.js/dist/addons/cleave-phone.pr';
import { contactAtom, userAtom } from '../../../../atoms/Atoms';
import { phoneNumber } from '../../../../utils';
import Panel from '../../../shared-components/panel/Panel';
import { H3 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Input from '../../../shared-components/form/Input';
import Form from '../../../shared-components/form/Form';
import CleaveInput from '../../../shared-components/form/CleaveInput';
import Textarea from '../../../shared-components/form/Textarea';

function Contact() {
  const { t } = useTranslation();
  const [contact, setContact] = useRecoilState(contactAtom);
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    if (user) {
      setContact({
        ...contact,
        name: user.completeName,
        phone: phoneNumber(user.phoneNumber),
      });
    }
  }, [user]);

  return (
    <Panel>
      <H3 text={t('contact')} />
      <Subtitle
        text={t('contact_prompt')}
      />
      <Form>
        <Input
          required
          label={t('name')}
          name="name"
          type="text"
          value={contact.name}
          onChange={(e) => setContact({ ...contact, name: e.target.value })}
          placeholder="Juan del Pueblo"
          className="col-span-2 md:col-span-1"
        />
        <CleaveInput
          required
          label={t('phone')}
          name="phone"
          type="phone"
          options={{
            phone: true,
            phoneRegionCode: 'PR',
          }}
          value={contact.phone}
          onChange={(e) => {
            setContact({ ...contact, phone: e.target.rawValue });
          }}
          placeholder="787 777 7777"
          className="col-span-2 md:col-span-1"
        />
        <Textarea
          label={t('instructions')}
          name="instructions"
          rows="3"
          value={contact.instructions}
          onChange={(e) => {
            setContact({ ...contact, instructions: e.target.value });
          }}
          placeholder={t('instructions_placeholder')}
          className="col-span-2"
        />
      </Form>
    </Panel>
  );
}

export default Contact;

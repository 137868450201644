import React from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  getEventTypeName
} from '../../../../functions';
import TimeSlotCardLanding from './TimeSlotCardLanding';

function TimeSlotDayLanding({ day }) {
  const { t } = useTranslation();
  return (
    <div
      className="mx-2 text-left rounded-xl bg-white border border-white h-5/6 overflow-y-hidden mb-2"
    >
        {/* <div className="flex text-sm items-center space-x-2 text-gray-600 
        font-bold tracking-tight leading-none mb-2 p-2">
          <div className="line-clamp-1">{`${dayjs(day?.startTime).add(4, 'hour')
          .format('dddd, MMMM DD, YYYY')}`}</div>
          <div className={`${dayjs().format('DD') !== dayjs(day?.startTime).format
          ('DD') && 'hidden'} ml-2`}>({t('today')})</div>
        </div> */}
        <div className="h-full overflow-y-auto pb:6">
          {day &&
            _.map(_.sortBy(day, 'startTime'), (x, i) => (
              <TimeSlotCardLanding timeSlot={x} />
          ))}
        </div>
    </div>
  );
}

export default TimeSlotDayLanding;

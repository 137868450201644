import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import {
  orderDetailsModalAtom,
  orderHistoryAtom,
} from '../../../../../../atoms/Atoms';
import { mapOrderStatus } from '../../../../../../functions';

function Items() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const orderHistory = useRecoilValue(orderHistoryAtom);
  const setModal = useSetRecoilState(orderDetailsModalAtom);

  const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <>
      <motion.div
        initial={false}
        animate={orderHistory?.creditCardOrders?.length > 0 ? 'open' : 'closed'}
      >
        {orderHistory?.creditCardOrders?.length > 0 && (
          <motion.div variants={containerVariants} className="divide-y">
            {_.map(orderHistory?.creditCardOrders, (o) => (
              <motion.div
                key={o.id}
                variants={itemVariants}
                className="flex items-center justify-between text-xs py-3 px-2"
              >
                <div>
                  <div className="font-semibold">
                    ${o.orderTotal.toFixed(2)}
                  </div>
                  <div className="text-gray-500">
                    {o.nickName} @ {dayjs(o.date).locale(i18n.language).format('ll')}
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <div className="text-xs font-light tracking-tight text-center text-gray-400 border px-2 py-1 rounded-full bg-white w-24 truncate">
                    {t(mapOrderStatus(o.status))}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(o);
                      history.push(`/profile/orders/${o.confirmationCode}`);
                    }}
                    className="w-8 h-8 inline-flex items-center justify-center rounded-md hover:bg-gray-100 outline-none ring-0 focus:outline-none focus:ring-0 transition duration-500 ease-linear"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      />
                    </svg>
                  </button>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </motion.div>
    </>
  );
}

export default Items;

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import envConfig from './envConfig';

const useAnalyticsEventTracker = (category) => {
  const eventTracker = (event, data) => {
    if (envConfig.GoogleAnalyticsTrackingId) {
      ReactGA.event({ category, event, data });  
    }
    if (envConfig.FacebookPixelId) {
      ReactPixel.track(event, data);
    }
  }
  return eventTracker;
}

export default useAnalyticsEventTracker;

import React, { useEffect, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import is from 'is_js';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faTimesCircle,
  faCreditCardFront,
  faCreditCard
} from '@fortawesome/pro-duotone-svg-icons';  
import { faArrowCircleRight, faSpider } from '@fortawesome/free-solid-svg-icons';
import Placeholder from './shared-components/Placeholder';
import bookingApi from '../../../api/bookingApi';
import api from '../../../api/api';
import {
  selectedRentalTimeSlot,
  userPaymentsAtom,
  notificationsAtom,
  userAtom
} from '../../../atoms/Atoms';
import {
  fadeOutMotion,
  fadeInMotion
} from '../../../functions';
import { Spinner } from '../../shared-components/Spinner';
import Button from '../../shared-components/buttons/Button';
import {
  backdropVariants,
  sidebarVariants,
  modalVariants
} from '../../../utils';
import RentalSlotDay from './shared-components/RentalSlotDay';
import envConfig from '../../../envConfig';
import Select from '../../shared-components/form/Select';

function Rental() {
  const history = useHistory();
  const showPaymentButton = envConfig.ShowPaymentButtonOnBookingReservation;
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const paymentsRef = useRef();
  const endDateOptions = [
    { title: `4 ${t('days')}`, date: dayjs().utc().add(4, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: t('today'), date: dayjs().utc().add(1, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `3 ${t('days')}`, date: dayjs().utc().add(3, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `5 ${t('days')}`, date: dayjs().utc().add(5, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `7 ${t('days')}`, date: dayjs().utc().add(7, 'day').format('YYYY-MM-DDTHH:00:00.00') },
  ]
  // const startDate = dayjs().utc().format('YYYY-MM-DDTHH:00:00.00');
  const user = useRecoilValue(userAtom);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [timeSlotDays, setTimeSlotDays] = useState([]);
  const [selectedLocation, setSelectecLocation] = useState(null);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [selectedTimeSlot, setSelectedTimeSlot] = useRecoilState(selectedRentalTimeSlot);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedCourtType, setSelectedCourtType] = useState('Beach Tennis');

  const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  const agreementsList = [
    { text: t('rental_agreement_option_1'), index: '1' },
    { text: t('rental_agreement_option_2'), index: '2' },
    { text: t('rental_agreement_option_3'), index: '3' }
  ];

  function getLocations() {
    setLoading(true);
    bookingApi
      .get('events/locations?eventType=0')
        .then((response) => {
          setLoading(false);
          setLocations(_.sortBy(response.data.data, 'name'));
          if (response.data.data.length > 0) {
            setSelectecLocation(_.sortBy(response.data.data, 'name')[0]);
          } else {
            setSelectecLocation(_.sortBy(response.data.data, 'name')[0]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
  }

  function decreaseDay() {
    if (selectedDate > 0) {
      setSelectedDate(selectedDate - 1);
    }
  }

  function fetchPayments() {
    setLoading(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setPayment({ ...payment, cards: response.data.data.results });
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function incrementDay() {
    if (selectedDate < timeSlotDays?.length - 1) {
      setSelectedDate(selectedDate + 1);
    }
  }

  function searchTimeSlots() {
    if (!selectedLocation || !endDate) {
      return;
    }
    setTimeSlotDays([]);
    setLoading(true);
    bookingApi
      .post('events/search/timeslots', {
        searchTerm: null,
        type: 0,
        hostId: null,
        locationId: `${selectedLocation?.id}`,
        endDate: endDate?.date
      })
        .then((response) => {
          setLoading(false);
          const tempDays = _.sortBy(response.data.data, 'startDate');
          setTimeSlotDays(tempDays);
          console.log('td', response.data.data);
          if (selectedDate === null) {
            setSelectedDate(0); 
          }
          if (selectedTimeSlot !== null) {
            const filtered = _.filter(tempDays[selectedDate].timeSlots,
              (slot) => slot.id === selectedTimeSlot.id);
            setSelectedTimeSlot(filtered[0]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
  }

  function createReservation(card, payPalInvoice, payPalReference, athMovilReference) {
    setLoading(true);
    bookingApi.post('events/booking', {
      EventTimeSlotId: selectedTimeSlot?.id,
      UserCardId: card?.id,
      PaypalInvoiceNumber: payPalInvoice,
      PaypalReferenceNumber: payPalReference,
      AthMovilReferenceNumber: athMovilReference,
      Amount: selectedTimeSlot?.event?.price,
      BypassPayment: false,
      Comments: selectedCourtType
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          closePaymentsModal();
          searchTimeSlots();
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  const close = () => {
    if (showPaymentsModal) {
      closePaymentsModal();
    } else {
      setSelectedTimeSlot(null);
    }
  };

  useClickAway(ref, () => {
    close()
  });

  useClickAway(paymentsRef, () => {
    closePaymentsModal()
  });

  useEffect(() => {
    if (selectedLocation) {
      searchTimeSlots();
    }
  }, [selectedLocation, endDate]);

  useEffect(() => {
    setEndDate(endDateOptions[0]);
    getLocations();
  }, []);

  useEffect(() => {
    setSelectedCourtType('Beach Tennis')
  }, [selectedTimeSlot]);

  useEffect(() => {
    if (showPaymentsModal) {
      fetchPayments();
    }
  }, [showPaymentsModal]);

  return (
    <AnimatePresence>
      <div className="my-2 mx-4 overflow-y-hidden h-full">
        <div className="flex">
          <div className="flex flex-col">
          <div className="font-bold mt-1 md:text-lg text-md ml-2">{t('rental_availability')}</div>
          <div className="text-gray-600 tracking-tight mt-1 md:text-md text-sm ml-4">{t('choose_court')}</div>
          </div>
          <div className="ml-auto">  
            <button
              key="reload"
              type="button"
              hidden={loading || !selectedLocation}
              onClick={() => {
                searchTimeSlots();
              }}
              className="md:text-md text-sm bg-green-500 hover:bg-green-500 text-white font-semibold py-1 px-4 border border-green-400 rounded shadow md:mx-2"
            >
              <span>{t('refresh')}{loading ? <Spinner spinning={loading} className="ml-2 inline" /> : <></>}</span>
            </button>
          </div>
        </div>
        <div className="flex space-x-4 mt-2">
          <div className="hidden">
            <div className="relative z-20 mt-2 md:block hidden">
              <Menu as="div" className="relative flex justify-end items-center">
                {({ open }) => (
                  <>
                    <Menu.Button className="w-full flex items-center justify-between rounded-md shadow-sm px-4 py-3 space-x-4 bg-white text-sm font-medium hover:bg-gray-50 outline-none focus:outline-none">
                      <span>{endDate?.title}</span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={open ? 'transform rotate-180 transition duration-500 easy-in-out' : 'transition duration-500 easy-in-out'}
                      />
                    </Menu.Button>
                    <Transition
                      show={open}
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="absolute right-0 top-10 w-full mt-2 origin-bottom bg-white divide-y divide-gray-100 rounded-xl shadow outline-none max-h-60 overflow-y-auto no-scrollbar"
                      >
                        {_.map(endDateOptions, (e, index) => (
                          <Menu.Item
                            key={`${e.title} ${index}`}
                            as="button"
                            type="button"
                            className={`${e.title === endDate?.title && 'text-accent'} disabled:cursor-not-allowed text-sm font-light tracking-tight text-left flex items-center space-x-2 w-full p-4 leading-none hover:text-accent transition duration-500 easy-in-out truncate line-clamp-1`}
                            onClick={() => setEndDate(e)}
                          >
                            <span>{e.title}</span>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
            {_.map(locations, (l, index) => (  
            <button
              key={`${l.id} ${index}`}
              type="button"
              onClick={() => {
                setSelectecLocation(l)
              }}
              className={`${selectedLocation?.id === l.id ? 'text-white bg-purple-400 text-md font-medium ring ring-offset-4 ring-purple-600' : 'text-gray-500 text-sm'}bg-white border p-2 border-gray-400 rounded shadow`}
            >
              <span>{l.name}</span>
            </button>
          ))}
        </div>
        {is.not.empty(timeSlotDays) && (
          <div className="flex flex-col items-center w-full md:hidden">
            <div className="flex items-center flex-row mt-4 text-md">
              <button
                type="button"             
                onClick={() => {
                  decreaseDay();
                }}
                disabled={selectedDate === 0}
                className="flex items-center bg-blue-400 disabled:bg-blue-200 disabled:cursor-not-allowed text-white justify-center h-8 w-8 shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none"
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <div className="mx-4">{`${dayjs(timeSlotDays[selectedDate]?.startDate).format('dddd MMMM D, YYYY')}`}</div>
              <button
                type="button"
                onClick={() => {
                  incrementDay();
                }}
                disabled={selectedDate === timeSlotDays?.length - 1}
                className="flex items-center bg-blue-400 disabled:bg-blue-200 disabled:cursor-not-allowed text-white justify-center h-8 w-8 shadow rounded-full ring-0 focus:ring-0 outline-none focus:outline-none"
              >
                  <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}
        <motion.div
          initial={fadeOutMotion}
          animate={fadeInMotion}
          exit={fadeOutMotion}
          className="hidden md:grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 mt-4 h-full"
        >
          {is.not.empty(timeSlotDays) && _.map(_.sortBy(timeSlotDays, 'startDate'), (e, index) => (
            <RentalSlotDay key={`${index} ${e.startDate}`} day={e} />
          ))}
          {loading && _.times(5, (e) => <Placeholder className="m-2" key={e} />)}
        </motion.div>
        <motion.div
          initial={fadeOutMotion}
          animate={fadeInMotion}
          exit={fadeOutMotion}
          className="md:hidden mt-4 h-full"
        >
          {is.not.empty(timeSlotDays) && (
            <RentalSlotDay key="mobile" day={timeSlotDays[selectedDate]} />
          )}
        </motion.div>
      </div>
      {selectedTimeSlot && !showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          key="sidebar"
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
        >
          <motion.div
            ref={ref}
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute right-0 h-full flex flex-col w-full md:w-1/3 bg-white"
          >
            <div className="px-4 py-6 flex items-center justify-between bg-gray-100">
              {t('time_slot_details')}
              <button
                type="button"
                onClick={() => setSelectedTimeSlot(null)}
                className="hover:opacity-75 outline-none focus:outline-none"
              >
                <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
              </button>
            </div>
            <div className="flex flex-col flex-1 overflow-auto p-4">
              {selectedTimeSlot?.event?.imageUrl && (
                // Shows image if one is set to the event or location
                <img alt={selectedTimeSlot?.event?.name} src={selectedTimeSlot?.event?.imageUrl} className="h-52 w-auto object-cover rounded-lg" />
              )}
              <div className="flex">
                  <div className="my-2 text-md font-bold">{t('event')}: {selectedTimeSlot?.event?.name}</div>
                  <div className="my-2 ml-auto text-md font-bold">${selectedTimeSlot?.event?.price.toFixed(2)}</div>
              </div>
              <div>{selectedTimeSlot?.event?.description}</div>   
              <div className="text-sm mt-1">{t('date')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('dddd MMMM D, YYYY')}`}</div>
              <div className="text-sm mt-1">{t('slot')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`}</div>        
              <div className="text-sm mt-1">{t('status')}: {`${selectedTimeSlot?.isFull ? t('booked') : t('available')}`}</div>
              <div className={selectedTimeSlot?.comment ? '' : 'hidden'}>{t('comments')}: {selectedTimeSlot?.comment}</div>
              {!showPaymentButton && (
                <>
                  <div className="my-2 text-md font-medium">{`${!selectedTimeSlot?.isFull ? t('contact_us_for_booking') : ''}`}</div>
                  <a href="mailto:bookings@mecacomplex.com" className={`${selectedTimeSlot?.isFull ? 'hidden' : 'font-medium text-blue-600 dark:text-blue-500 hover:underline'}`}>bookings@mecacomplex.com</a>
                </>
              )}
              {!user && (
                <Button
                  text={t('login_to_reserve')}
                  textColor="text-white"
                  onClick={() => {
                  history.push('/login', { from: 'bookings/rentals' });
                }}
                  loaderColor="bg-white"
                  className="bg-accent text-lg hover:bg-accent mt-4"
                />
              )}
              {!selectedTimeSlot?.isFull && user && (
              <div>
                <div className="flex flex-col items-start mt-8 bg-green-100 rounded-xl p-4 border-t border-gray-100">
                  <label className="text-sm font-semibold">{t('rental_terms')}</label>
                  <button 
                    hidden
                    type="button"
                    onClick={handleToggle}
                  >
                    {isExpanded ? 'show less' : 'show less'} List
                  </button>
                {isExpanded && (
                  <ul className="text-sm">
                    {_.map(agreementsList, (a, index) => (
                      <li key={index}>-{a.text}</li>
                    ))}
                  </ul>
                )}
                <div className="text-sm mt-4">
                  <span>{t('if_cancel_booking_contact')}
                  </span>
                  <a href="mailto:bookings@mecacomplex.com" className=" ml-1 font-medium text-blue-600 dark:text-blue-500 hover:underline">bookings@mecacomplex.com</a>
                </div>
                </div>      
              <Select
                className="mt-4"
                label={t('select_net_height')}
                id="courttype"
                name="courttype"
                options={[
                  {
                    key: 'Beach Tennis',
                    value: 'Beach Tennis',
                    text: 'Beach Tennis',
                  },
                  {
                    key: 'Volleyball',
                    value: 'Volleyball',
                    text: 'Volleyball',
                  },
                ]}
              />    
              <div className="flex items-center mx-1 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement" className="ml-2 text-sm">
                  {t('booking_i_agree_terms')}
                </label>
              </div>
              </div>)}
              {showPaymentButton && !selectedTimeSlot?.isFull && user && (
                <Button
                  text={t('complete_payment')}
                  textColor="text-white"
                  disabled={loading || !isChecked}
                  loading={loading}
                  onClick={() => {
                    setShowPaymentsModal(true);
                  }}
                  loaderColor="bg-white"
                  className="bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-lg"
                />
              )}
              {showPaymentButton && selectedTimeSlot?.isFull &&
                !selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('timeslot_is_full')}
                  textColor="text-white"
                  disabled
                  loaderColor="bg-white"
                  className="bg-red-400 hover:ring-2 ring-offset-2 ring-red-500 text-lg mt-4"
                />
              )}
              {showPaymentButton && selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('already_reserved')}
                  textColor="text-white"
                  disabled
                  loaderColor="bg-white"
                  className="bg-red-400 hover:ring-2 ring-offset-2 ring-green-500 text-lg"
                />
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
      {selectedTimeSlot && showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="payments"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={paymentsRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  {t('amount_to_pay')}: ${(selectedTimeSlot?.event?.price).toFixed(2)}
                </div>
                <button
                  type="button"
                  onClick={() => closePaymentsModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {loading && <Spinner spinning={loading} className="mx-auto mt-2" />}
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  <div>{t('payment_method_description')}</div>
                  <Spinner
                    spinning={loading}
                    className="mx-auto my-4"
                  />
                  {envConfig.AllowCreditCardCreate === 'true' && envConfig.AllowCreditCardsInBooking === 'true' && (
                    <button
                      type="button"
                      onClick={() => {
                        setPayment({ ...payment, modal: true });
                      }}
                      className="text-left w-full mt-4 transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
                    >
                      <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
                        <FontAwesomeIcon icon={faCreditCardFront} />
                      </div>
                      <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                        <div className="font-semibold">{t('add_new_payment')}</div>
                        <div>{t('new_card')}</div>
                      </div>
                    </button>
                  )}
                  {envConfig.PayPalForBookings === 'true' && !loading && (
                    <div className="mt-6">
                      <PayPalScriptProvider options={{ 'client-id': envConfig.PayPalKey }}>
                        <PayPalButtons
                          createOrder={(data, actions) => actions.order.create({
                            purchase_units: [
                                {
                                  description: `${selectedTimeSlot?.event?.name} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('MMM D YYYY')} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`,
                                    amount: {
                                        value: `${(selectedTimeSlot?.event?.price).toFixed(2)}`,
                                    },
                                    custom_id: `${user?.id} ${selectedTimeSlot?.id}`
                                },
                            ],
                            application_context: { brand_name: 'MECA Complex', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                          })}
                          onApprove={(data, actions) => actions.order.capture().then((details) => {
                            const refNumber = details.id;
                            const invoiceNumber =
                              details.purchase_units[0]?.payments?.captures[0]?.id;
                            createReservation(null, invoiceNumber, refNumber, null);
                          })}
                        />
                      </PayPalScriptProvider>
                    </div>
                  )}
                  {envConfig.AthMovilForBookings === 'true' && !loading && (
                    <></>
                  )}
                  {envConfig.AllowCreditCardsInBooking === 'true' && _.map(payment.cards, (card, index) => (
                    <button
                      type="button"
                      onClick={() => createReservation(card, null, null, null)}
                      key={`${card.id} ${index}`}
                      className="w-full"
                    >
                      <div
                        className="divide-x mt-4 divide-purple-200 flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none"
                      >
                        <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                        <div className="flex flex-col flex-1 items-start justify-start text-xs p-2">
                          <div className="font-semibold">{card.brand}</div>
                          <div>**** {card.last4}</div>
                          <div>{card.expirationDate}</div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Rental;

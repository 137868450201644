import React, { useEffect, useState, useRef } from 'react';
import { useClickAway, useMount } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import is from 'is_js';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemsCarousel from 'react-items-carousel';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  faChevronDown,
  faTimesCircle,
  faCreditCardFront,
  faCreditCard,
  faCalendar,
  faMapPin,
} from '@fortawesome/pro-duotone-svg-icons';  
import Placeholder from './shared-components/Placeholder';
import bookingApi from '../../../api/bookingApi';
import api from '../../../api/api';
import {
  selectedRentalTimeSlot,
  userPaymentsAtom,
  notificationsAtom,
  userAtom
} from '../../../atoms/Atoms';
import {
  fadeOutMotion,
  fadeInMotion,
  toLocalTime
} from '../../../functions';
import { Spinner } from '../../shared-components/Spinner';
import Button from '../../shared-components/buttons/Button';
import {
  backdropVariants,
  sidebarVariants,
  modalVariants
} from '../../../utils';
import TimeSlotDay from './shared-components/TimeSlotDayLanding';
import envConfig from '../../../envConfig';
import useBreakpoint from '../../../hooks/useBreakpoint';
import Logo from '../../../assets/img/logo/main_logo_square.png';

function EventsLandingByTimeSlot() {
  const history = useHistory();
  const showPaymentButton = envConfig.ShowPaymentButtonOnBookingReservation;
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const paymentsRef = useRef();
  const endDateOptions = [
    { title: `4 ${t('days')}`, date: dayjs().utc().add(4, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: t('today'), date: dayjs().utc().add(1, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `3 ${t('days')}`, date: dayjs().utc().add(3, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `5 ${t('days')}`, date: dayjs().utc().add(5, 'day').format('YYYY-MM-DDTHH:00:00.00') },
    { title: `14 ${t('days')}`, date: dayjs().utc().add(14, 'day').format('YYYY-MM-DDTHH:00:00.00') },
  ]
  // const startDate = dayjs().utc().format('YYYY-MM-DDTHH:00:00.00');
  const user = useRecoilValue(userAtom);
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [selectedTimeSlot, setSelectedTimeSlot] = useRecoilState(selectedRentalTimeSlot);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlotDays, setTimeSlotDays] = useState([]);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const breakpoint = useBreakpoint();
  const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };
  const [eventDetails, setEventDetails] = useState(null);

  const { eventId, startTime } = useParams();

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  const agreementsList = [
    { text: t('event_agreement_option_1'), index: '1' },
    { text: t('event_agreement_option_2'), index: '2' },
    { text: t('event_agreement_option_3'), index: '3' }
  ];

  function decreaseDay() {
    if (selectedDate > 0) {
      setSelectedDate(selectedDate - 1);
    }
  }

  function getSpecificTimeSlot(event) {
    const filtered = _.filter(event.timeSlots, (e) => e.startTime === startTime);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return '';
  }

  useEffect(() => {
    setLoading(true);
    if (eventId) {
      bookingApi.get(`events/info/${eventId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('event_not_found')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          history.push('/events');
        } else {
          setEventDetails(getSpecificTimeSlot(response.data.data))
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [eventId]);

  useMount(() => {
    setLoading(true);
    if (eventId) {
      bookingApi.get(`events/info/${eventId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('event_not_found')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          history.push('/events');
        } else {
          setEventDetails(getSpecificTimeSlot(response.data.data))
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  });


  function fetchPayments() {
    setLoading(true);
    api
      .get('users/me/cards')
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setPayment({ ...payment, cards: response.data.data.results });
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function incrementDay() {
    if (selectedDate < timeSlotDays?.length - 1) {
      setSelectedDate(selectedDate + 1);
    }
  }

  function searchTimeSlots() {
    if (!endDate) {
      return;
    }
    setTimeSlotDays([]);
    setLoading(true);
    bookingApi.get(`events/info/${eventId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('event_not_found')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          history.push('/events');
        } else {
          setEventDetails(getSpecificTimeSlot(response.data.data))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function createReservation(card, payPalInvoice, payPalReference, athMovilReference) {
    setLoading(true);
    bookingApi.post('events/booking', {
      EventTimeSlotId: selectedTimeSlot?.id,
      UserCardId: card?.id,
      PaypalInvoiceNumber: payPalInvoice,
      PaypalReferenceNumber: payPalReference,
      AthMovilReferenceNumber: athMovilReference,
      Amount: selectedTimeSlot?.event?.price,
      BypassPayment: false
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          closePaymentsModal();
          searchTimeSlots();
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  const close = () => {
    if (showPaymentsModal) {
      closePaymentsModal();
    } else {
      setSelectedTimeSlot(null);
    }
  };

  useClickAway(ref, () => {
    close()
  });

  useClickAway(paymentsRef, () => {
    closePaymentsModal()
  });

  useEffect(() => {
    if (endDate) {
      searchTimeSlots();
    }
  }, [endDate]);

  useEffect(() => {
    setEndDate(endDateOptions[4]);
  }, []);

  useEffect(() => {
    if (showPaymentsModal) {
      fetchPayments();
    }
  }, [showPaymentsModal]);

  useEffect(() => {
    if (eventDetails) {
      const tempDays = _.sortBy(selectedEvent?.timeSlots, 'startDay');
      setTimeSlotDays(tempDays);
      if (selectedDate === null) {
        setSelectedDate(0); 
      }
      if (selectedTimeSlot !== null) {
        const filtered = _.filter(tempDays[selectedDate === null ? 0 : selectedDate].timeSlots,
          (slot) => slot.id === selectedTimeSlot.id);
        setSelectedTimeSlot(filtered[0]);
      }
    }
  }, [selectedEvent]);

  return (
    <AnimatePresence>
      <div className="bg-white overflow-auto">
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-20 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:max-w-lg lg:self-end">
            <div className="mt-2 md:mt-4">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 md:text-4xl">{eventDetails?.event.name}</h1>
            </div>
            <section aria-labelledby="information-heading" className="mt-4">
              <h2 id="information-heading" className="sr-only">
                Event Details
              </h2>
              <div className="flex items-center">
                <p className="text-lg text-sky-900 sm:text-xl">${eventDetails?.event.price?.toFixed(2)}</p>
              </div>
              <div className="mt-2 space-y-4">
                <p className="text-base text-sky-500">{eventDetails?.event.description}</p>
              </div>
              <div className="mt-2 space-y-4">
                <p className="text-base text-sky-600"><FontAwesomeIcon icon={faMapPin} className="mr-2" />{eventDetails?.event.location?.name}</p>
              </div>
              <br />
              <br />
              <div>             
                <div>
                  <button
                    key={`button-${eventDetails?.event.id}`}
                    type="button"
                    onClick={() => {
                      setSelectedEvent(eventDetails)
                    }}
                    href={`tel:${eventDetails?.event.name}`}
                    className="items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-2 font-semibold text-gray-900"
                  >
                    <FontAwesomeIcon icon={faCalendar} className="h-5 w-5 text-base" aria-hidden="true" />
                    {' '}{t('dates_and_availability')}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    key={eventDetails?.id}
                    disabled={eventDetails?.isFull}
                    onClick={() => setSelectedTimeSlot(eventDetails)}
                    className={`${'text-accent'} w-full truncate line-clamp-1 disabled:cursor-not-allowed flex text-left text-xs font-medium line-clamp-1 hover:text-accent outline-none focus:outline-none transition duration-500 ease-in-out`}
                  >
                    <div className="mb-2 mx-2 bg-green-500 rounded-xl pt-4 border-t border-gray-100 text-white text-md">
                      <div className="text-md md:text-lg ml-2">{`${dayjs(eventDetails?.startTime).format('dddd, MMMM DD, YYYY')}`}</div>
                        <div className="text-md ml-2">{`${toLocalTime(eventDetails?.startTime).format('h:mm A')}-${toLocalTime(eventDetails?.endTime).format('h:mm A')}`}</div>
                          <div className="text-md ">
                            <div
                              className={`${eventDetails?.isFull ? 'text-red-600 border-xl border-green-600 border-2 rounded-b-xl bg-white' : 'bg-white border-2 border-green-600 rounded-b-xl'} p-2 rounded-b-xl text-md font-medium tracking-tight text-left flex items-center space-x-2 w-full mt-2 leading-none hover:text-accent transition duration-500 easy-in-out truncate line-clamp-1`}
                            >
                              <div className="flex flex-col text-center text-md text-red-500 mt-2">
                                <span>{`${eventDetails?.isFull ? t('full_event') : ''}`}</span>
                                <span className={`${eventId?.isFull ? t('hidden') : 'text-gray-600'}`}>{t('reserve_a_spot')}</span>
                              </div>
                            </div>
                          </div>
                    </div>
                  </button>
                </div>
              </div>             
            </section>
          </div>
          {/* Product image */}
          <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg">
              <img src={eventDetails?.event.imageUrl ? eventDetails?.event.imageUrl : Logo} alt="Event" className="h-full w-full object-cover object-center" />
            </div>
          </div>
        </div>
      </div>
      {selectedTimeSlot && !showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="sidebar"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-20"
        >
          <motion.div
            ref={ref}
            variants={sidebarVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute right-0 h-full flex flex-col w-full md:w-1/3 bg-white"
          >
            <div className="px-4 py-6 flex items-center justify-between bg-gray-100">
              {t('details')}
              <button
                type="button"
                onClick={() => {
                  setSelectedTimeSlot(null);
                  setIsChecked(false);
                }}
                className="hover:opacity-75 outline-none focus:outline-none"
              >
                <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
              </button>
            </div>
            <div className="flex flex-col flex-1 overflow-auto p-4">
              {selectedTimeSlot?.event?.imageUrl && (
                // Shows image if one is set to the event or location
                <img alt={selectedTimeSlot?.event?.name} src={selectedTimeSlot?.event?.imageUrl} className="h-52 w-auto object-cover rounded-lg" />
              )}
              <div className="flex">
                  <div className="my-2 text-md font-bold">{selectedTimeSlot?.event?.name}</div>
                  <div className="my-2 ml-auto text-md font-bold">${selectedTimeSlot?.event?.price.toFixed(2)}</div>
              </div>
              <div className="my-2 text-xs md:text-sm tracking-tight text-justify">{selectedTimeSlot?.event?.description}</div>   
              <div className="text-sm mt-1">{t('date')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('dddd MMMM D, YYYY')}`}</div>
              <div className="text-sm mt-1">{t('slot')}: {`${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`}</div>        
              <div className="text-sm mt-1">{t('location')}: {`${eventDetails?.event.location.name}`}</div>
              <div className={selectedTimeSlot?.comment ? '' : 'hidden'}>{t('comments')}: {selectedTimeSlot?.comment}</div>
              {!showPaymentButton && (
                <>
                  <div className="my-2 text-md font-medium">{`${!selectedTimeSlot?.isFull ? t('contact_us_for_booking') : ''}`}</div>
                  <a href="mailto:bookings@mecacomplex.com" className={`${selectedTimeSlot?.isFull ? 'hidden' : 'font-medium text-blue-600 dark:text-blue-500 hover:underline'}`}>bookings@mecacomplex.com</a>
                </>
              )}
              {!user && (
                <Button
                  text={t('login_to_dropin')}
                  textColor="text-white"
                  onClick={() => {
                  history.push('/login', { from: 'classes/dropin' });
                }}
                  loaderColor="bg-white"
                  className="bg-accent text-lg hover:bg-accent mt-4"
                />
              )}
              {!selectedTimeSlot?.isFull && !selectedTimeSlot?.reservedByUser && user && (
              <div>
                <div className="flex flex-col items-start mt-8 bg-red-100 rounded-xl p-4 border-t border-gray-100">
                  <label className="text-sm font-semibold">{t('event_terms')}</label>
                  <button 
                    hidden
                    type="button"
                    onClick={handleToggle}
                  >
                    {isExpanded ? 'show less' : 'show less'} List
                  </button>
                {isExpanded && (
                  <ul className="text-sm">
                    {_.map(agreementsList, (item, i) => (
                      <li key={`${item.index} ${i}`}>-{item.text}</li>
                    ))}
                  </ul>
                )}
                <div className="text-sm mt-4">
                  <span>{t('if_cancel_booking_contact')}
                  </span>
                  <a href="mailto:bookings@mecacomplex.com" className=" ml-1 font-medium text-blue-600 dark:text-blue-500 hover:underline">bookings@mecacomplex.com</a>
                </div>
                </div>          
              <div className="flex items-center mx-1 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement" className="ml-2 text-sm">
                  {t('booking_i_agree_erms')}
                </label>
              </div>
              </div>)}
              {selectedTimeSlot?.reservedByUser && user && (
                <div className="my-4 text-center text-white bg-purple-600 rounded-xl p-2 text-lg">
                {t('already_reserved')}
                </div>
              )}
              {showPaymentButton && !selectedTimeSlot?.isFull 
              && !selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('complete_payment')}
                  textColor="text-white"
                  disabled={loading || !isChecked}
                  loading={loading}
                  onClick={() => {
                    setShowPaymentsModal(true);
                    setIsChecked(false);
                  }}
                  loaderColor="bg-white"
                  className="bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-lg"
                />
              )}
              {showPaymentButton && (selectedTimeSlot?.isFull ||
               (selectedTimeSlot.maxCapacity === selectedTimeSlot.currentCapacity)) &&
                !selectedTimeSlot?.reservedByUser && user && (
                <Button
                  text={t('class_is_full')}
                  textColor="text-white"
                  disabled
                  loaderColor="bg-white"
                  className="bg-red-400 hover:ring-2 ring-offset-2 ring-red-500 text-lg mt-4"
                />
              )}  
              {selectedTimeSlot?.reservedByUser && user && (
                <div className="my-4 text-center text-purple-600 p-2 text-lg">
                {t('already_have_reservation')}
                </div>
              )}            
            </div>
          </motion.div>
        </motion.div>
      )}
      {selectedTimeSlot && showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="payments"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={paymentsRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  {t('amount_to_pay')}: ${(selectedTimeSlot?.event?.price).toFixed(2)}
                </div>
                <button
                  type="button"
                  onClick={() => closePaymentsModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {loading && <Spinner className="mx-auto mt-2" />}
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  <div>{t('payment_method_description')}</div>
                  <Spinner
                    spinning={loading}
                    className="mx-auto my-4"
                  />
                  {envConfig.AllowCreditCardCreate === 'true' && envConfig.AllowCreditCardsInBooking === 'true' && (
                    <button
                      type="button"
                      onClick={() => {
                        setPayment({ ...payment, modal: true });
                      }}
                      className="text-left w-full mt-4 transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
                    >
                      <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
                        <FontAwesomeIcon icon={faCreditCardFront} />
                      </div>
                      <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                        <div className="font-semibold">{t('add_new_payment')}</div>
                        <div>{t('new_card')}</div>
                      </div>
                    </button>
                  )}
                  {envConfig.PayPalForBookings === 'true' && !loading && (
                    <div className="mt-6">
                      <PayPalScriptProvider options={{ 'client-id': envConfig.PayPalKey }}>
                        <PayPalButtons
                          createOrder={(data, actions) => actions.order.create({
                            purchase_units: [
                                {
                                    description: `${selectedTimeSlot?.event?.name} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('MMM D YYYY')} ${dayjs(selectedTimeSlot?.startTime).add(-4, 'hour').format('h:mm a')}-${dayjs(selectedTimeSlot?.endTime).add(-4, 'hour').format('h:mm a')}`,
                                    amount: {
                                        value: `${(selectedTimeSlot?.event?.price).toFixed(2)}`,
                                    },
                                    custom_id: `${user?.id} ${selectedTimeSlot?.id}`
                                },
                            ],
                            application_context: { brand_name: 'MECA Complex', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                          })}
                          onApprove={(data, actions) => actions.order.capture().then((details) => {
                            const refNumber = details.id;
                            const invoiceNumber =
                              details.purchase_units[0]?.payments?.captures[0]?.id;
                            createReservation(null, invoiceNumber, refNumber, null);
                          })}
                        />
                      </PayPalScriptProvider>
                    </div>
                  )}
                  {envConfig.AthMovilForBookings === 'true' && !loading && (
                    <></>
                  )}
                  {envConfig.AllowCreditCardsInBooking === 'true' && _.map(payment.cards, (card) => (
                    <button
                      type="button"
                      onClick={() => createReservation(card, null, null, null)}
                      key={card.id}
                      className="w-full"
                    >
                      <div
                        className="divide-x mt-4 divide-purple-200 flex shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none"
                      >
                        <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                        <div className="flex flex-col flex-1 items-start justify-start text-xs p-2">
                          <div className="font-semibold">{card.brand}</div>
                          <div>**** {card.last4}</div>
                          <div>{card.expirationDate}</div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default EventsLandingByTimeSlot;

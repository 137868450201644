import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';
import { useMount, useUnmount } from 'react-use';
import VisibilitySensor from 'react-visibility-sensor';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faBreadLoaf,
  // faCarrot,
  // faCertificate,
  // faCow,
  // faHomeHeart,
  // faMeat,
  // faPaw,
  // faPumpkin,
  // faUtensils,
  // faWineBottle,
  faArrowToRight,
  faArrowToLeft,
} from '@fortawesome/pro-duotone-svg-icons';
import Card from '../../../../shared-components/card/Card';
import { departmentPageAtom, catalogAtom } from '../../../../../atoms/Atoms';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import Placeholder from './Placeholder';
import { H1 } from '../../../../shared-components/typography/Title';
import api from '../../../../../api/api';
import envConfig from '../../../../../envConfig';

function Carousel({ department, dynamicDepartment }) {
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasLoaded, sethasLoaded] = useState(false);
  const catalog = useRecoilValue(catalogAtom);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [departmentState, setDepartmentState] = useRecoilState(
    departmentPageAtom
  );
  const [title, setTitle] = useState('');
  const [storeUrl, setStoreUrl] = useState('');

  function itemsToShow(bp) {
    // if (bp === 7) {
    //   return 6; 
    // }
    // if (bp === 4) {
    //   return 3; 
    // }
    // if (bp === 2) {
    //   return 1; 
    // }
    // if (bp === 2) {
    //   return 1; 
    // }
    return bp - 1;
  } 

  // console.log('bp', breakpoint);

  function dynamicDepartmentInfo(dept) {
    let name = '';
    let type = 0;
    if (dept === 'sponsored') {
      name = t('sponsored');
      type = 6;
    } else if (dept === 'myPantry') {
      name = t('buy_again');
      type = 7;
    } else if (dept === 'recentlyAdded') {
      name = t('recently_added');
      type = 9;
    } else if (dept === 'madeLocally') {
      name = t('made_locally');
      type = 10;
    } else if (dept === 'offers') {
      name = t('offers');
      type = 5;
    } else if (dept === 'wishlist') {
      name = t('wishlist');
      type = 4;
    } else if (dept === 'favorites') {
      name = t('favorites');
      type = 3;
    } else if (dept === 'customDepartment01') {
      name = catalog?.customDepartment01Name;
      type = 12;
    } else if (dept === 'customDepartment02') {
      name = catalog?.customDepartment02Name;
      type = 13;
    } else if (dept === 'customDepartment03') {
      name = catalog?.customDepartment03Name;
      type = 14;
    } else if (dept === 'customDepartment04') {
      name = catalog?.customDepartment04Name;
      type = 30;
    } else if (dept === 'customDepartment05') {
      name = catalog?.customDepartment05Name;
      type = 31;
    } else if (dept === 'customDepartment06') {
      name = catalog?.customDepartment06Name;
      type = 32;
    } else if (!dept) {
      return {
        title: i18n.language === 'es' ? department?.nameSpa : department?.nameEng,
        apiUrl: `catalog/${id}/department/${department?.id}/top/${envConfig.DepartmentCarouselSize}`,
        shopperUrl: `/stores/${id}/shopper/${type}`,
        storeUrl: `/stores/${id}/departments/${department?.id}`,
        searchType: type
      };
    }
    return {
      title: name,
      apiUrl: `catalog/${id}/dynamicdepartment/${type}/top/${envConfig.DepartmentCarouselSize}`,
      shopperUrl: `/stores/${id}/shopper/${type}`,
      storeUrl: `/stores/${id}/customdepartments/${type}`,
      searchType: type
    };
  }

  let apiUrl = '';
  if (department) {
    apiUrl = `catalog/${id}/department/${department?.id}/top/${envConfig.DepartmentCarouselSize}`;
  }
  if (envConfig.ShowShopper && dynamicDepartment && dynamicDepartment === 'customDepartment01') {
    apiUrl = dynamicDepartmentInfo(dynamicDepartment).apiUrl;
  } else {
    apiUrl = dynamicDepartmentInfo(dynamicDepartment).apiUrl;
  }

  const load = function (isVisible) {
    if (!hasLoaded && isVisible) {
      sethasLoaded(true);
      if (department) {
        api({
          method: 'GET',
          url: `${apiUrl}/${page}`,
        })
          .then((response) => {
            if (response.data) {
              const filter = _.filter(data, (e) => e !== 'placeholder');
              setData([...filter, ...response.data.data.results]);
              const tempHasMore = filter.length + response.data.data.results.length <
                parseInt(response.data.data.message, 10);
              setHasMore(tempHasMore)
              if (hasMore) {
                setPage(page + 1);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        api.get(`${apiUrl}/0`).then((response) => {
          if (response.data) {
            const filter = _.filter(data, (e) => e !== 'placeholder');
            setData([...filter, ...response.data.data.results]);
            const tempHasMore = filter.length + response.data.data.results.length <
              parseInt(response.data.data.message, 10);
            setHasMore(tempHasMore)
            if (hasMore) {
              setPage(page + 1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
  };

  useEffect(() => {
    if (department) {
      setTitle(i18n.language === 'es' ? department.nameSpa : department.nameEng);
      setStoreUrl(`/stores/${id}/departments/${department.id}`);
    } else {
      const result = dynamicDepartmentInfo(dynamicDepartment);
      setTitle(result.title);
      if (envConfig.ShowShopper === 'true' && dynamicDepartment && dynamicDepartment === 'customDepartment01') {
        setStoreUrl(result.shopperUrl); 
        console.log(envConfig.ShowShopper);
      } else { 
        setStoreUrl(result.storeUrl);
      }
    }
  }, [department, i18n.language]);

  useMount(() => {
    const placeholders = [];
    for (let i = 0; i < breakpoint; i++) {
      placeholders.push('placeholder');
    }
    setData(placeholders);
  });

  useUnmount(() => {
    setData([]);
  });

  const fetchMore = () => {
    setLoadingMore(true);
    if (department) {
      api({
        method: 'GET',
        url: `${apiUrl}/${page}`,
      })
        .then((response) => {
          if (response.data) {
            const filter = _.filter(data, (e) => e !== 'placeholder');
            setLoadingMore(false);
            setData([...filter, ...response.data.data.results]);
            const tempHasMore = filter.length + response.data.data.results.length <
              parseInt(response.data.data.message, 10);
            setHasMore(tempHasMore)
            if (hasMore) {
              setPage(page + 1);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api.get(`${apiUrl}/0`).then((response) => {
        if (response.data) {
          const filter = _.filter(data, (e) => e !== 'placeholder');
          setLoadingMore(false);
          setData([...filter, ...response.data.data.results]);
          const tempHasMore = filter.length + response.data.data.results.length <
            parseInt(response.data.data.message, 10);
          setHasMore(tempHasMore)
          if (hasMore) {
            setPage(page + 1);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <VisibilitySensor offset={{ bottom: -600 }} onChange={(isVisible) => { load(isVisible); }}>
      <div className="space-y-4">
        <div className="mx-auto flex flex-col space-x-4 px-4">
          <div className="flex items-center mx-4 text-2xl tracking-tight font-extrabold space-x-2">
            {/* <FontAwesomeIcon
              icon={getIcon(title)}
              className="text-lg"
            /> */}
            <H1 text={title} />
          </div>
          <div className="text-xs">
            <span className="font-medium mr-1">
            {department && department.categories?.length > 1 ? t('categories_in_department') : ''}
            </span>
            {department && _.map(_.slice(department.categories, 0, 5), (e, index) => {
                if (index === 0) {
            return (<span key={e.id} className="font-medium text-gray-400">
              <button
                type="button"
                className="hover:underline transition duration-500 ease-in-out outline-none focus:outline-none"
                onClick={() => {
                history.push(`/stores/${id}/departments/${department.id}`);
                setDepartmentState({
                ...departmentState,
                category: e,
                });
                }}
              >
                  {i18n.language === 'es' ? e.nameSpa : e.nameEng}
              </button>
                    </span>) 
              }
              if (index !== 0) {
                return (<span key={e.id} className="font-medium text-gray-400">
                  {', '}
                  <button
                    type="button"
                    className="hover:underline transition duration-500 ease-in-out outline-none focus:outline-none"
                    onClick={() => {
                    history.push(`/stores/${id}/departments/${department.id}`);
                    setDepartmentState({
                    ...departmentState,
                    category: e,
                    });
                    }}
                  >
                      {i18n.language === 'es' ? e.nameSpa : e.nameEng}
                  </button>
                        </span>) 
                  }     
            })}
            <NavLink
              className="font-medium text-accent hover:underline transition duration-500 ease-in-out ml-2"
              to={storeUrl}
            >
              {department && department?.categories.length > 1 ? t('view_all') : t('view_products')}
            </NavLink>
          </div>
        </div>
        <ItemsCarousel
          // placeholderItem={<Placeholder />}
          // enablePlaceholder
          // numberOfPlaceholderItems={itemsToShow(breakpoint)}
          infiniteLoop={false}
          gutter={12}
          activePosition="right"
          chevronWidth={50}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={itemsToShow(breakpoint)}
          slidesToScroll={itemsToShow(breakpoint)}
          showSlither
          firstAndLastGutter
          activeItemIndex={activeItemIndex}
          requestToChangeActive={setActiveItemIndex}
          onActiveStateChange={(state) => {
            if (state.isLastScroll && hasMore) {
              fetchMore();
            }
          }}
          rightChevron={
            <button
              type="button"
              className="flex items-center justify-center hover:ring-1 hover:bg-accent hover:text-white transition duration-500 ease-in-out h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-1 outline-none focus:outline-none"
            >
              <FontAwesomeIcon icon={faArrowToRight} className="hover:scale-90" />
            </button>
          }
          leftChevron={
            <button
              type="button"
              className="flex items-center justify-center hover:ring-1 hover:bg-accent hover:text-white transition duration-500 h-10 w-10 bg-white shadow rounded-full ring-0 focus:ring-1 outline-none focus:outline-none"
            >
              <FontAwesomeIcon icon={faArrowToLeft} className="hover:scale-90" />
            </button>
          }
          classes={{
            itemsWrapper: 'w-full py-2',
            itemWrapper: 'w-full',
          }}
        >
          {_.map(data, (e) => {
            if (e === 'placeholder') {
              return <Placeholder key={e} />;
            }
            return (
              <Card
                key={e.id}
                product={e}
                name={e.name}
                description={e.description}
                image={e.imageUrl || null}
                id={e.id}
                brand={e.brand}
                price={e.activePrice}
              />
            );
          })}
          {loadingMore && (<Placeholder />)}
        </ItemsCarousel>
      </div>
    </VisibilitySensor>
  );
}

export default Carousel;

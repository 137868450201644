import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import api from '../../../../../api/api';
import { notificationsAtom, tokenAtom } from '../../../../../atoms/Atoms';
import Form from '../../../../shared-components/form/Form';
import Input from '../../../../shared-components/form/Input';
import Button from '../../../../shared-components/buttons/Button';
import Panel from '../../../../shared-components/panel/Panel';

function Password() {
  const { i18n, t } = useTranslation();
  const token = useRecoilValue(tokenAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const formik = useFormik({
    initialValues: {
      current: '',
      password: '',
      confirm: '',
    },
    validationSchema: Yup.object().shape({
      current: Yup.string().required('Este campo es requerido.'),
      password: Yup.string().required('Este campo es requerido.'),
      confirm: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Las contraseñas deben ser la misma.'
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      api
        .post(
          'oauth/local/password/update',
          {
            CurrentPassword: formik.values.current,
            NewPassword: formik.values.password,
          }
        )
        .then((response) => {
          if (response.data.error) {
            setNotifications([
              ...notifications,
              {
                title: `${t('oops')}`,
                description:
                `${t('password_update_error')}`,
                error: true,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: `${t('yay')}`,
                description:
                `${t('password_update_success')}`,
                error: false,
              },
            ]);
            formik.resetForm();
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: error.message,
              error: true,
            },
          ]);
          setSubmitting(false);
        });
    },
  });

  return (
    <div className="flex flex-col flex-1 px-4 overflow-auto mb-4">
      <Panel>
        <div className="font-bold tracking-tight">{t('edit_password')}</div>
        <div className="text-sm tracking-tight">
        {t('edit_password_description')}
        </div>
        <Form>
          <Input
            label={t('current_password')}
            required
            type="password"
            name="current"
            id="current"
            placeholder="******"
            autoCapitalize="false"
            value={formik.values.current}
            onChange={formik.handleChange}
            error={
              formik.errors.current &&
              formik.touched.current &&
              formik.errors.current
            }
            className="col-span-1 md:col-span-2"
          />
          <Input
            label={t('new_password')}
            required
            type="password"
            name="password"
            id="password"
            placeholder="******"
            autoCapitalize="false"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
          />
          <Input
            label={t('confirm_password')}
            required
            type="password"
            name="confirm"
            id="confirm"
            placeholder="******"
            autoCapitalize="false"
            value={formik.values.confirm}
            onChange={formik.handleChange}
            error={
              formik.errors.confirm &&
              formik.touched.confirm &&
              formik.errors.confirm
            }
          />
        </Form>
        <Button
          text={t('save')}
          textColor="text-white"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          loaderColor="bg-white"
          className="bg-accent hover:bg-accent w-20"
        />
      </Panel>
    </div>
  );
}

export default Password;

import React from 'react';
import { useTranslation } from 'react-i18next';

function Sponsors() {
    const { t, i18n } = useTranslation();

    return (
      <div className="bg-white py-12 sm:py-32">
        <div className="flex justify-center font-bold">{t('our_sponsors')}</div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="-mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-1">
            <div className="bg-gray-400/5 p-8 sm:p-10">
              <img
                className="max-h-16 w-full object-contain"
                src="https://mecacomplexstorage.blob.core.windows.net/sharedassets/LibertyMutualBW.jpg"
                alt="Liberty Mutual"
                width={158}
                height={48}
              />
            </div>
            <div className="bg-gray-400/5 p-6 sm:p-10 hidden">
              <img
                className="max-h-12 w-full object-contain"
                src="https://mecacomplexstorage.blob.core.windows.net/sharedassets/pedialyte.png"
                alt="Pedialyte Sport"
                width={158}
                height={48}
              />
            </div>
            <div className="bg-gray-400/5 p-6 sm:p-10 hidden">
              <img
                className="max-h-12 w-full object-contain"
                src="https://mecacomplexstorage.blob.core.windows.net/sharedassets/EnsureMaxProtein-Lockup-CMYK(1).jpg"
                alt="Ensure Max Protein"
                width={158}
                height={48}
              />
            </div>
            <div className="bg-gray-400/5 p-6 sm:p-10 hidden">
              <img
                className="max-h-12 w-full object-contain"
                src="https://mecacomplexstorage.blob.core.windows.net/sharedassets/Screenshot2023-05-29234700.png"
                alt="Plantas Tropicales"
                width={158}
                height={48}
              />
            </div>
            <div className="bg-gray-400/5 p-6 sm:p-10 hidden">
              <img
                className="max-h-12 w-full object-contain"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                alt="SavvyCal"
                width={158}
                height={48}
              />
            </div>
            <div className="bg-gray-400/5 p-6 sm:p-10 hidden">
              <img
                className="max-h-12 w-full object-contain"
                src="https://mecacomplexstorage.blob.core.windows.net/sharedassets/pepsilogo.png"
                alt="Statamic"
                width={158}
                height={48}
              />
            </div>            
          </div>
        </div>
      </div>
    )
  }
  export default Sponsors;


import React, { useRef, useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickAway } from 'react-use';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  orderSuccess,
  successMessageAtom,
  referenceOrderAtom,
  notificationsAtom
} from '../../../../atoms/Atoms';
import { backdropVariants, modalVariants } from '../../../../utils';
import { MenuToggle } from '../../../shared-components/MenuToggle';
import { mapOrderStatus, mapOrderType, toLocalTime } from '../../../../functions';
import api from '../../../../api/api';
import Loader from '../../../shared-components/loader/Loader';
import envConfig from '../../../../envConfig';

require('dayjs/locale/es');

dayjs.locale('es');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function Confirmation() {
  const history = useHistory();
  const ref = useRef();
  const [open, setOpen] = useRecoilState(orderSuccess);
  const [order, setOrder] = useRecoilState(successMessageAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const referenceOrder = useRecoilValue(referenceOrderAtom);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useClickAway(ref, () => {
    // setOpen(false);
  });

  useEffect(() => {
    console.log('OPEN', open);
  }, [open]);

  function updatePayPalOrder(payPalInvoice, payPalReference, sendObj, orderObj, details) {
    setLoading(true);
    api.post('paypal/connectpayment', {
      OrderId: order?.data.id,
      CaptureId: payPalInvoice,
      PaypalId: payPalReference,
      Logs: {
        SendLog: JSON.stringify(sendObj),
        OrderLog: JSON.stringify(orderObj),
        ReceiveLog: JSON.stringify(details)
      }
    })
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: '',
                description: response.data.message,
                error: false,
              },
            ]);
          }
          setOrder({ ...order, data: { ...order.data, status: 3 } });
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            ref={ref}
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="overflow-hidden rounded-t-xl md:rounded-xl w-full overflow-hidden md:w-3/5 lg:w-1/3 h-4/5 md:h-auto bg-white"
          >
            <div className="flex space-x-4 items-start justify-between p-4 bg-gray-100 rounded-t-xl">
              <div>
                <div className="font-extrabold tracking-tight text-2xl">
                  {t('congrats')}
                </div>
                <div className="tracking-tight text-gray-800 font-light text-md">
                  {order &&
                    order?.message
                      ?.replace('Su', 'Tu')
                      ?.replace('orden', 'pedido')
                      ?.replace('comunicándole', 'comunicándote')
                      ?.replace('procesada', 'procesado')}
                </div>
              </div>
              <MenuToggle
                disabled={loading}
                onClick={() => {
                  setOpen(false);
                  if (!referenceOrder) {
                    history.push('/');
                  }
                }}
              />
            </div>
            <div className="text-gray-400 text-sm p-4">
              <div className="text-gray-800 text-base mb-4 font-medium tracking-tight">
                {t('order_details')}
              </div>
              <div>
                {t('store')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.businessName}
                </span>
              </div>
              <div>
                {t('order_code')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.confirmationCode}
                </span>
              </div>
              <div>
                {t('order_client')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.pickUpName}
                </span>
              </div>
              <div>
                {t('order_service')}:{' '}
                <span className="text-accent font-medium">
                  {order && mapOrderType(order.data.type)}
                </span>
              </div>
              <div>
                {t('order_service_hour')}:{' '}
                <span className="text-accent font-medium">
                  {order &&
                    toLocalTime(order.data.endSlotTime)
                    .format('dddd, D [de] MMMM, YYYY h:mm A')}
                </span>
              </div>
              <div>
                {t('payment')}:{' '}
                <span className="text-accent font-medium">
                  {order && order.data.paymentName}
                </span>
              </div>
              <div>
                {t('status')}:{' '}
                <span className="text-accent font-medium">
                  {order && t(mapOrderStatus(order.data.status))}
                </span>
              </div>
              <div className=" text-gray-500">
                {t('note')}:{' '}
                <span className="text-gray-600 font-medium">
                  {order && order.data.specialInstruction
                    ? order.data.specialInstruction
                    : 'Ninguna'}
                </span>
              </div>
              <div className="py-6">
                <div className=" ext-gray-800">
                  {t('items')}:{' '}
                  <span className="text-accent font-medium">
                    {order && order.data.itemCount}
                  </span>
                </div>
                <div className=" ext-gray-800">
                  Subtotal:{' '}
                  <span className="text-accent font-medium">
                    ${order && order.data.productsTotal.toFixed(2)}
                  </span>
                </div>
                <div>
                  {t('municipal_tax')}:{' '}
                  <span className="text-accent font-medium">
                    ${order && order.data.municipalTax.toFixed(2)}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('state_tax')}:{' '}
                  <span className="text-accent font-medium">
                    ${order && order.data.stateTax.toFixed(2)}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('order_service_charge')}:{' '}
                  <span className="text-accent font-medium">
                    ${order && (order.data.processingFee + order.data.deliveryTotal).toFixed(2)}
                  </span>
                </div>
                <div className="text-black-400">
                  Total:{' '}
                  <span className="text-gray-800 font-bold">
                    ${order && order.data.orderTotal.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="py-4">
                {loading && (
                  <div className="my-4 ml-36">
                    <Loader color="bg-accent" />
                  </div>
                )}
                {(order?.data?.status === 3 || order?.data?.status === 0) && (
                  <Link
                    to={`/order/${order && order.data.confirmationCode}`}
                    onClick={() => setOpen(false)}
                    className="text-rose-500 hover:text-rose-400 font-medium tracking-tight transition duration-500 ease-linear"
                  >
                    {t('view_details')}
                  </Link>
                )}
                {order?.data?.status === 27 && (
                  <PayPalScriptProvider options={{ 'client-id': envConfig.PayPalKey }}>
                    <PayPalButtons
                      createOrder={(data, actions) => actions.order.create({
                        purchase_units: [
                            {
                                description: `MECA WEBSTORE: ${order?.data.confirmationCode}`,
                                amount: {
                                    value: `${(order?.data.orderTotal).toFixed(2)}`,
                                },
                                custom_id: order?.data.confirmationCode
                            },
                        ],
                        application_context: { brand_name: 'MECA Complex', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                      })}
                      onApprove={(data, actions) => actions.order.capture().then((details) => {
                        const refNumber = details.id;
                        const invoiceNumber =
                          details.purchase_units[0]?.payments?.captures[0]?.id;
                        updatePayPalOrder(invoiceNumber, refNumber, null, null, details);
                      })}
                    />
                  </PayPalScriptProvider>
                )}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
export default Confirmation;

import React from 'react';
import _ from 'lodash';

function Placeholder({ className, title, subtitle }) {
  return (
    <div className={`${className} flex flex-col text-left rounded-md bg-white shadow-sm`}>
      <div className="flex flex-col items-start border-b border-gray-100 mb-2 px-4 h-auto">
        <p className="font-semibold my-2 text-md">{title}</p>
        <p className="font-light m-1 text-xs mb-4">{subtitle}</p>
        {!title && !subtitle && <div className="h-2 w-32 bg-gray-100 rounded-full animate-pulse" />}
      </div>
      <div className="flex flex-col flex-1 mb-2 p-4 space-y-2">
        {_.times(6, (e) => (
          <div
            key={e}
            className="h-2 w-48 bg-gray-100 rounded-full animate-pulse"
          />
        ))}
      </div>
      <div className="flex items-center justify-end text-left bg-gray-50 rounded-b-md px-4 h-12 border-t border-gray-100 p-4">
        <div className="h-2 w-24 bg-gray-200 rounded-full animate-pulse" />
      </div>
    </div>
  );
}

export default Placeholder;

import React, { useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faLanguage } from '@fortawesome/pro-duotone-svg-icons';
import { ShoppingBagIcon } from '@heroicons/react/outline';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import mainLogo from '../../../../assets/img/logo/main_logo.png';
import {
  menuAtom,
  orderAtom,
  userAtom,
  paymentAtom,
  sidebarAtom,
  storeAtom,
  tokenAtom,
  serviceAtom,
} from '../../../../atoms/Atoms';
import { businessesSelector, checkoutSelector } from '../../../../atoms/Selectors';
import User from '../../user/User';
import LoyaltySummary from '../../LoyaltySummary';
import envConfig from '../../../../envConfig';



function HeaderV10(props) {
  const languages = [    
    {
      key: 'en',
      lang: 'en',
      name: 'English',
    },
    {
      key: 'es',
      lang: 'es',
      name: 'Español',
    },
  ];
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const refreshStore = useResetRecoilState(businessesSelector);
  const { id } = useParams();
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setPayment = useSetRecoilState(paymentAtom);
  const order = useRecoilValue(orderAtom);
  const setSidebar = useSetRecoilState(sidebarAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const [menu, setMenu] = useRecoilState(menuAtom);
  const { location } = props;
  const [service, setService] = useRecoilState(serviceAtom);

  useEffect(() => {
    if (store && store.id !== id) {
      if (location && location.pathname !== '/') {
        history.push('/');
      }
    }
  }, [store, order]);

return ( 
    <header>
      <nav className="flex flex-shrink-0 bg-white items-center justify-between px-4 h-20 text-sm font-semibold border-b">
        <div className="flex items-center space-x-2">
          <Link 
            to="/" 
            onClick={() => setService(-1)}
          >
            <img alt="Logo" src={mainLogo} className="w-32 md:w-48 h-auto" />
          </Link>
        </div>
        <div className="flex items-center md:divide-x">
          <button
            type="button"
            className="hidden mr-2 items-center md:space-x-2 text-lg relative p-2 hover:scale-90 transform rounded-md outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-linear"
            onClick={() => setSidebar(true)}
          >
            {/* {envConfig.ShowWebstore && service === 0 && (
              <span className="text-xs font-semibold tracking-tight md:flex">
                ${checkout.subtotal}
              </span>
            )} */}
            {/* {envConfig.ShowWebstore && service === 0 && (
              <div className="hidden relative">
                <ShoppingBagIcon 
                  className="text-accent h-6 font-light tracking-tight" 
                />           
                <AnimatePresence>
                  {order.length > 0 && (
                    <motion.div
                      key="count"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      className="absolute -bottom-2 -left-2 flex 
                      items-center justify-center text-white
                       font-semibold h-5 w-5 rounded-full bg-accent"
                    >
                      <motion.span
                        key={`count-${order.length}`}
                        initial={{ opacity: 0, y: '100%' }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                        style={{ fontSize: 9 }}
                      >
                        {order.length}
                      </motion.span>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )} */}
          </button>
          <button
            type="button"
            onClick={() => setMenu(!menu)}
            className="md:hidden hover:scale-90 transform h-10 w-10 px-2 rounded-md text-xl transition duration-200 ease-in-out ring-0 outline-none focus:ring-0 focus:outline-none"
          >
            <FontAwesomeIcon icon={faBars} className="text-white" />
          </button>
          {!user && (
            <div className="md:flex space-x-2 pl-4">
              <NavLink
                to="/login"
                activeClassName="text-accent"
                className="font-semibold tracking-tight text-xs hover:text-accent outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
              >
                {t('signin')}
              </NavLink>
              <NavLink
                to="/register"
                activeClassName="text-accent"
                className="font-semibold tracking-tight text-xs hover:text-accent outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
              >
                {t('signup')}
              </NavLink>
            </div>
          )}
          {user && (
            <Menu className="md:block">
              {({ open }) => (
                <div className="relative">
                  <Menu.Button className="text-right flex md:space-x-2 hover:scale-90 transform items-center outline-none p-2 rounded-md focus:outline-none transition duration-500 ease-linear">
                    <User />
                  </Menu.Button>
                  {open && (
                    <Menu.Items
                      static
                      className="absolute z-50 right-0 mt-2 h-auto w-56 no-scrollbar overflow-auto bg-white divide-y divide-gray-100 rounded-xl shadow-lg outline-none focus:outline-none"
                    >
                      {/* <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight
                         hover:bg-gray-100 outline-none focus:outline-none
                          transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/loyalty');
                        }}
                      >
                        <LoyaltySummary />
                      </Menu.Item> */}
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/reservations');
                        }}
                      >
                        {t('reservations')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/subscriptions');
                        }}
                      >
                        {t('my_subscriptions')}
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs 
                        tracking-tight hover:bg-gray-100 outline-none 
                        focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/orders');
                        }}
                      >
                        {t('orders')}
                      </Menu.Item>
                      {/* <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight 
                        hover:bg-gray-100 outline-none focus:outline-none transition
                         duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/payment');
                        }}
                      >
                        {t('order_payment')}
                      </Menu.Item> */}
                      {/* <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight
                        hover:bg-gray-100 outline-none focus:outline-none transition
                        duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/locations');
                        }}
                      >
                        {t('locations')}
                      </Menu.Item> */}
                      <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight hover:bg-gray-100 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/settings');
                        }}
                      >
                        {t('my_account')}
                      </Menu.Item>
                      {/* <Menu.Item
                        as="button"
                        type="button"
                        className="p-4 w-full text-left font-medium text-xs tracking-tight
                        hover:bg-gray-100 outline-none focus:outline-none transition
                        duration-500 easy-in-out"
                        onClick={() => {
                          history.push('/profile/password');
                        }}
                      >
                        {t('change_password')}
                      </Menu.Item> */}
                      <Menu.Item
                        as="button"
                        type="button"
                        className="font-semibold p-4 w-full text-left font-medium text-xs tracking-tight bg-gray-800 text-white hover:bg-gray-700 outline-none focus:outline-none transition duration-500 easy-in-out"
                        onClick={() => {
                          setUser(null);
                          setToken(null);
                          setPayment({
                            card: null,
                            cards: null,
                            loading: true,
                            error: null,
                            options: null,
                          });
                          refreshStore();
                          window.localStorage.removeItem(`${envConfig.StorageKey}-user`);
                          window.localStorage.removeItem(`${envConfig.StorageKey}-card`);
                          window.localStorage.removeItem(`${envConfig.StorageKey}-token`);
                          history.push('/login');
                        }}
                      >
                        {t('signout')}
                      </Menu.Item>
                    </Menu.Items>
                  )}
                </div>
              )}
            </Menu>
          )}
          <Menu as="div" className="hidden md:block relative ml-2 text-3xl">
            <div className="relative flex ml-4">
              <Menu.Button className="hover:scale-90 transform group flex items-center leading-none ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
              <FontAwesomeIcon
                icon={faLanguage}
                className="text-accent"
              />
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items className="absolute z-20 right-0 mt-2 w-32 ring-0 focus:ring-0 outline-none focus:outline-none">
                <div className="rounded-lg shadow-lg overflow-hidden">
                  <div className="relative bg-white p-4 space-y-4 text-xs">
                    {_.map(languages, (lang) => (
                      <Menu.Item
                        as="button"
                        key={lang.key}
                        type="button"
                        onClick={() => {
                          i18n.changeLanguage(lang.lang).then(() => {
                            window.localStorage.setItem(
                              `${envConfig.StorageKey}-lang`,
                              JSON.stringify(lang.lang)
                            );
                          });
                        }}
                        className={classNames(
                          i18n.language !== lang.lang && 'text-gray-400',
                          'group flex items-center hover:text-gray-500 space-x-2 ring-0 focus:ring-0 outline-none focus:outline-none transition ease-in-out duration-500'
                        )}
                      >
                        <FontAwesomeIcon icon={faLanguage} className="w-4 h-4 text-2xl" />
                        <span>{lang.name}</span>
                      </Menu.Item>
                    ))}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu> 
        </div>
      </nav>
    </header>
  )
  } 
  

export default HeaderV10;

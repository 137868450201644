import React from 'react';
import envConfig from '../../../envConfig';
import SocialFooter1 from './shared-components/SocialFooter1';


function SocialFooter() {
  if (envConfig.SocialFooterVersion === '1') { 
    return ( 
      <SocialFooter1 />
  )
  } if (envConfig.SocialFooterVersion === '2') {
    return ( 
      <SocialFooter1 />
  )
  }
  return ( 
    <SocialFooter1 />
  )
}

export default SocialFooter;

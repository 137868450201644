import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import {
  notificationsAtom,
  orderDetailsModalAtom,
  searchAddProductsAtom,
  searchLoadingAtom,
  orderDetailsAtom,
  searchResultsAtom,
} from '../../../../../../../../atoms/Atoms';
import Card from './Card';
import api from '../../../../../../../../api/api';
import Loader from '../../../../../../../shared-components/loader/Loader';

function AddProducts() {
  const { i18n, t } = useTranslation();
  const [searchResults, setSearchResults] = useRecoilState(searchResultsAtom);
  const [loading, setSearchLoading] = useRecoilState(searchLoadingAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [value, setValue] = useRecoilState(searchAddProductsAtom);
  const orderDetailsModal = useRecoilValue(orderDetailsModalAtom);
  const orderDetails = useRecoilValue(orderDetailsAtom);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (orderDetailsModal) {
      setOrder(orderDetailsModal);
    } else if (orderDetails) {
      setOrder(orderDetails);
    }
  }, [orderDetailsModal, orderDetails]);

  const getProducts = () => {
    setSearchLoading(true);
    setSearchResults([]);
    api
      .post(`businesses/V4/${order.businessId}/100/0`, {
        SearchType: 0,
        searchString: value,
      })
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('search_error')}`,
              error: true,
            },
          ]);
        } else {
          setSearchResults(response.data.data.items);
        }
        setSearchLoading(false);
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description:
            `${t('search_error')}`,
            error: true,
          },
        ]);
        setSearchLoading(false);
      });
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between space-x-4">
        <div className="text-sm font-bold tracking-tight leading-none">
          Añadir Productos
        </div>
        <div className="flex items-center space-x-2 text-gray-800">
          <input
            placeholder={t('search_products')}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => {
              if (value && event.key === 'Enter') getProducts();
            }}
            className="border border-gray-200 rounded-lg p-3 border-0 text-xs tracking-tight leading-tight ring-0 outline-none focus:outline-none"
          />
          <button
            type="button"
            onClick={() => getProducts()}
            className="flex p-3 text-xs font-semibold text-gray-600 tracking-tight leading-tight bg-gray-300 hover:bg-gray-200 rounded-lg ring-0 outline-none focus:outline-none transition duration-200 ease-linear"
          >
            Buscar
          </button>
        </div>
      </div>
      {loading && <Loader />}
      {!loading && searchResults.length === 0 && (
        <div className="flex flex-col flex-1 items-center justify-center">
          <div className="text-center tracking-tight">
            <div className="text-lg font-bold text-gray-800 tracking-tight leading-tight">
            {`${t('search_products')}...`}
            </div>
            <div className="text-xs text-gray-800 tracking-tight leading-tight">
              Utiliza la busqueda para encontrar los productos que deseas añadir
              a tu pedido
            </div>
          </div>
        </div>
      )}
      {!loading && searchResults.length > 0 && (
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          exit="hidden"
          className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-7 gap-2"
        >
          {_.map(searchResults, (product) => (
            <motion.div key={product.id} variants={item}>
              <Card
                name={product.name}
                description={product.description}
                image={product.imageUrl || null}
                brand={product.brand}
                price={product.activePrice}
                product={product}
              />
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  );
}

export default AddProducts;

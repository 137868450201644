import Home from '../components/pages/home/Home';
import Register from '../components/pages/register/Register';
import Login from '../components/pages/login/Login';
import NotFound from '../components/pages/404/404';
import Password from '../components/pages/password/Password';
import Reset from '../components/pages/reset/Reset';
// import Checkout from '../components/pages/checkout/Checkout';
// import Orders from '../components/pages/orders/Orders';
import Profile from '../components/pages/profile/Profile';
import Store from '../components/pages/store/Store';
import Departments from '../components/pages/departments/Departments';
import Rental from '../components/pages/bookings/Rental';
import Privacy from '../components/pages/privacy/Privacy';
import DropIn from '../components/pages/classes/DropIn';
import BookingsHome from '../components/pages/bookings/BookingsHome';
import Events from '../components/pages/events/Events';
import BookingsStatus from '../components/pages/bookings/BookingsStatus';
import Event from '../components/pages/specific-event/Event';
import Gallery from '../components/pages/gallery/Gallery';
import Checkout from '../components/pages/store/shared-components/Checkout';
import Department from '../components/pages/department/Department';
import Search from '../components/pages/search/Search';
import Order from '../components/pages/order/Order';
import EventDetails from '../components/pages/events/EventsLandingPage';
import EventTimeSlotDetails from '../components/pages/events/EventsLandingByTimeSlot';
import Product from '../components/pages/specific-product/Product';
import Subscriptions from '../components/pages/subscriptions/Subscriptions';
import MySubscriptions from '../components/pages/profile/shared-components/subscriptions/Subscriptions';
import Services from '../components/pages/services/Home';
import BookServices from '../components/pages/services/Services';

const routes = [
  {
    id: 'home',
    name: 'Inicio',
    path: '/',
    exact: true,
    protected: false,
    component: Home,
  },
  {
    id: 'Privacy',
    name: 'Privacy',
    path: '/privacy',
    exact: true,
    protected: false,
    component: Privacy,
  },
  {
    id: 'register',
    name: 'Registro',
    path: '/register/:friendCode?',
    exact: false,
    protected: false,
    component: Register,
  },
  {
    id: 'login',
    name: 'Iniciar Sesión',
    path: '/login',
    exact: false,
    protected: false,
    component: Login,
  },
  {
    id: 'password',
    name: 'Password',
    path: '/password',
    exact: false,
    protected: false,
    component: Password,
  },
  {
    id: 'reset',
    name: 'Reset',
    path: '/reset/:id/:token',
    exact: false,
    protected: false,
    component: Reset,
  },
  {
    id: 'rental',
    name: 'Rental',
    path: '/bookings/rentals',
    exact: false,
    protected: false,
    component: Rental,
  },
  {
    id: 'dropin',
    name: 'DropIn',
    path: '/classes/dropin',
    exact: false,
    protected: false,
    component: DropIn,
  },
  {
    id: 'bookings-home',
    name: 'BookingsHome',
    path: '/bookings/home',
    exact: false,
    protected: false,
    component: BookingsHome,
  },
  {
    id: 'bookings-status',
    name: 'BookingsStatus',
    path: '/bookings/status',
    exact: false,
    protected: false,
    component: BookingsStatus,
  },
  {
    id: 'events',
    name: 'Events',
    path: '/events',
    exact: false,
    protected: false,
    component: Events,
  },
  {
    id: 'event',
    name: 'Event',
    path: '/event/:eventId?',
    exact: true,
    protected: false,
    component: EventDetails,
  },
  {
    id: 'event',
    name: 'Event',
    path: '/event/timeslot/:eventId?/:startTime?',
    exact: true,
    protected: false,
    component: EventTimeSlotDetails,
  },
  {
    id: 'checkout',
    name: 'Pagar',
    path: '/checkout',
    exact: false,
    protected: true,
    component: Checkout,
  },
  {
    id: 'store',
    name: 'Tienda',
    path: '/stores/:id',
    exact: true,
    protected: false,
    component: Store,
  },
  {
    id: 'departments',
    name: 'Departamentos',
    path: '/stores/:id/departments',
    exact: true,
    protected: false,
    component: Departments,
  },
  {
    id: 'department',
    name: 'Departamento',
    path: '/stores/:id/departments/:departmentID',
    exact: true,
    protected: false,
    component: Department,
  },
  {
    id: 'order',
    name: 'Orden',
    path: '/order/:code',
    exact: false,
    protected: false,
    component: Order,
  },
  // {
  //   id: 'orders',
  //   name: 'Ordenes',
  //   path: '/orders',
  //   exact: false,
  //   protected: false,
  //   component: Orders,
  // },
  {
    id: 'profile',
    name: 'Profile',
    path: '/profile/:section?/:order?',
    exact: false,
    protected: true,
    component: Profile,
  },
  {
    id: 'gallery',
    name: 'Gallery',
    path: '/gallery',
    exact: false,
    protected: false,
    component: Gallery,
  },
  {
    id: 'search',
    name: 'Busqueda',
    path: '/stores/:id/search',
    exact: false,
    protected: false,
    component: Search,
  },
  {
    id: 'product',
    name: 'Product',
    path: '/product/:productId',
    exact: false,
    protected: false,
    component: Product,
  },
  {
    id: 'subscriptions',
    name: 'Subscriptions',
    path: '/subscriptions',
    exact: false,
    protected: false,
    component: Subscriptions,
  },
  {
    id: 'profileSubscriptions',
    name: 'My Subscriptions',
    path: '/profile/subscriptions',
    exact: false,
    protected: false,
    component: MySubscriptions,
  },
  {
    id: 'services',
    name: 'Services',
    path: '/services',
    exact: true,
    protected: false,
    component: Services,
  },
  {
    id: 'bookServices',
    name: 'Book Services',
    path: '/services/book',
    exact: true,
    protected: false,
    component: BookServices,
  },
  {
    id: 'notfound',
    name: 'Not Found',
    path: '*',
    exact: false,
    protected: false,
    component: NotFound,
  }
];

export default routes;

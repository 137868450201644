import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import './assets/main.css';
import './assets/tailwind.generated.css';
import './i18n/config';
import App from './components/App';

ReactDOM.render(
  <Router>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Router>,
  document.getElementById('root')
);

const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY ?? 'ec1c559c517d42fd8034d91c7ce7314f',
  API_URL: process.env.REACT_APP_API_URL ?? 'https://meca-api.azure-api.net/web/',
  APP_UTILITY_KEY: process.env.REACT_APP_UTILITY_KEY ?? '#{REACT_APP_UTILITY_KEY}#',
  BOOKING_API_URL: process.env.REACT_APP_BOOKING_API_URL ?? 'https://meca-booking-prod.azurewebsites.net/api/',
  BOOKING_API_KEY: process.env.REACT_APP_BOOKING_API_KEY ?? '#{REACT_APP_BOOKING_API_KEY}#',
  HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://meca-api-prod.azurewebsites.net',
  PAYPAL_CLIENTID: process.env.REACT_APP_PAYPAL_CLIENTID ?? 'ATnHDVLEUOkut3mlK_F-AbsN6gVm6QBWo4kN3cK2Z22PjKgQcX15ztftAiXXoEd0Sllvz5JcupdeyHXR',
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyCbyH9QdelpvTas84vne4P4dffXrGXjVtE',
  SearchItemsPageSize: process.env.REACT_APP_SEARCH_PAGE_SIZE ?? '12',
  StorageKey: process.env.REACT_APP_STORAGE_KEY ?? 'meca', 
  DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE ?? '#{REACT_APP_DEFAULT_LANGUAGE}#', 
  TemplateStyle: process.env.REACT_APP_TEMPLATE_STYLE ?? '#{REACT_APP_TEMPLATE_STYLE}#',
  TemplateVersion: process.env.REACT_APP_TEMPLATE_VERSION ?? '10',
  HeaderVersion: process.env.REACT_APP_HEADER_VERSION ?? '10',
  SubHeaderVersion: process.env.REACT_APP_SUBHEADER_VERSION ?? '10',
  StoreVersion: process.env.REACT_APP_STORE_VERSION ?? '10',
  WelcomeLong: process.env.REACT_APP_WELCOME_LONG ?? '#{REACT_APP_WELCOME_LONG}#',
  WelcomeShort: process.env.REACT_APP_WELCOME_SHORT ?? '#{REACT_APP_WELCOME_SHORT}#',
  DepartmentsStyle: process.env.REACT_APP_DEPARTMENTS_STYLE ?? 'sidebar-right',
  DepartmentCarouselSize: 6,
  CardBorderStyle: process.env.REACT_APP_CARD_BORDER_STYLE ?? '#{REACT_APP_CARD_BORDER_STYLE}#',
  ButtonStyle: process.env.REACT_APP_BUTTON_STYLE ?? '#{REACT_APP_BUTTON_STYLE}#',
  SocialFooterVersion: process.env.REACT_APP_SOCIAL_FOOTER_VERSION ?? '#{REACT_APP_SOCIAL_FOOTER_VERSION}#',
  InstagramLink: process.env.REACT_APP_SOCIAL_INSTAGRAM ?? 'https://www.instagram.com/mecacomplex/',
  FacebookLink: process.env.REACT_APP_SOCIAL_FACEBOOK ?? 'https://www.facebook.com/mecacomplex',
  WelcomeVersion: process.env.REACT_APP_WELCOME_VERSION ?? '#{REACT_APP_WELCOME_VERSION}#',
  PayPalForBookings: process.env.REACT_APP_PAYPAL_BOOKING ?? 'true',
  AthMovilForBookings: process.env.REACT_APP_ATHMOVIL_BOOKING ?? 'false',
  AthMovilPublicKey: process.env.REACT_APP_ATHMOVIL_PUBLIC_KEY ?? '#{REACT_APP_ATHMOVIL_PUBLIC_KEY}#',
  AthMovilPrivateKey: process.env.REACT_APP_ATHMOVIL_PRIVATE_KEY ?? '#{REACT_APP_ATHMOVIL_PRIVATE_KEY}#',
  PayPalKey: process.env.REACT_APP_PAYPAL_KEY ?? 'ATnHDVLEUOkut3mlK_F-AbsN6gVm6QBWo4kN3cK2Z22PjKgQcX15ztftAiXXoEd0Sllvz5JcupdeyHXR',
  AllowCreditCardCreate: process.env.REACT_APP_ALLOW_CARD_CREATE ?? 'false',
  ShowPaymentButtonOnBookingReservation: process.env.REACT_APP_SHOW_PAYMENT_ON_BOOKING ?? 'false',
  ShowButtonOnBookingReservation: process.env.REACT_APP_SHOW__ON_BOOKING ?? '#{REACT_APP_SHOW__ON_BOOKING}#',
  FacebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? '#{REACT_APP_FACEBOOK_PIXEL_ID}#',
  GoogleAnalyticsTrackingId: process.env.REACT_APP_GA_TRACKING_ID ?? 'G-PEW5W8Q649',
  BrainTreeTokenizationKey: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY ?? 'production_d57rqhwq_gnvpbm97c73msk5w',
  ContactEmail: process.env.REACT_APP_CONTACT_EMAIL ?? 'bookings@mecacomplex.com',
  HasReferrals: process.env.REACT_APP_HAS_REFERRALS_ENABLED ?? 'false',
  CardVersion: process.env.REACT_APP_CARD_VERSION ?? '10',
  SidebarVersion: process.env.REACT_APP_SIDEBAR_VERSION ?? '10',
  WebstoreId: process.env.REACT_APP_WEDSTORE_ID ?? '10',
  AllowCreditCardsInBooking: process.env.REACT_APP_CREDITCARD_BOOKING ?? 'false',
  AllowCreditCardsInWebstore: process.env.REACT_APP_CREDITCARD_WEBSTORE ?? 'true',
  CounterVersion: process.env.REACT_APP_COUNTER_VERSION ?? '10',
  ShowWebstore: process.env.REACT_APP_SHOW_WEBSTORE ?? 'false'
};

export default envConfig;

import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { checkoutSelector } from '../../../../atoms/Selectors';
import { itemInstructionsModalAtom } from '../../../../atoms/Atoms';
import Counter from '../../../shared-components/counter/Counter';
import { thousand } from '../../../../functions';

function Order() {
  const checkout = useRecoilValue(checkoutSelector);
  const setItemInstructions = useSetRecoilState(itemInstructionsModalAtom);
  const { t } = useTranslation();

  const getTotal = (id) => {
    const filter = _.filter(checkout.order, (e) => e.id === id);
    const price = filter[0].activePrice;
    let amount = 0;
    for (let i = 0; i < filter.length; i++) {
      amount += filter[i].amount;
    }
    return {
      subtotal: price,
      units: amount,
      total: (price * amount).toFixed(2),
    };
  };

  const containerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <>
      <motion.div
        initial={false}
        animate={checkout.order.length > 0 ? 'open' : 'closed'}
      >
        <motion.div variants={containerVariants} className="mt-4 space-y-2">
          <ul className="-my-2 divide-y divide-gray-200">
            {_.map(_.orderBy(_.uniqBy(checkout.order, 'id'), 'name'),
              (e, index) => (
                <li key={e.id} className="flex py-6">
                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                    <img
                      src={e.imageUrl}
                      alt={e.name}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="ml-4 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <h3>
                          {e.brand}
                        </h3>                                       
                        <p className="ml-4">${thousand(getTotal(e.id).total)}</p>
                      </div>
                      <p className="mt-1 text-xs text-gray-600">{e.name}</p>
                      <p className="mt-1 text-xs text-gray-500">{e.description}</p>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                      <p className="text-gray-500">Qty {getTotal(e.id).units}</p>
                        <div className="flex">
                          <Counter product={e} />
                        </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </motion.div>
      </motion.div>
      {checkout.order.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1,
            delay: 0.5,
          }}
          className="h-full flex flex-col flex-1 items-center justify-center"
        >
          <FontAwesomeIcon icon={faShoppingBasket} className="text-rose-500" />
          <div>{t('store_alert_title')}</div>
          <div className="text-gray-400 text-xs text-center">
            {t('empty_cart_content')}
          </div>
        </motion.div>
      )}
    </>
  );
}

export default Order;

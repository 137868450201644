import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import Banner from '../../../assets/img/404.svg';

function NotFound() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="h-screen bg-white text-white w-full flex flex-col justify-between items-center text-center">
      <div className="flex flex-1 justify-center items-center flex-col">
        <div className="text-7xl tracking-tight font-extrabold text-red-400">
          404
        </div>
        <div className="text-4xl text-secondary tracking-tight font-extrabold">
          {t('oops_404')}
        </div>
        <div className="mb-4 text-gray-400">
        {t('dont_worry_404')}
        </div>
        <button
          type="button"
          onClick={() => history.push('/')}
          className="text-sm flex-grow-0 space-x-2 flex items-center rounded px-3 py-2 font-medium tracking-tight border border-red border-opacity-20 hover:border-opacity-30 outline-none focus:outline-none transition duration-500 ease-in-out  text-red-400"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <div>{t('home_page')}</div>
        </button>
      </div>
      <div className="flex hidden items-center text-center">
        <img alt="404 Banner" src={Banner} />
      </div>
    </div>
  );
}

export default NotFound;

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';

import Image4 from './shared-components/images/1.png';
import Image5 from './shared-components/images/2.png';
import Image6 from './shared-components/images/3.png';
import Image7 from './shared-components/images/4.png';
import Image8 from './shared-components/images/8.png';


import {
  locationAtom,
  storeAtom,
  serviceSelectionAtom,
  userAtom,
  serviceAtom,
} from '../../../atoms/Atoms';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import Service10 from './shared-components/Service10';


function Services() {
  const { t } = useTranslation();
  const user = useRecoilValue(userAtom);
  const history = useHistory();
  const [open, setOpen] = useRecoilState(serviceSelectionAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [service, setService] = useRecoilState(serviceAtom);

  useEffect(() => {
    if (envConfig.ShowWebstore) {
      api({
        method: 'GET',
        url: 'businesses',
      })
        .then((response) => {
          // console.log('stores', response.data.data.results);
          const filteredStores =
            _.filter(response.data.data.results, (s) => s.id === envConfig.WebstoreId);
          setStore(filteredStores[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const services = [
    {
      id: '1',
      key: '1',
      title: t('Beach Tennis Academy'),
      subTitle: t('learn_with_us'),
      body: t('beach_tenis_body'),
      note: t('beach_tenis_body'),
      buttonText: null,
      image: Image4,
      bgImage: null,
      visible: true,
      enabled: true,
      onClick: () => {
        setService(-1);
        history.push('/bookings/home');
      }
    },   
    {
      id: '2',
      key: '2',
      title: t('court_rentals'),
      subTitle: t('court_rentals_subtitle'),
      body: t('test'),
      note: t('court_rentals_note'),
      buttonText: t('start_delivery_order'),
      image: Image5,
      bgImage: null,
      visible: true,
      enabled: true,
      onClick: () => {
        setService(-1);
        history.push('/bookings/rentals');
      }
    },   
    // {
    //   id: '3',
    //   key: '3',
    //   title: 'MECA Shop',
    //   subTitle: t('online_shop_subtitle'),
    //   body: t('merch_available'),
    //   note: t('merch_available'),
    //   buttonText: t('start_delivery_order'),
    //   image: Image8, 
    //   bgImage: null,
    //   visible: true,
    //   enabled: true,
    //   onClick: () => {
    //     if (envConfig.ShowWebstore) {
    //       setService(0);
    //       history.push(`/stores/${store && store.id}`); 
    //     }
    //   } 
    // },  
    {
      id: '4',
      key: '4',
      title: t('events'),
      subTitle: t('events_subtitle'),
      body: t('test'),
      note: t('events_note'),
      buttonText: '',
      image: Image6,
      bgImage: null,
      enabled: true,
      onClick: () => {
        setService(-1);
        history.push('/events');
      } 
    },
    {
      id: '5',
      key: '5',
      title: t('services'),
      subTitle: t('services_subtitle'),
      body: '',
      note: '',
      buttonText: '',
      image: Image8, 
      bgImage: null,
      visible: true,
      enabled: true,
      onClick: () => {
        setService(-1);
        history.push('/services'); 
      } 
    },
    {
      id: '6',
      key: '6',
      title: t('gallery'),
      subTitle: t('gallery_subtitle'),
      body: '',
      note: '',
      buttonText: '',
      image: Image7,
      bgImage: null,
      enabled: true,
      onClick: () => {
        setService(-1);
        history.push('/gallery');
      } 
    },
  ];


  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-5 gap-5 py-2 md:py-12 px-3">
      {_.map(
        services,
        ({ id, title, subTitle, body, note, image, buttonText, onClick, enabled }) => (
          <Service10
            key={id}
            title={title}
            subTitle={subTitle}
            body={body}
            note={note}
            image={image}
            buttonText={buttonText}
            onClick={onClick}
            enabled={enabled}
            dark
          />
        )
      )}
    </div>
  );
}

export default Services;

import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Panel from '../../../shared-components/panel/Panel';
import { H3, H1 } from '../../../shared-components/typography/Title';
import Subtitle from '../../../shared-components/typography/Subtitle';
import Input from '../../../shared-components/form/Input';
import Button from '../../../shared-components/buttons/Button';
import api from '../../../../api/api';
import {
  promoCodeAtom,
  notificationsAtom,
  storeAtom,
  serviceAtom,
  locationAtom,
  referenceOrderAtom,
  serviceSelectionAtom
} from '../../../../atoms/Atoms';
import { checkoutSelector, minimumSelector } from '../../../../atoms/Selectors';
import { thousand } from '../../../../functions';


function Summary() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const service = useRecoilValue(serviceAtom);
  const checkout = useRecoilValue(checkoutSelector);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom)
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [referenceOrder, setReferenceOrder] = useRecoilState(
    referenceOrderAtom
  );
  const minimum = useRecoilValue(minimumSelector);
  const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);


  
  return (
    <Panel>
      <H3 text={t('order_summary')} />
      {/* <Subtitle
        text={t('promo_code_description')}
      /> */}
      {store &&
        <div className="top-0 bg-white border-gray-200 text-left">
                <div className="text-sm">
                {t('subtotal')}: ${thousand(checkout.subtotal)}
                </div>
                <div className="flex flex-row">
                <div                  
                  className="text-sm tracking-tight"
                >
                  {t('order_service')}:
                </div>
                <div
                  role="button"
                  tabIndex={0} 
                  className="text-sm tracking-tight underline hover:underline text-accent ml-2"
                  // onClick={() => setServiceSelection(true)}
                >
                  {service === 0 ? t('pickup') : t('delivery')}
                </div>
                </div>                
                {service === 1 && (
                <>
                <div className="flex md:flex-row items-start">
                    <div className="text-sm tracking-tight">
                    {location.location?.name}
                    </div>
                    <div className={location.location?.addressLine === location.location?.name ? 'hidden' : 'text-sm tracking-tight ml-1'}>
                    {location.location?.addressLine}
                    </div>
                    <div className="text-sm tracking-tight ml-1">
                    {location.location?.city}
                    </div>
                    <div className="text-sm tracking-tight ml-1">
                    {location.location?.zipcode}
                    </div>    
                </div>               
                </>
                )}
                {service === 0 && (
                <div className="text-sm tracking-tight">
                    {t('store')}: {store?.name}
                </div>
                )}
                {!referenceOrder && (
                <div className="text-sm tracking-tight">
                    {t('minimum_purchase').format(minimum?.toFixed(2))}
                </div>
                )}
        </div>}
    </Panel>
  );
}

export default Summary;

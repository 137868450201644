// Drawer.js
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMount } from 'react-use';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Drawer } from 'antd';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useRecoilValue, useRecoilState } from 'recoil';
import { faCreditCard, faCreditCardFront, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import envConfig from '../../../../envConfig';
import {
    backdropVariants,
    sidebarVariants,
    modalVariants
  } from '../../../../utils';
import bookingApi from '../../../../api/bookingApi';
import { notificationsAtom, userAtom, userPaymentsAtom } from '../../../../atoms/Atoms';
import { getRecurrenceLength } from '../../../../functions';
import { Spinner } from '../../../shared-components/Spinner';

const DetailsDrawer = ({ isOpen, closeDrawer, selectedSubscription }) => {
  const user = useRecoilValue(userAtom);
  const { t, i18n } = useTranslation();
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [submitting, setSubmitting] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const history = useHistory();
  const showPaymentButton = envConfig.ShowPaymentButtonOnBookingReservation;
  const paymentsRef = useRef();

const agreementsList = [
    { text: t('class_agreement_option_1'), index: '1' },
    { text: t('class_agreement_option_2'), index: '2' },
    { text: t('class_agreement_option_4'), index: '4' }
];

const closePaymentsModal = () => {
    setShowPaymentsModal(false);
  };

  useEffect(() => {
    setIsChecked(false);
  }, [selectedSubscription]);

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }
  
  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  // function assignFreeSubscription() {
  //   setSubmitting(true);
  //   bookingApi
  //     .post(
  //       'subscriptions/subscribe',
  //     {
  //       subscriptionId: selectedSubscription.id,
  //       autoRenew: false,
  //       cardId: null,
  //       payPalAgreementId: null,
  //       paypalReferenceNumber: '123',
  //       paypalInvoiceNumber: '123',
  //       userId: user.id
  //     },
  //     null
  //     )
  //     .then((response) => {
  //       if (response.data.error) {
  //         setNotifications([
  //           ...notifications,
  //           {
  //             title: `${t('oops')}`,
  //             description: response.data.error,
  //             error: true,
  //           },
  //         ]);
  //         setSubmitting(false);
  //       } else {
  //         setSubmitting(false);
  //         setNotifications([
  //           ...notifications,
  //           {
  //             title: `${t('alert')}`,
  //             description: response.data.message,
  //             error: false,
  //           },
  //         ]);
  //         closeDrawer();
  //       }
  //     })
  //     .catch((error) => {
  //       setSubmitting(false);
  //       console.error(error);
  //     });
  // }

  function SubscriptionActivation(cardId, ppInvoiceNumber, ppReferenceNumber, ppAgreementId) {
    setSubmitting(true);
    bookingApi
      .post(
        'subscriptions/subscribe',
      {
        subscriptionId: selectedSubscription.id,
        autoRenew: false,
        cardId: null,
        payPalAgreementId: null,
        paypalReferenceNumber: ppReferenceNumber,
        paypalInvoiceNumber: ppInvoiceNumber,
        userId: user.id
      }
      )
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description: response.data.error,
              error: true,
            },
          ]);
          setSubmitting(false);
        } else {
          setSubmitting(false);
          if (response.data.message) {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: response.data.message,
                error: false,
              },
            ]);
          } else {
            setNotifications([
              ...notifications,
              {
                title: t('yay'),
                description: t('subscribed_successfully'),
                error: false,
              },
            ]);
          }
          closeDrawer();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
      });
  }
  useMount(() => {
    setIsChecked(false);
  });

  return (
    <div>
      <Drawer title={t('subscription_details')} onClose={closeDrawer} open={isOpen} width={600}>
        <div className="flex">
          <div className="my-2 text-xl font-bold">{i18n.language === 'es' ? selectedSubscription?.nameEs : selectedSubscription?.nameEn}</div>
          {selectedSubscription?.isOnPromo && ( 
            <div className="my-2 ml-auto text-xl font-bold">${selectedSubscription?.promoPrice.toFixed(2)}</div>
          )}
            {!selectedSubscription?.isOnPromo && ( 
            <div className="my-2 ml-auto text-xl font-bold">${selectedSubscription?.price.toFixed(2)}</div>
          )}
        </div>
        <div className="text-md mt-1">{i18n.language === 'es' ? selectedSubscription?.descriptionEs : selectedSubscription?.descriptionEn}</div>
        <div className="text-md mt-1">{`Starts: ${moment().add(-4, 'hour').format('MMM D, YYYY')}`}</div>
        {selectedSubscription?.recurrence > 0 ? <div className="text-md mt-1 capitalize">{t('duration')} : {t(getRecurrenceLength(selectedSubscription?.recurrence))}</div> : null}
           {!showPaymentButton && (
              <>
                <div className="my-2 text-md font-medium">{t('contact_us_for_booking')}</div>
                <a href="mailto:bookings@mecacomplex.com" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">bookings@mecacomplex.com</a>
              </>
            )}
            {!user && (
              <Button
                onClick={() => {
                history.push('/login', { from: 'subscriptions' });
              }}
                loaderColor="bg-white"
                type="primary"
                block
                size="large"
                className="bg-accent text-md hover:bg-accent mt-4"
              >
                {t('login_to_dropin')}
              </Button>
            )}
            {!selectedSubscription?.isSubscribed && user && (
              <div>
                <div className="flex flex-col items-start mt-8 bg-red-100 rounded-xl p-4 border-t border-gray-100">
                  <label className="text-sm font-semibold">{t('terms_and_conditions')}</label>
                  <button 
                    hidden
                    type="button"
                    onClick={handleToggle}
                  >
                    {isExpanded ? 'show less' : 'show less'} List
                  </button>
                {isExpanded && (
                  <ul className="text-sm">
                    {_.map(agreementsList, (item, index) => (
                      <li key={item.index}>-{item.text}</li>
                    ))}
                  </ul>
                )}
                <div className="text-sm mt-4">
                  <span>{t('if_cancel_booking_contact')}
                  </span>
                  <a href="mailto:bookings@mecacomplex.com" className=" ml-1 font-medium text-blue-600 dark:text-blue-500 hover:underline">bookings@mecacomplex.com</a>
                </div>
                </div>          
              <div className="flex items-center mx-1 mt-8 mb-4">
                <input
                  type="checkbox"
                  className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement" className="ml-2 text-sm">
                  {t('booking_i_agree_terms')}
                </label>
              </div>
              </div>)}
              {selectedSubscription?.isSubscribed && user && (
                <div className="my-4 text-center text-white bg-purple-600 rounded-xl p-2 text-lg">
                {t('already_reserved')}
                </div>
              )}
              {showPaymentButton && 
              !selectedSubscription?.isSubscribed && user && (
                <Button
                  type="primary"
                  block
                  size="large"
                  disabled={loading || !isChecked}
                  loading={loading}
                  onClick={() => {
                    setShowPaymentsModal(true);
                    setIsChecked(false);
                  }}
                  loaderColor="bg-white"
                  className="bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-lg"
                >
                  {t('complete_payment')}
                </Button>
              )}
              {selectedSubscription?.isSubscribed && user && (
                <div className="my-4 text-center text-purple-600 p-2 text-lg">
                {t('already_have_reservation')}
                </div>
              )}
              {showPaymentsModal && !payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          key="payments"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <motion.div
            variants={modalVariants}
            className="text-gray-800 antialiased justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              ref={paymentsRef}
              className="flex flex-col bg-white rounded-xl mx-auto"
            >
              <header className="flex items-center justify-between bg-gray-800 text-white rounded-t-xl px-4 py-6">
                <div className="flex items-center space-x-1 font-bold tracking-tight text-xl leading-tight">
                  {t('amount_to_pay')}: ${selectedSubscription?.isOnPromo ? `${(selectedSubscription?.promoPrice).toFixed(2)}` : `${(selectedSubscription?.price).toFixed(2)}`}
                </div>
                <button
                  type="button"
                  onClick={() => closePaymentsModal()}
                  className="text-xl leading-none ring-0 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              </header>
              {loading && <Spinner className="mx-auto mt-2" />}
              <main className="group relative flex flex-1 overflow-y-auto bg-gray-50 rounded-b-xl p-4">
                <div className="flow">
                  <div>{t('payment_method_description')}</div>
                  <Spinner
                    spinning={loading}
                    className="mx-auto my-4"
                  />
                  {envConfig.AllowCreditCardCreate === 'true' && envConfig.AllowCreditCardsInBooking === 'true' && (
                    <button
                      type="button"
                      onClick={() => {
                        setPayment({ ...payment, modal: true });
                      }}
                      className="text-left w-full mt-4 transform hover:scale-90 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-amber-400 border border-amber-400 outline-none focus:outline-none"
                    >
                      <div className="w-16 h-full flex flex-shrink-0 items-center justify-center text-white text-3xl bg-amber-400 rounded-l-xl">
                        <FontAwesomeIcon icon={faCreditCardFront} />
                      </div>
                      <div className="flex flex-col justify-center p-4 text-xs text-gray-800">
                        <div className="font-semibold">{t('add_new_payment')}</div>
                        <div>{t('new_card')}</div>
                      </div>
                    </button>
                  )}
                  {envConfig.PayPalForBookings === 'true' && !loading && (
                    <div className="mt-6">
                      <PayPalScriptProvider options={{ 'client-id': envConfig.PayPalKey }}>
                        <PayPalButtons
                          createOrder={(data, actions) => actions.order.create({
                            purchase_units: [
                                {
                                description: `${selectedSubscription?.nameEn} - ${selectedSubscription?.descriptionEn} Starts: ${moment().add(-4, 'hour').format('MMM D, YYYY')}`,
                                amount: {
                                    value: selectedSubscription?.isOnPromo ? `${(selectedSubscription?.promoPrice).toFixed(2)}` : `${(selectedSubscription?.price).toFixed(2)}`,
                                },
                                custom_id: `${user?.id} ${selectedSubscription?.id}`
                                },
                                // {
                                //     description: `${subs?.description}`,
                                //     amount: {
                                //         value: `${(subs?.price).toFixed(2)}`,
                                //     },
                                //     custom_id: `${user?.id} ${subs?.id}`
                                // },
                            ],
                            application_context: { brand_name: 'MECA Complex', locale: `${i18n.language}-US`, shipping_preference: 'NO_SHIPPING' }
                          })}
                          onApprove={(data, actions) => actions.order.capture().then((details) => {
                            const refNumber = details.id;
                            const invoiceNumber =
                              details.purchase_units[0]?.payments?.captures[0]?.id;
                              SubscriptionActivation(null, invoiceNumber, refNumber, null);
                          })}
                        />
                      </PayPalScriptProvider>
                    </div>
                  )}
                  {envConfig.AthMovilForBookings === 'true' && !loading && (
                    <></>
                  )}
                  {envConfig.AllowCreditCardsInBooking === 'true' && _.map(payment.cards, (card) => (
                    <button
                      type="button"
                      onClick={() => SubscriptionActivation(card, null, null, null)}
                      key={card.id}
                      className="w-full"
                    >
                      <div
                        className="divide-x mt-4 divide-purple-200 shadow rounded-xl flex h-20 transition duration-500 ease-linear ring-2 ring-offset-2 ring-purple-400 border border-purple-400 outline-none focus:outline-none"
                      >
                        <div className="w-16 flex flex-col items-center justify-center text-white text-3xl bg-purple-600 rounded-l-xl">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </div>
                        <div className="flex flex-col flex-1 items-start justify-start text-xs p-2">
                          <div className="font-semibold">{card.brand}</div>
                          <div>**** {card.last4}</div>
                          <div>{card.expirationDate}</div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </main>
            </div>
          </motion.div>
        </motion.div>
      )}           
      </Drawer>
    </div>
  );
};

export default DetailsDrawer;

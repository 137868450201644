import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFormik } from 'formik';
import { Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline'
import { useRecoilState, useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useClickAway } from 'react-use';
import { locationAtom, tokenAtom } from '../../../../../atoms/Atoms';
import api from '../../../../../api/api';
import { Spinner } from '../../../Spinner';
import Form from '../../../form/Form';
import Input from '../../../form/Input';
import Textarea from '../../../form/Textarea';
import envConfig from '../../../../../envConfig';

function Register(props) {
  let lang = 'en';
  if (window.localStorage.getItem(`${envConfig.StorageKey}-lang`)) {
    lang = window.localStorage.getItem(`${envConfig.StorageKey}-lang`).replaceAll('"', '')
  }
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: envConfig.GEO_API_KEY,
    language: `${lang}-US`,
    libraries: ['places']
  })

  const { t } = useTranslation();
  // const { google } = props;
  const suggestionsRef = useRef();
  const [editable, setEditable] = useState(false);
  const [state, setState] = useState({
    address: '',
    draggable: true,
    lat: 18.3977264,
    lng: -66.1040551,
    // google,
    suggestions: [],
  });
  const [location, setLocation] = useRecoilState(locationAtom);
  const token = useRecoilValue(tokenAtom);

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      city: '',
      zipcode: '',
      instructions: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(`${t('required_field')}`),
      address: Yup.string().required(`${t('required_field')}`),
      latitude: Yup.number()
        .typeError(`${t('only_numbers')}`)
        .required(`${t('required_field')}`),
      longitude: Yup.number()
        .typeError('Solo numeros en este campo')
        .required(`${t('required_field')}`),
      city: Yup.string().required(`${t('required_field')}`),
      zipcode: Yup.string().required(`${t('required_field')}`),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      api
        .post(
          'users/me/locations/new',
          {
            AdditionalInstructions: values.instructions,
            AddressLine: values.address,
            City: values.city,
            Name: values.name,
            Lat: values.latitude,
            Lon: values.longitude,
            Zipcode: values.zipcode,
          }
        )
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            setLocation({
              ...location,
              locations: [...location.locations, response.data.data],
              current: 'locations',
            });
          }
          formik.resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setSubmitting(false);
        });
    },
  });

  const apiHasLoaded = (map) => {
    const placesService = new window.google.maps.places.PlacesService(map);
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    const bounds = new window.google.maps.LatLngBounds({
      lat: state.lat,
      lng: state.lng,
    });
    map.fitBounds(bounds);
    setState({
      ...state,
      mapApiLoaded: true,
      mapInstance: map,
      placesService,
      autocompleteService
    });
  };

  const fetchPredictions = () => {
    if (state.address.length && state.mapApiLoaded) {
      state.autocompleteService.getPlacePredictions(
        {
          input: state.address,
          componentRestrictions: { country: 'pr' },
        },
        (predictions, status) => {
          if (status !== 'OK') {
            return;
          }

          setState({
            ...state,
            suggestions: predictions,
          });
        }
      );
    } else {
      setState({
        ...state,
        suggestions: [],
      });
    }
  };

  const getDetails = (placeId, lat, lng) => {
    state.placesService.getDetails(
      {
        placeId,
        fields: [
          'name',
          'address_components',          
          'formatted_address',
          'geometry',
          'place_id',
        ],
      },
      (result, status) => {
        if (status === 'OK') {
          state.mapInstance.fitBounds(result.geometry.viewport);

          _.each(result.address_components, (component) => {
            switch (component.types[0]) {
              case 'postal_code':
                return formik.setFieldValue('zipcode', component.long_name);
              case 'locality':
                return formik.setFieldValue('city', component.long_name);
              case 'administrative_area_level_1':
                return formik.setFieldValue('city', component.long_name);
              default:
                return null;
            }
          });
          formik.setFieldValue('name', result.name);
          formik.setFieldValue('address', result.formatted_address.split(',')[0]);
          formik.setFieldValue('latitude', result.geometry.location.lat());
          formik.setFieldValue('longitude', result.geometry.location.lng());

          setEditable(true);

          if (lat && lng) {
            setState({
              ...state,
              draggable: true,
              address: '',
              suggestions: [],
              lat,
              lng,
            });
          } else {
            setState({
              ...state,
              draggable: true,
              address: '',
              suggestions: [],
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    fetchPredictions();
  }, [state.address]);

  useClickAway(suggestionsRef, () => {
    setState({
      ...state,
      address: '',
      suggestions: [],
    });
  });

  return (
    <div className="flex flex-col w-full h-full divide-y divide-gray-200 transition-all transform shadow-xl duration-500 easy-in-out">
       <button
         type="button"
         onClick={() => setLocation({ ...location, current: null, location: null })}
         className="hover:opacity-75 outline-none focus:outline-none"
       >
        <FontAwesomeIcon icon={faTimesCircle} className="text-2xl text-black" />
       </button>
      <div className="flex flex-col flex-1 no-scrollbar p-4">
        <div className="text-xs mb-4 hidden">
         {t('new_location_search_prompt')}
        </div>
        <div>
          <div className="flex flex-col text-xs py-4">
            <div className="flex space-x-2">
              <div className="flex flex-shrink-0 items-center justify-center bg-primary w-8 h-8 font-bold ordinal slashed-zero tabular-nums text-white rounded-full">
                01
              </div>
              <div>
                <div className="font-semibold">{t('search_address')}</div>
                <div className="mb-4">
                  {t('search_address_prompt')}
                </div>
              </div>
            </div>
            <div className="h-12 group relative w-full md:px-6">
              <div className="flex items-center h-full text-sm px-4 border border-accent bg-white rounded">
                <SearchIcon className="w-4 h-4" />
                <input
                  type="text"
                  className="pl-2 bg-transparent text-xs border-0 w-full leading-tight outline-none focus:outline-none ring-0 focus:ring-0 px-0"
                  value={state.address}
                  placeholder={t('search_address')}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  onChange={(e) => {
                    setState({ ...state, address: e.target.value });
                  }}
                />
              </div>
              <Transition
                appear={state.suggestions.length > 0}
                show={state.suggestions.length > 0}
                enter="transform transition duration-500"
                enterFrom="opacity-0 -translate-y-50"
                enterTo="opacity-100 -translate-y-0"
                leave="transform transition duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute z-10 mt-2 w-full divide-y divide-gray-100 shadow-xl bg-white px-4"
              >
                {_.map(state.suggestions, (suggestion) => (
                  <button
                    key={suggestion.place_id}
                    type="button"
                    onClick={() => getDetails(suggestion.place_id)}
                    className=" hover:text-gray-500 text-xs text-left block w-full py-4 px-4 truncate ring-0 focus:ring-0 outline-none focus:outline-none transition duration-200 ease-linear cursor-pointer"
                  >
                    {suggestion.description}
                  </button>
                ))}
              </Transition>
            </div>
          </div>
          <div className="flex flex-col text-xs py-4">
            <div className="flex space-x-2">
              <div className="flex flex-shrink-0 items-center justify-center bg-primary w-8 h-8 font-bold ordinal slashed-zero tabular-nums text-white rounded-full">
                02
              </div>
              <div>
                <div className="font-semibold">
                  {t('move_marker')}
                </div>
                <div className="text-gray-500 mb-4">
                  {t('move_marker_prompt')}
                </div>
              </div>
            </div>
            <div className="h-96 relative md:mx-6">
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  zoomControl
                  draggable
                  center={{
                    lat: state.lat,
                    lng: state.lng,
                  }}
                  zoom={10}
                  onLoad={apiHasLoaded}
                >
                  <Marker
                    draggable
                    position={{ lat: state.lat, lng: state.lng }}
                    onDragend={(a, b, coords) => {
                      const lat = coords.latLng.lat();
                      const lon = coords.latLng.lng();
                      formik.setFieldValue('latitude', lat);
                      formik.setFieldValue('longitude', lon);
                      console.log('latitude', lat);
                      console.log('longitude', lon);
                      setState({
                          ...state,
                          lat,
                          lng: lon,
                        });
                    }}
                  />
                </GoogleMap>
              )}
              {/* <Map
                hidden
                draggable
                style={{
                  position: 'absolute',
                }}
                google={state.google}
                onReady={apiHasLoaded}
                zoomControl
                initialCenter={{
                  lat: state.lat,
                  lng: state.lng,
                }}
              >
                <Marker
                  draggable
                  position={{ lat: state.lat, lng: state.lng }}
                  onDragend={(a, b, coords) => {
                    const lat = coords.latLng.lat();
                    const lon = coords.latLng.lng();
                    formik.setFieldValue('latitude', lat);
                    formik.setFieldValue('longitude', lon);
                    setState({
                        ...state,
                        lat,
                        lng: lon,
                      });
                  }}
                />
              </Map> */}
              <div
                hidden={editable}
                className="absolute h-full w-full"
                style={{ background: 'rgb(255 255 255 / 57%)' }}
              />
            </div>
          </div>
          <div className="flex flex-col text-xs py-4">
            <div className="flex space-x-2">
              <div className="flex flex-shrink-0 items-center justify-center bg-primary w-8 h-8 font-bold ordinal slashed-zero tabular-nums text-white rounded-full">
                03
              </div>
              <div>
                <div className="font-semibold">
                  {t('edit_address_fields')}
                </div>
                <div className="text-gray-500">
                  {t('edit_address_fields_prompt')}
                </div>
              </div>
            </div>
            <Form className="md:px-6">
              <Input
                disabled={!editable}
                name="name"
                label={t('name')}
                type="text"
                required
                placeholder={t('sample_address_name')}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className="col-span-1 md:col-span-2"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={
                  formik.errors.name &&
                  formik.touched.name &&
                  formik.errors.name
                }
              />
              <Input
                disabled={!editable}
                name="address"
                label={t('address')}
                type="text"
                required
                placeholder={t('sample_address')}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                className="col-span-1 md:col-span-2"
                onChange={formik.handleChange}
                value={formik.values.address}
                error={
                  formik.errors.address &&
                  formik.touched.address &&
                  formik.errors.address
                }
              />
              <Input
                disabled={!editable}
                name="city"
                label={t('city')}
                type="text"
                required
                placeholder={t('sample_city')}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={formik.handleChange}
                value={formik.values.city}
                error={
                  formik.errors.city &&
                  formik.touched.city &&
                  formik.errors.city
                }
              />
              <Input
                disabled={!editable}
                name="zipcode"
                label={t('postal_code')}
                type="text"
                required
                placeholder={t('sample_postal_code')}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={formik.handleChange}
                value={formik.values.zipcode}
                error={
                  formik.errors.zipcode &&
                  formik.touched.zipcode &&
                  formik.errors.zipcode
                }
              />
              <Input
                name="latitude"
                label="Latitud"
                type="text"
                disabled
                className="hidden"
                required
                placeholder="18.3977264"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={formik.handleChange}
                value={formik.values.latitude}
                error={
                  formik.errors.latitude &&
                  formik.touched.latitude &&
                  formik.errors.latitude
                }
              />
              <Input
                name="longitude"
                label="Longitud"
                type="text"
                disabled
                className="hidden"
                required
                placeholder="-66.1040551"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={formik.handleChange}
                value={formik.values.longitude}
                error={
                  formik.errors.longitude &&
                  formik.touched.longitude &&
                  formik.errors.longitude
                }
              />
              <Textarea
                disabled={!editable}
                name="instructions"
                label={t('instructions')}
                className="col-span-1 md:col-span-2"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.instructions}
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="h-14 grid grid-cols-2">
        <button
          type="button"
          onClick={() => setLocation({ ...location, current: 'locations' })}
          className="h-14 flex items-center justify-center text-white text-xs font-medium bg-gray-600 hover:bg-gray-700 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-in-out"
        >
          {t('cancel')}
        </button>
        <button
          type="button"
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
          className="h-14 flex items-center justify-center text-white text-xs font-medium bg-accent hover:bg-opacity-50 outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-in-out"
        >
          {formik.isSubmitting ? <Spinner /> : `${t('save')}`}
        </button>
      </div>
    </div>
  );
}

export default Register;

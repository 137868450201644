import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState
} from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faCreditCardFront,
  faKey,
  faGift,
  faLocationArrow,
  faReceipt,
} from '@fortawesome/pro-duotone-svg-icons';
import { motion } from 'framer-motion';
import {
  notificationsAtom,
  orderDetailsModalAtom,
  selectedOrderAtom,
  locationAtom,
  userPaymentsAtom,
  orderHistoryAtom,
  tokenAtom,
  userAtom,
  selectedBookingAtom
} from '../../../atoms/Atoms';
import Settings from './shared-components/settings/Settings';
import Orders from './shared-components/orders/Orders';
import Password from './shared-components/password/Password';
// import HomeLink from '../../shared-components/homelink/HomeLink';
import Payments from './shared-components/payments/Payments';
import api from '../../../api/api';
import bookingApi from '../../../api/bookingApi';
import { H2 } from '../../shared-components/typography/Title';
import User from '../../shared-components/user/User';
import Locations from './shared-components/locations/Locations';
import Loyalty from './shared-components/loyalty/Loyalty';
import { Spinner } from '../../shared-components/Spinner';
import { businessesSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';
import Reservations from './shared-components/reservations/Reservations';
import Subscription from './shared-components/subscriptions/Subscriptions';

function Profile() {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const refreshStore = useResetRecoilState(businessesSelector);
  const setDetailsModal = useSetRecoilState(orderDetailsModalAtom);
  const { section, order } = useParams();
  const setUser = useSetRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const locations = useRecoilValue(locationAtom);
  const payments = useRecoilValue(userPaymentsAtom);
  const setSelectedBooking = useSetRecoilState(selectedBookingAtom);
  const selectedOrder = useRecoilValue(selectedOrderAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [orderHistory, setOrderHistory] = useRecoilState(orderHistoryAtom);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!section) {
      history.push('/profile/orders');
    }
  }, [section]);

  const getTitle = () => {
    switch (section) {
      case 'orders':
        return {
          title: `${t('orders')}`,
        };
      case 'settings':
        return {
          title: `${t('profile')}`,
        };
      case 'password':
        return {
          title: `${t('password')}`,
        };
      case 'payment':
        return {
          title: `${t('payment_methods')}`,
        };
      case 'locations':
        return {
          title: `${t('my_locations')}`,
        };
      case 'loyalty':
        return {
          title: `${t('loyalty')}`,
        };
      case 'reservations':
        return {
          title: `${t('reservations')}`,
        };
      case 'subscriptions':
        return {
          title: `${t('my_subscriptions')}`,
        };
      default:
        return `${t('profile')}`;
    }
  };

  const getOrder = (confirmationCode) => {
    setLoading(true);
    api
    .get(`orders/order/${confirmationCode}`)
    .then((response) => {
      setLoading(false);
      if (response.data.error) {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description:
            `${t('order_search_error')}`,
            error: true,
          },
        ]);
      } else {
        setDetailsModal(response.data.data);
      }
    })
    .catch((error) => {
      setLoading(false);
      setNotifications([
        ...notifications,
        {
          title: `${t('oops')}`,
          description: `${t('order_search_error')} - ${error.message}`,
          error: true,
        },
      ]);
    });
  };

  const getBooking = (bookingId) => {
    setLoading(true);
    bookingApi
      .get(`events/booking/${bookingId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('error_getting_booking')}`,
              error: true,
            },
          ]);
        } else {
          setSelectedBooking(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('error_getting_booking')} - ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  useEffect(() => {
    if (order) {
      if (!section || section === 'orders') {
        getOrder(order);
      } else if (section === 'reservations') {
        getBooking(order);
      }
    }
  }, [order]);

  useEffect(() => {
    if (selectedOrder) {
      getOrder(selectedOrder);
    }
  }, [selectedOrder]);

  useEffect(() => {
    api
      .get('users/orderInfo')
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: `${t('oops')}`,
              description:
              `${t('profile_load_error')}`,
              error: true,
            },
          ]);
        } else {
          setOrderHistory(response.data.data);
        }
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: `${t('oops')}`,
            description: `${t('profile_load_error')} - ${error.message}`,
            error: true,
          },
        ]);
      });
  }, []);

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="hidden md:flex flex-col flex-shrink-0 w-64 divide-y bg-white shadow-md">
        <div className="flex items-center h-24 text-sm tracking-tight p-4 space-x-2 bg-secondary">
          <User className="flex-row-reverse" />
        </div>
        <nav className="flex flex-col flex-1 overflow-auto p-4 space-y-2">
          {/* <NavLink
            to="/profile/loyalty"
            activeClassName="bg-gray-100"
            className="p-2 flex items-center space-x-2 font-medium 
            text-xs tracking-tight transition-colors 
            duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faGift} className="text-accent" />
            <div className="flex-1">{t('loyalty')}</div>
          </NavLink> */}
          <NavLink
            to="/profile/reservations"
            activeClassName="bg-gray-100"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight
              transition-colors duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faReceipt} className="text-accent" />
            <div className="flex-1">{t('reservations')}</div>
                {/* {orderHistory?.totalOrders > 0 && (
              <div className="text-xs flex items-center justify-center
              b g-accent text-white font-medium rounded-full w-5 h-5"
              >
                {orderHistory?.totalOrders}
              </div>
            )} */}
          </NavLink>
          <NavLink
            to="/profile/orders"
            activeClassName="bg-gray-100"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight
              transition-colors duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faReceipt} className="text-accent" />
            <div className="flex-1">{t('orders')}</div>
            {orderHistory?.totalOrders > 0 && (
              <div className="text-xs flex items-center justify-center bg-accent text-white font-medium rounded-full w-5 h-5">
                {orderHistory?.totalOrders}
              </div>
            )}
          </NavLink>
          <NavLink
            to="/profile/subscriptions"
            activeClassName="bg-gray-100"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight transition-colors duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faReceipt} className="text-accent" />
            <div className="flex-1">{t('my_subscriptions')}</div>
          </NavLink>
          {/* <NavLink
            to="/profile/payment"
            activeClassName="bg-gray-200"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight 
            transition-colors 
            duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon
              icon={faCreditCardFront}
              className="text-accent"
            />
            <span className="flex-1">{t('payment_methods')}</span>
            {payments?.cards?.length > 0 && (
              <div className="text-xs flex items-center justify-center bg-accent 
              text-white font-medium rounded-full w-5 h-5">
                {payments?.cards?.length}
              </div>
            )}
          </NavLink> */}
          {/* <NavLink
            to="/profile/locations"
            activeClassName="bg-gray-200"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight
              transition-colors duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faLocationArrow} className="text-accent" />
            <span className="flex-1">{t('my_locations')}</span>
            {locations?.locations?.length > 0 && (
              <div className="text-xs flex items-center justify-center bg-accent
                text-white font-medium rounded-full w-5 h-5">
                {locations?.locations?.length}
              </div>
            )}
          </NavLink> */}
          <NavLink
            to="/profile/settings"
            activeClassName="bg-gray-200"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight transition-colors duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faCog} className="text-accent" />
            <span>{t('profile')}</span>
          </NavLink>
          <NavLink
            to="/profile/password"
            activeClassName="bg-gray-200"
            className="p-2 flex items-center space-x-2 font-medium text-xs tracking-tight transition-colors duration-200 ease-linear rounded-md hover:bg-gray-100"
          >
            <FontAwesomeIcon icon={faKey} className="text-accent" />
            <span>{t('password')}</span>
          </NavLink>
        </nav>
        <button
          type="button"
          className="flex items-center justify-center space-x-2 text-accent font-medium bg-gray-50 hover:bg-gray-100 h-14 px-4 text-xs ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-linear"
          onClick={() => {
            setUser(null);
            setToken(null);
            refreshStore();
            window.localStorage.removeItem(`${envConfig.StorageKey}-user`);
            window.localStorage.removeItem(`${envConfig.StorageKey}-state`);
            window.localStorage.removeItem(`${envConfig.StorageKey}-card`);
            window.localStorage.removeItem(`${envConfig.StorageKey}-token`);
          }}
        >
          <div>{t('signout')}</div>
        </button>
      </div>
      <div className="flex flex-col flex-1 overflow-y-auto">
        <motion.div
          initial={{
            x: 10,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          exit={{
            y: 10,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          className="flex flex-col flex-shrink-0 justify-center h-auto md:h-24 px-4 py-4 md:py-0"
        >
          {/* <HomeLink /> */}
          <H2 text={getTitle().title} />
        </motion.div>
        <motion.div
          initial={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
          exit={{
            y: 100,
            opacity: 0,
            transition: {
              duration: 0.5,
              ease: [0.43, 0.13, 0.23, 0.96],
            },
          }}
        >
          {section === 'orders' && <Orders />}
          {section === 'settings' && <Settings />}
          {section === 'password' && <Password />}
          {section === 'payment' && <Payments />}
          {section === 'locations' && <Locations />}
          {section === 'loyalty' && <Loyalty />}
          {section === 'reservations' && <Reservations /> }
          {section === 'subscriptions' && <Subscription /> }

        </motion.div>
        {loading && (
          <div className="loading-screen">
            <Spinner big />
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';

function ItemTimeConstraintWarning({ product }) {
  const hasConstraint = product?.timeConstraint?.hourDelay > 0 ||
    product?.timeConstraint?.minuteDelay > 0 ||
    !product?.timeConstraint?.availableOnMondays ||
    !product?.timeConstraint?.availableOnTuesdays ||
    !product?.timeConstraint?.availableOnWednesdays ||
    !product?.timeConstraint?.availableOnThursdays ||
    !product?.timeConstraint?.availableOnFridays ||
    !product?.timeConstraint?.availableOnSaturdays ||
    !product?.timeConstraint?.availableOnSundays ||
    product?.timeConstraint?.limitDate;
  if (!hasConstraint || !product.timeConstraint) {
    return (
      null
    );
  }

  return (
    <FontAwesomeIcon
      icon={faClock}
      className="absolute text-xs text-accent"
    />
  );
}

export default ItemTimeConstraintWarning;

import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBadgeCheck,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/pro-duotone-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { notificationsAtom } from '../../atoms/Atoms';

function Notifications() {
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications.length) {
        const filter = _.filter(notifications, (e) => e !== notifications[0]);
        setNotifications(filter);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [notifications]);

  return (
    <div className="fixed z-50 inset-0 flex flex-col items-end justify-start p-4 pointer-events-none">
      <AnimatePresence>
        {_.map(notifications, (e, index) => (
          <motion.div
            key={index.toString()}
            positionTransition
            initial={{ opacity: 0, y: -25, scale: 0.3 }}
            animate={{
              opacity: 1,
              y: 0,
              scale: 1,
              transition: { type: 'spring' },
            }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            className="mb-2 divide-y max-w-sm w-full rounded-xl pointer-events-auto bg-gray-800 overflow-hidden"
          >
            <div className="flex items-start p-4 text-xs space-x-4">
              <div
                className={`${
                  e.error
                    ? ' text-white bg-red-600'
                    : 'text-white bg-green-600'
                } flex items-center justify-center flex-shrink-0 text-white flex-shrink-0 w-8 h-8 rounded-full`}
              >
                <FontAwesomeIcon
                  icon={e.error ? faExclamationTriangle : faBadgeCheck}
                />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5 overflow-hidden">
                <p className="font-medium flex-shrink text-sm text-white">
                  {e.title}
                </p>
                <div className="mt-1 text-gray-400">{e.description}</div>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  onClick={() => {
                    const filter = _.filter(notifications, (n) => n !== e);
                    setNotifications(filter);
                  }}
                  className="text-white hover:text-gray-500 focus:outline-none ring-0 focus:ring-0"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
}

export default Notifications;

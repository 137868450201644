import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBadgeCheck,
  faChevronRight,
  faStar,
} from '@fortawesome/pro-duotone-svg-icons';
import { useHistory } from 'react-router-dom';
import {
  locationAtom,
  notificationsAtom,
  orderAtom,
  serviceAtom,
  storeAtom,
  tokenAtom,
} from '../../../../../../atoms/Atoms';
import EmptyLocations from '../../../../illustrations/EmptyLocations';
import { H2, H3 } from '../../../../typography/Title';
import Subtitle from '../../../../typography/Subtitle';
import Button from '../../../../buttons/Button';
import Loader from '../../../../loader/Loader';
import Delivery from '../../../../illustrations/Delivery';
import api from '../../../../../../api/api';
import envConfig from '../../../../../../envConfig';

function MediumDevicesList() {
  const { t } = useTranslation();
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);
  const setService = useSetRecoilState(serviceAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [state, setState] = useState(null);

  const getBusiness = (e) => {
    setLocations({
      ...locations,
      loadingBusinesses: true,
      businesses: [],
    });
    api
      .get(`businesses/fast/${e.id}`)
      .then((response) => {
        if (response.data.error) {
          setLocations({
            ...locations,
            loadingBusinesses: false,
            businesses: [],
          });
          setNotifications([
            ...notifications,
            {
              title: t('oops'),
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setLocations({
            ...locations,
            loadingBusinesses: false,
            businesses: response.data.data.results,
          });
        }
      })
      .catch((error) => {
        setLocations({
          ...locations,
          loadingBusinesses: false,
          businesses: [],
        });
        setNotifications([
          ...notifications,
          {
            title: t('oops'),
            description: `${t('store_load_error')} ${error.message}`,
            error: true,
          },
        ]);
      });
  };

  const handleAlert = async (e) => {
    await setStore(e);
    await setService(1);
    await setOrder([]);
    await setLocations({
      ...locations,
      alert: null,
      page: 0,
      modal: false,
      location: state,
    });
    await localStorage.setItem(`${envConfig.StorageKey}-store`, JSON.stringify(e));
    await localStorage.setItem(`${envConfig.StorageKey}-location`, JSON.stringify(state));
    await localStorage.setItem(`${envConfig.StorageKey}-service`, '1');
    await localStorage.removeItem(`${envConfig.StorageKey}-order`);
    await history.push(`/stores/${e.id}`);
  };

  if (locations.loading) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <Loader color="bg-gray-700" />
      </div>
    );
  }

  if (!locations.loading && locations.locations.length === 0) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <EmptyLocations className="w-64 h-44" />
        <div className="text-center p-4">
          <H2 text={t('no_locations')} />
          <Subtitle text={t('no_locations_prompt')} />
        </div>
        <Button
          text={t('register')}
          textColor="text-white"
          className="bg-accent hover:bg-accent w-24"
          onClick={() => setLocations({ ...locations, current: 'register' })}
        />
      </div>
    );
  }

  return (
    <div className="w-full grid grid-cols-2 divide-x divide-gray-100 text-sm overflow-hidden">
      <motion.div
        variants={{
          hide: {
            opacity: 0,
          },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
        initial="hide"
        animate="show"
        className="flex flex-col flex-1 overflow-y-auto divide-y divide-gray-100"
      >
        {_.map(locations.locations, (l) => (
          <motion.button
            type="button"
            key={l.id}
            variants={{
              hide: { opacity: 0 },
              show: {
                opacity: 1,
              },
            }}
            className={`${state?.id === l.id && 'sticky top-0 shadow-md'}
              hover:bg-gray-50 h-16 flex items-center text-left w-full p-4 space-x-4 transition duration-500 ease-in-out bg-white
            `}
            onClick={() => {
              getBusiness(l);
              setState(l);
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              className={
                state?.id === l.id ? 'text-amber-400' : 'text-gray-300'
              }
            />
            <div className="flex-1 text-xs truncate">
              <div className="truncate font-medium">{l.name}</div>
              <div className="text-gray-400 truncate">{l.addressLine}</div>
            </div>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="text-xs text-gray-400"
            />
          </motion.button>
        ))}
      </motion.div>
      <div className="flex flex-col flex-1 overflow-hidden divide-y divide-gray-100">
        {/* No address selected */}
        {!state && (
          <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight bg-white p-4 text-center">
            <Delivery className="h-16 mb-4" />
            <H3 text="Seleccionar Localización" />
            <div className="text-center text-gray-400">
              {t('select_a_location')}
            </div>
          </div>
        )}
        {/* Address selected and loading */}
        {locations.loadingBusinesses && (
          <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight bg-white p-4 text-center">
            <Loader />
          </div>
        )}
        {/* Address selected, not loading and no results */}
        {state &&
          !locations.loadingBusinesses &&
          locations.businesses.length === 0 && (
            <div className="flex flex-col flex-1 items-center justify-center text-xs tracking-tight bg-white p-4 text-center">
              <Delivery className="h-16 mb-4" />
              <H3 text="Sin Resultados" />
              <div className="text-center text-gray-400">
                {t('no_valid_location')}
              </div>
            </div>
          )}
        {/* Address selected, not loading and results */}
        {state &&
          !locations.loadingBusinesses &&
          locations.businesses.length > 0 && (
            <motion.div
              variants={{
                hide: {
                  opacity: 0,
                },
                show: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.1,
                  },
                },
              }}
              initial="hide"
              animate="show"
              className="relative flex flex-col flex-1 text-sm tracking-tight overflow-y-auto divide-y divide-gray-100"
            >
              {locations.alert && (
                <motion.div
                  initial={{
                    y: '100%',
                  }}
                  animate={{
                    y: 0,
                    transition: {
                      delay: 0.5,
                    },
                  }}
                  exit={{
                    y: '100%',
                  }}
                  className="inset-x-0 absolute z-10 flex flex-col items-center justify-center text-center bottom-0 bg-gray-900 bg-opacity-95 h-1/2 text-white p-4 rounded-br-xl"
                >
                  <div className="font-semibold mb-2">{t('oops')}</div>
                  <div className="text-xs">
                    {t('change_store_prompt')}
                  </div>
                  <div className="space-x-2 mt-4">
                    <Button
                      text={t('cancel')}
                      onClick={() => {
                        setLocations({ ...locations, alert: null });
                      }}
                      className="bg-gray-800 w-20 border border-gray-700 hover:border-gray-600"
                    />
                    <Button
                      text={t('accept')}
                      onClick={() => handleAlert(locations.alert)}
                      className="bg-accent hover:bg-accent w-20"
                    />
                  </div>
                </motion.div>
              )}
              {_.map(locations.businesses, (e) => (
                <motion.button
                  type="button"
                  key={e.id}
                  variants={{
                    hide: { opacity: 0 },
                    show: {
                      opacity: 1,
                    },
                  }}
                  onClick={async () => {
                    if (store && order.length > 0 && store.id !== e.id) {
                      await setLocations({ ...locations, alert: e });
                    } else {
                      await setStore(e);
                      await setService(1);
                      await setLocations({
                        ...locations,
                        modal: false,
                        page: 0,
                        location: state,
                      });
                      await localStorage.setItem(`${envConfig.StorageKey}-service`, '1');
                      await localStorage.setItem(
                        `${envConfig.StorageKey}-location`,
                        JSON.stringify(state)
                      );
                      await localStorage.setItem(`${envConfig.StorageKey}-store`, JSON.stringify(e));
                      if (!locations.preventRedirect) {
                        await history.push(`/stores/${e.id}`);
                      }
                    }
                  }}
                  className={`${
                    store && store.id === e.id && 'sticky shadow-md'
                  } flex items-center bg-white hover:bg-gray-50 h-16 lex items-center text-xs outline-none p-4 space-x-4 focus:outline-none ring-0 focus:ring-0 transition duration-200 ease-in-out`}
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className={
                      store?.id === e.id ? 'text-amber-400' : 'text-gray-300'
                    }
                  />
                  <div className="flex-1 text-left truncate">
                    <div className="font-medium truncate">{e.name}</div>
                    <div className="text-gray-400 truncate">
                      {t('order_minimum')}: ${e.minimumDeliveryOrderAmount.toFixed(2)}
                      /{t('service_cost')}: $
                      {e.locations[0].deliveryFee.toFixed(2)}
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    className="text-xs text-gray-400"
                  />
                </motion.button>
              ))}
            </motion.div>
          )}
      </div>
    </div>
  );
}

export default MediumDevicesList;

import React, { useEffect } from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { departmentsAtom, storeAtom, catalogAtom, envAtom } from '../../../atoms/Atoms';
import Breadcrumb from './shared-components/Breadcrumb';
import Carousel from './shared-components/carousel/Carousel';
import Loader from '../../shared-components/loader/Loader';
// import Banner from '../../shared-components/banners/Banner';
import api from '../../../api/api';
import envConfig from '../../../envConfig';
import Store1 from './shared-components/Store1';
import Store2 from './shared-components/Store2';
import Store3 from './shared-components/Store3';
import Store10 from './shared-components/Store10';

function Store() {
  if (envConfig.StoreVersion === '1') {
    return (
      <Store1 />
    )
  } 
  if (envConfig.StoreVersion === '2') {
    return (
      <Store2 />
    )
  } 
  if (envConfig.StoreVersion === '3') {
    return (
      <Store3 />
    )
  } 
  if (envConfig.StoreVersion === '10') {
    return (
      <Store10 />
    )
  } 
  return (
    <Store1 />
  )
} 

export default Store;

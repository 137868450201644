import React, { useState, useEffect, useRef } from 'react';
import { useMount, useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import is from 'is_js';
import { useHistory } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeDollar, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import Payment from './Payment';
import PaymentMethod from './PaymentMethod';
import Order from './Order';
import { checkoutSelector, minimumSelector } from '../../../../atoms/Selectors';
import Hours from './Hours';
import { thousand } from '../../../../functions';
import PromoCode from './PromoCode';
import {
  contactAtom,
  orderAtom,
  notificationsAtom,
  orderSuccess,
  paymentAtom,
  userPaymentsAtom,
  referenceOrderAtom,
  storeAtom,
  successMessageAtom,
  tokenAtom,
  timeAtom,
  userAtom,
  locationAtom,
  serviceAtom,
  isAdditionalItemOrderAtom,
  promoCodeAtom
} from '../../../../atoms/Atoms';
import Notification from './Notification';
import Confirmation from './Confirmation';
import Contact from './Contact';
import api from '../../../../api/api';
import { H1 } from '../../../shared-components/typography/Title';
import Loader from '../../../shared-components/loader/Loader';
import ExcludeOrder from './ExcludeOrder';
import envConfig from '../../../../envConfig';
import Summary from './Summary';
import { backdropVariants } from '../../../../utils';
import Button from '../../../shared-components/buttons/Button';
import useAnalyticsEventTracker from '../../../../useAnalyticsEventTracker';

function Checkout() {
  const eventTracker = useAnalyticsEventTracker('User Actions');
  const ref = useRef();
  const history = useHistory();
  const checkout = useRecoilValue(checkoutSelector);
  const store = useRecoilValue(storeAtom);
  const contact = useRecoilValue(contactAtom);
  const user = useRecoilValue(userAtom);
  const token = useRecoilValue(tokenAtom);
  const setSuccess = useSetRecoilState(orderSuccess);
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom)
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [referenceOrder, setReferenceOrder] = useRecoilState(
    referenceOrderAtom
  );
  const minimum = useRecoilValue(minimumSelector);
  const [submitting, setSubmitting] = useState(false);
  const setMessage = useSetRecoilState(successMessageAtom);
  const setOrder = useSetRecoilState(orderAtom);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [time, setTime] = useRecoilState(timeAtom);
  const service = useRecoilValue(serviceAtom);
  const isAdditionalItemOrder = useRecoilValue(isAdditionalItemOrderAtom);
  const [needsDeliveryLocation, setNeedsDeliveryLocation] = useState(false);
  const { t } = useTranslation();
  const [showSecondOrderAlertBeforeSubmitPopup,
    setShowSecondOrderAlertBeforeSubmitPopup]
    = useState(false);
  const [showSecondOrderAlertAfterSubmitPopup,
    setShowSecondOrderAlertAfterSubmitPopup]
    = useState(false);

  console.log(store);

  const sendOrder = () => {
    setSubmitting(true);
    eventTracker('Submit Order', '');
    const mappedItems = _.map(checkout.order, (p) => ({
      businessItemId: p.id,
      amount: p.amount,
      price: p.activePrice,
      brand: p.brand,
      name: p.name,
      description: p.description,
      isEBT: p.isEBT,
      itemId: p.id,
      acceptsSubstitute: true,
      hasRandomWeight: p.hasRandomWeight,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      itemImage: p.imageUrl,
      specialInstruction: p.specialInstruction
    }));
    // console.log(mappedItems);

    const orderObject = {
      OGuid: uuid(),
      AppVersion: '1.0',
      PaymentType: payment.card.last4 ? 0 : payment.card.paymentType,
      OrderLat: null,
      OrderLon: null,
      BusinessId: store.id,
      CardId: payment.card.last4 ? payment.card.id : null,
      PaymentOptionId: payment.card.last4 ? null : payment.card.id,
      DeliveryLocationId: service === 1 ? location?.location?.id : null,
      DeliveryTotal: service === 1 ? store.locations[0].deliveryFee : 0,
      SubTotal: parseFloat(checkout.subtotal),
      MunicipalTax: parseFloat(checkout.muniTax),
      StateTax: parseFloat(checkout.stateTax),
      TaxTotal: parseFloat(checkout.muniTax) + parseFloat(checkout.stateTax),
      ProcessingFee:
        service === 0 ? parseFloat(store.locations[0].pickupFee) : 0,
      OrderTotal: parseFloat(checkout.total),
      LocationId: store.locations[0].id,
      PickUpName: `${contact.name} ${contact.phone}`,
      SpecialInstruction: contact.instructions,
      Type: service,
      items: mappedItems,
      DesiredDateTime: checkout.time,
      StartSlotTime: checkout.startSlotTime,
      EndSlotTime: checkout.endSlotTime,
      BypassMinimum: referenceOrder !== null || checkout.hasMinimum,
      IsAdditionalItemOrder: referenceOrder !== null && isAdditionalItemOrder,
      ReferenceOrderId: referenceOrder?.id,
      CouponId: promoCode?.id
    };
    // console.log(JSON.stringify(orderObject));
    api
      .post('orders', orderObject)
      .then((response) => {
        if (response.data.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error_submitting_order'),
              description: response.data.error,
              error: true,
            },
          ]);
        } else {
          setMessage(response.data);
          setSuccess(true);
          if (referenceOrder) {
            setReferenceOrder(null);
          }
          if (
            orderObject.PaymentOptionId &&
            !payment.card.allowNonFoodProducts &&
            _.filter(checkout.allItems, (product) => !product.isEBT)
          ) {
            setReferenceOrder(response.data.data);
            setOrder(_.filter(checkout.allItems, (product) => !product.isEBT));
            if (envConfig.ShowEbtAfterPopup === 'true') {
              setShowSecondOrderAlertAfterSubmitPopup(true);
            }
          } else {
            setOrder([]);
          }
          localStorage.removeItem(`${envConfig.StorageKey}-order`);
          const tempPayment = JSON.parse(JSON.stringify(payment));
          tempPayment.card = null;
          localStorage.removeItem(`${envConfig.StorageKey}-card`);
          localStorage.removeItem(`${envConfig.StorageKey}-payment`);
          setPayment(tempPayment);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setNotifications([
          ...notifications,
          {
            title: t('error_submitting_order'),
            description: error.message,
            error: true,
          },
        ]);
        setSubmitting(false);
      });
  };

  useMount(() => {
    if (document.readyState === 'complete') {
      if (!user) {
        history.push('/login', { from: 'checkout' });
      }
    }
  });

  useEffect(() => {
    if (service === 1 && location.location === null) {
      setNeedsDeliveryLocation(true);
      setLocation({ ...location, modal: true, preventRedirect: true });
    }
    setReferenceOrder(null);
  }, []);

  useEffect(() => {
    if (promoCode) {
      if (promoCode.minimumAmount > checkout.subtotal) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_minimum').format(promoCode.minimumAmount.toFixed(2)),
            error: true,
          },
        ]);
      } else if (promoCode.appliesTo === 0 && service !== 0) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_pickup'),
            error: true,
          },
        ]);
      } else if (promoCode.appliesTo === 1 && service !== 1) {
        setPromoCode(null);
        setNotifications([
          ...notifications,
          {
            title: t('invalid_promo_code_title'),
            description:
              t('invalid_promo_code_delivery'),
            error: true,
          },
        ]);
      }
    }
  }, [checkout, service]);

  useEffect(() => {
    if (payment?.card && envConfig.ShowEbtBeforePopup === 'true' && checkout?.excludedItems?.length > 0) {
      setShowSecondOrderAlertBeforeSubmitPopup(true);
    }
  }, [payment?.card?.last4, payment?.card?.id]);

  useClickAway(ref, () => {
    if (showSecondOrderAlertBeforeSubmitPopup) {
      setShowSecondOrderAlertBeforeSubmitPopup(false);
    }
    if (showSecondOrderAlertAfterSubmitPopup) {
      setShowSecondOrderAlertAfterSubmitPopup(false);
    }
  });

  return (
    <>
      <div className="hidden sticky top-0 bg-white p-4 border-b border-gray-200 text-right">
        <H1 text={t('your_order')} />
        <div className="text-sm font-semibold">
          {t('subtotal')}: ${thousand(checkout.subtotal)}
        </div>
        <div className="text-xs tracking-tight">
          {t('order_service')}: {service === 0 ? t('pickup') : t('delivery')}
        </div>
        {service === 1 && (
          <>
            <div className="text-xs tracking-tight">
              {t('delivery_at')}: {location.location?.name}
            </div>
            <div className="text-xs tracking-tight">
              {location.location?.addressLine}
            </div>
          </>
        )}
        {service === 0 && (
          <div className="text-xs tracking-tight">
            {t('pickup_at')}: {store?.name}
          </div>
        )}
        {!referenceOrder && (
          <div className="text-xs font-semibold text-rose-500">
            {t('minimum_purchase').format(minimum?.toFixed(2))}
          </div>
        )}
      </div>
      <div className="md:flex flex-1 overflow-y-auto md:overflow-y-hidden">
        <PaymentMethod />
        <Confirmation />
        <div className="flex bg-white flex-col md:flex-row flex-1">
          <div className="p-4 w-full md:w-1/4 overflow-y-auto flex-shrink-0">
            <h1 className="text-2xl font-medium text-gray-900">{t('shopping_cart')}</h1>
            <Order />
          </div>
          <motion.div
            initial={{
              x: 50,
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            animate={{
              x: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            exit={{
              y: 50,
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.43, 0.13, 0.23, 0.96],
              },
            }}
            className="flex-2 overflow-y-auto w-full md:bg-white border-t border-l md:rounded-tl-xl p-4 space-y-4 md:mt-4"
          >
            <Summary />
            <PromoCode />
            <Hours />
            <Contact />
            <Payment />
            <AnimatePresence>
              {!is.all.existy(
                token,
                user,
                payment.card,
                checkout.time,
                checkout.order.length > 0,
                store,
                checkout.hasMinimum
              ) && <Notification />}
            </AnimatePresence>
            <div className="flex items-center grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
              <div className="text-sm text-right md:text-left">
                <div className=" text-gray-400">
                  {t('subtotal')}:{' '}
                  <span className="text-accent font-medium">
                    ${thousand(checkout.subtotal)}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('municipal_tax')}:{' '}
                  <span className="text-accent font-medium">
                    ${checkout.muniTax}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('state_tax')}:{' '}
                  <span className="text-accent font-medium">
                    ${checkout.stateTax}
                  </span>
                </div>
                <div className=" text-gray-400">
                  {t('order_service_charge')}:{' '}
                  <span className="text-accent font-medium">
                    ${checkout.serviceCost?.toFixed(2)}
                  </span>
                </div>
                {promoCode?.id && (
                  <div className=" text-gray-400">
                    {t('discount')}:{' '}
                    <span className="text-accent font-medium">
                      ${checkout.discountTotal?.toFixed(2)}
                    </span>
                  </div>
                )}
              </div>
              <button
                type="button"
                disabled={
                  is.any.falsy(
                    token,
                    user,
                    payment.card,
                    checkout.time,
                    checkout.order.length > 0,
                    store,
                    checkout.hasMinimum
                  ) || submitting
                }
                className="flex flex-1 items-center justify-between p-4 hover:shadow-md rounded-xl text-sm font-semibold tracking-tight text-white ring-2 ring-accent ring-offset-2 bg-accent hover:bg-accent disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition-colors duration-500 ease-in-out"
                onClick={() => sendOrder()}
              >
                <motion.div
                  whileHover={{ scale: 1.2, rotate: 360 }}
                  className="text-xs flex items-center justify-center h-6 w-6 bg-accent rounded-md"
                >
                  {checkout.order.length}
                </motion.div>
                <div className="flex items-center justify-center w-1/2 text-center">
                  {submitting ? (
                    <Loader color="bg-white" />
                  ) : (
                    <span>{t('make_order')}</span>
                  )}
                </div>
                <div className="flex items-center space-x-1">
                  <FontAwesomeIcon icon={faBadgeDollar} className="text-2xl" />
                  <div className="text-xs">{thousand(checkout.total)}</div>
                </div>
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      <AnimatePresence>
        {showSecondOrderAlertBeforeSubmitPopup && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                <div
                  ref={ref}
                  className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                >
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      onClick={() => setShowSecondOrderAlertBeforeSubmitPopup(false)}
                      className="hover:opacity-75 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                    </button>
                  </div>
                  <div className="flex space-x-4">
                    <div className="font-light tracking-tight text-md md:text-lg">
                      {t('second_order_warning_before')}
                    </div>
                  </div>
                  <div className="flex items-center justify-end mt-4 space-x-2">
                    <Button
                      text={t('accept')}
                      onClick={() => setShowSecondOrderAlertBeforeSubmitPopup(false)}
                      className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showSecondOrderAlertAfterSubmitPopup && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                <div
                  ref={ref}
                  className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                >
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      onClick={() => setShowSecondOrderAlertAfterSubmitPopup(false)}
                      className="hover:opacity-75 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                    </button>
                  </div>
                  <div className="flex space-x-4">
                    <div className="font-light tracking-tight text-md md:text-lg">
                      {t('second_order_warning_after')}
                    </div>
                  </div>
                  <div className="flex items-center justify-end mt-4 space-x-2">
                    <Button
                      text={t('accept')}
                      onClick={() => setShowSecondOrderAlertAfterSubmitPopup(false)}
                      className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {payment?.paypalUrl && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ zIndex: 1001 }}
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
          >
            <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                <div
                  ref={ref}
                  className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                >
                  <div className="flex items-center justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        setPayment({
                          ...payment,
                          paypalUrl: null
                        });
                      }}
                      className="hover:opacity-75 outline-none focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                    </button>
                  </div>
                  <div className="flex items-center justify-end mt-4 space-x-2">
                    <Button
                      text={t('click_to_redirect_to_paypal')}
                      onClick={() => {
                        const newWindow = window.open(
                          payment.paypalUrl,
                          '_blank',
                          'noopener,noreferrer'
                        );
                        if (newWindow) newWindow.opener = null;
                        setPayment({
                          ...payment,
                          paypalUrl: null
                        });
                      }}
                      className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Checkout;

import React, { useEffect, useRef, useState } from 'react';
import { Braintree } from 'react-braintree-fields';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import api from '../../../../../../api/api';
import { getType } from '../../../../../../functions';
import { backdropVariants, modalVariants } from '../../../../../../utils';
import {
  notificationsAtom,
  userPaymentsAtom,
} from '../../../../../../atoms/Atoms';
import Input from '../../../../../shared-components/form/Input';
import CleaveInput from '../../../../../shared-components/form/CleaveInput';
import Button from '../../../../../shared-components/buttons/Button';
import Form from '../../../../../shared-components/form/Form';
import envConfig from '../../../../../../envConfig';
import BrainTreeInput from './options/BrainTreeInput';
import unknownImg from '../../../../../../assets/img/credit_cards/unknown.svg';
import amex from '../../../../../../assets/img/credit_cards/amex.svg';
import diners from '../../../../../../assets/img/credit_cards/diners.svg';
import discover from '../../../../../../assets/img/credit_cards/discover.svg';
import mastercard from '../../../../../../assets/img/credit_cards/mastercard.svg';
import visa from '../../../../../../assets/img/credit_cards/visa.svg';

function BrainTreeModal() {
  const { t } = useTranslation();
  const ref = useRef();
  const brainTree = useRef();
  const [cardType, setCardType] = useState({
    brand: t('status_unknown'),
    brandImg: unknownImg
  });
  const [tokenRef, setTokenRef] = useState(null);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  function onCardTypeChange(cards) {
    if (cards.cards.length === 1) {
      let img = unknownImg;
      if (cards.cards[0].type === 'visa') {
        img = visa;
      } else if (cards.cards[0].type === 'master-card') {
        img = mastercard;
      } else if (cards.cards[0].type === 'american-express') {
        img = amex;
      } else if (cards.cards[0].type === 'diners-club') {
        img = diners;
      } else if (cards.cards[0].type === 'discover') {
        img = discover;
      }
      setCardType({
        brand: cards.cards[0].niceType,
        brandImg: img
      });
    } else {
      setCardType({
        brand: t('status_unknown'),
        brandImg: unknownImg
      });
    }
  }

  return (
    <AnimatePresence>
      {payment.modal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-center justify-center"
        >
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="text-gray-800 antialiased justify-center items-center flex overflow-hidden fixed inset-0 z-50"
          >
            <div className="relative max-h-full w-98 px-4 overflow-y-auto">
              <div
                ref={ref}
                className="rounded-xl space-y-4 shadow-lg relative flex flex-col w-full bg-white overflow-y-auto p-6"
              >
                <div className="flex items-center justify-between">
                  <div className="font-semibold tracking-tight">
                    {t('payment_method')}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setPayment({ ...payment, modal: false, edit: null });
                      // Reset form values if needed
                    }}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <Braintree
                  ref={brainTree}
                  authorization={envConfig.BrainTreeTokenizationKey}
                  onAuthorizationSuccess={(obj) => {
                    // Left Empty for now
                  }}
                  onDataCollectorInstanceReady={(obj) => {
                    // Left Empty for now
                  }}
                  onCardTypeChange={(obj) => onCardTypeChange(obj)}
                  getTokenRef={(tref) => setTokenRef(() => tref)}
                >
                  {cardType && (
                    <div className="flex justify-between items-center">
                      <div className="h-8 w-10 bg-gray-100 bg-opacity-25 rounded" />
                      <img
                        alt={cardType?.brand}
                        src={cardType?.brandImg}
                        className="h-8 w-10"
                      />
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-xs py-4 w-full">
                    <BrainTreeInput
                      label={t('card_number')}
                      type="number"
                      placeholder="**** **** **** 3742"
                      required
                      className="col-span-1 md:col-span-2"
                    />
                    <BrainTreeInput
                      label={t('card_name')}
                      placeholder="Juan del Pueblo"
                      type="cardholderName"
                      className="col-span-1 md:col-span-2"
                      required
                    />

                    <BrainTreeInput
                      label={t('card_expiration_date')}
                      required
                      type="expirationMonth"
                      placeholder="MM"
                    />
                    <BrainTreeInput
                      label={t('card_expiration_date')}
                      required
                      type="expirationYear"
                      placeholder="YYYY"
                      options={{ maskInput: { character: 'X' } }}
                    />
                    
                    <BrainTreeInput
                      label={t('card_cvv')}
                      placeholder="****"
                      type="cvv"
                      required
                    />
                    <BrainTreeInput
                      label={t('card_postal_code')}
                      placeholder="00907..."
                      type="postalCode"
                      required
                    />
                  </div>
                  <Button
                    text={t('save')}
                    textColor="text-white"
                    onClick={() => {
                      brainTree.current.tokenize()
                      .then((tRes) => {
                        console.log('tokenized card', tRes);
                        const country = tRes.binData.countryOfIssuance;
                        const isDebit = tRes.binData.debit === 'Yes';
                        const bank = tRes.binData.issuingBank;
                        const healthcare = tRes.binData.healthcare === 'Yes';
                        const last4 = tRes.details.lastFour;
                        const expMonth = tRes.details.expirationMonth;
                        const expYear = tRes.details.expirationYear;
                        const nameOnCard = tRes.details.cardholderName;
                        const brand = tRes.details.cardType;
                        const tokenizedCard = tRes.nonce;
                        api
                          .post(
                            'users/me/cards',
                            {
                              ExpMonth: expMonth,
                              ExpYear: expYear,
                              Name: nameOnCard,
                              Number: last4,
                              Country: country,
                              Organization: bank,
                              IsDebit: isDebit,
                              Token: tokenizedCard,
                              Type: 20,
                              Brand: brand,
                              AddressZip: '00000'
                            }
                          )
                          .then((response) => {
                            if (response.data.error) {
                              setNotifications([
                                ...notifications,
                                {
                                  title: `${t('card_not_added')}`,
                                  description:
                                  `${t('card_error')}`,
                                  error: true,
                                },
                              ]);
                            } else {
                              setPayment({
                                ...payment,
                                modal: false,
                                reload: true,
                                cards: [...payment.cards, response.data.data],
                              });
                              setNotifications([
                                ...notifications,
                                {
                                  title: `${t('card_added')}`,
                                  description:
                                    `${t('card_success')}`,
                                  error: false,
                                },
                              ]);
                            }
                          })
                          .catch((error) => {
                            setNotifications([
                              ...notifications,
                              {
                                title: `${t('card_not_added')}`,
                                description: `${t('card_error')} - ${error.message}`,
                                error: true,
                              },
                            ]);
                          });
                      })
                      .catch((err) => {
                        let message = 'unknown_error';
                        if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
                         message = 'must_fill_entire_form';
                        } else if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
                         message = 'some_fields_are_invalid';
                        }
                        setNotifications([
                           ...notifications,
                           {
                             title: `${t('card_not_added')}`,
                             description: t(message),
                             error: true,
                           },
                         ]);
                     });
                    }}
                    loaderColor="bg-white"
                    className="bg-accent hover:bg-accent w-20"
                  />
                </Braintree>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default BrainTreeModal;

import React, { useRef, useEffect, useState } from 'react';
import { useMount, useClickAway } from 'react-use';
import { useRecoilState, useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBagsShopping,
    faBus,
  faStar,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  alertAtom,
  locationAtom,
  orderAtom,
  serviceAtom,
  storeAtom,
  serviceSelectionAtom,
  userAtom,
} from '../../../../atoms/Atoms';
import { backdropVariants, sidebarVariants, modalVariants } from '../../../../utils';
import { businessesSelector } from '../../../../atoms/Selectors';
import Loader from '../../loader/Loader';
import envConfig from '../../../../envConfig';
import LocationsList from './LocationsList';
import UserLocations from './UserLocations';
import SmallDevicesList from './SmallDevicesList';

function ServicesDrawer() {
  const [user, setUser] = useRecoilState(userAtom);
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const [open, setOpen] = useRecoilState(serviceSelectionAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const [service, setService] = useRecoilState(serviceAtom);
  const [tempService, setTempService] = useState(service);
  const setAlert = useSetRecoilState(alertAtom);
  const stores = useRecoilValueLoadable(businessesSelector);
  const [location, setLocation] = useRecoilState(locationAtom);
  const [chooseStore, setChooseStore] = useState(false);
  const [chooseLocation, setChooseLocation] = useState(false);
  const [requestLogin, setRequestLogin] = useState(false);
  // console.log('Open', open);

//   useClickAway(ref, () => {
//     setOpen(false);
//   });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      setTempService(service);
      if (service === 0) {
        setChooseStore(true);
        setChooseLocation(false);
      } else {
        setChooseStore(false);
        setChooseLocation(true);
      }
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  useEffect(() => {
   setChooseLocation(false);
   setChooseStore(false);
  }, []);

  useMount(() => {   
    if (chooseLocation) {
      setChooseLocation(false);
    }
    if (chooseStore) {
      setChooseStore(false);
    }
  });

  return (
    <>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={backdropVariants}
            initial="hidden h-0"
            animate="visible"
            exit="hidden"
            className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10"
          >
            <motion.div
              ref={ref}
              variants={sidebarVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className="absolute right-0 h-full flex flex-col w-full md:w-1/4 bg-white"
            >
              <div key="header" className="px-4 py-6 flex items-center justify-between bg-base">
                <div className={open && 'hidden text-sm tracking-tight text-gray-500'}>
                  {store && tempService === 0 ? (
                    <div className="flex flex-row">
                        <div className="text-white font-medium">
                            {t('pickup_at')}
                        </div>
                        <div className="text-white font-medium ml-1">
                        {store && store.name}
                        </div>
                    </div>
                  ) : (store && 
                    <div className={open && store && location ? 'flex flex-row' : 'hidden'}>
                      <div className="text-white font-medium">
                        {t('delivery_to')}
                      </div>
                      <div className="text-white font-medium ml-1">
                       {location.location?.name}
                      </div>
                      <div className="text-white font-medium ml-1">
                       {t('from')}
                      </div>
                      <div className="text-white font-medium ml-1">
                       {store && store.name}
                      </div>
                    </div>
                  )}
                  <Link
                    onClick={() => setOpen(false)}
                    to={`/stores/${store && store.id}`}
                    className="text-sm text-white hover:text-accent font-medium transform duration-500 ease-in-out underline"
                  >
                    {t('continue_shopping')}
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="hover:opacity-75 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} className="text-2xl text-white" />
                </button>
              </div>                         
              <AnimatePresence>
                (
                  <motion.div                    
                    key="buttons"
                    initial={{ opacity: 0, y: 160 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 160 }}
                    transition={{
                      duration: 1,
                      delay: 1,
                      type: 'spring',
                    }}
                    className="flex space-x-4 p-4 bg-white text-white text-xs"
                  >
                    <div 
                      role="button"  
                      tabIndex={0}            
                      onClick={() => {
                      setTempService(0);
                      setChooseStore(true);
                      setChooseLocation(false);
                      // localStorage.setItem(`${envConfig.StorageKey}-service`, '0');
                     }}
                      className={`${tempService === 0 ? 'ring ring-5 ring-offset-2 ring-accent' : ''} bg-base flex flex-row rounded-xl border items-center text-white p-4 w-full md:w-1/2 my-4 md:my-2 md:ml-4 shadow h-14 md:h-14`}
                    >
                      <FontAwesomeIcon className="text-2xl md:text-2xl mt-2 mr-6 md:mr-2 w-1/3" icon={faBagsShopping} />      
                      <div className="flex flex-col">
                        <div className="w-full font-semibold text-lg md:text-2xl">{t('pickup')}</div>
                      </div>
                    </div>
                    <div 
                      role="button"  
                      tabIndex={-1}  
                      onClick={async () => {
                      setTempService(1);
                      setChooseLocation(true);
                      setChooseStore(false);
                      // await localStorage.setItem(`${envConfig.StorageKey}-service`, '1');
                  }}
                      className={`${tempService === 1 ? 'ring ring-5 ring-offset-2 ring-accent' : ''} bg-base flex flex-row rounded-xl border items-center text-white p-4 w-full md:w-1/2 my-4 md:my-2 md:ml-4 shadow h-14 md:h-14`}
                    >
                    <FontAwesomeIcon className="text-2xl md:text-2xl mt-2 mr-6 md:mr-2 w-1/3" icon={faBus} />      
                    <div className="flex flex-col">
                        <div className="w-full font-semibold text-lg md:text-2xl">{t('delivery')}</div>
                    </div>
                    </div>
                  </motion.div>
                )
              </AnimatePresence>
            <div 
              hidden={!chooseStore}
              className="flex flex-col flex-1 overflow-auto p-4"
            >
             <AnimatePresence>  
              {chooseStore && (
              
              <motion.div
                initial={{ opacity: 0, y: 160 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 160 }}
                transition={{
                  duration: 1,
                  delay: 1,
                  type: 'spring',
                }}
                className="flex space-x-4 p-4 bg-white"
              >
                    <div ref={ref} className="mx-auto max-w-md px-4">
                    <div className="flex items-start space-x-4 p-4 bg-white">
                        <div>
                          <div className="text-md tracking-tight">
                            {t('select_preferred_store')}
                          </div>
                        </div>
                    </div>
                        <div className="relative flex flex-col w-full bg-white">                        
                        {stores.state === 'loading' && (
                            <div className=" flex flex-1 flex-col items-center justify-center h-20">
                            <Loader />
                            </div>
                        )}
                        <div className="flex flex-col p-0 space-y-2">
                            {stores?.state === 'hasValue' && _.map(
                            _.filter(stores.contents.data.data.results,
                                (temp) => temp.locations[0].isPickUpAvailable),
                            (s) => (
                                <button
                                  type="button"
                                  key={s.id}
                                  className="text-left text-sm p-4 bg-gray-100 hover:border border-accent border-3 transition
                                   duration-500 ease-linear ring-0 ring-offset-0 focus:ring-0 outline-none focus:outline-none"
                                  onClick={async () => {
                                    setChooseStore(false);
                                    setOpen(false);
                                    if (store && store.id !== s.id) {
                                    await setStore(s);
                                    setService(tempService);
                                    await localStorage.setItem(`${envConfig.StorageKey}-service`, '0');
                                    await setOrder([]);
                                    await setLocation({ ...location, location: null });
                                    await localStorage.setItem(
                                        `${envConfig.StorageKey}-store`,
                                        JSON.stringify(s)
                                    );
                                    await localStorage.removeItem(`${envConfig.StorageKey}-order`);
                                    await history.push(`/stores/${s.id}`);
                                    } else {
                                    await setStore(s);
                                    setService(tempService);
                                    await localStorage.setItem(`${envConfig.StorageKey}-service`, '0');
                                    await setLocation({ ...location, location: null });
                                    await localStorage.setItem(
                                        `${envConfig.StorageKey}-store`,
                                        JSON.stringify(s)
                                    );
                                    history.push(`/stores/${s.id}`);
                                    }
                                }}
                                >
                                <div className="flex flex-row items-center">
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className={
                                  store?.id === s.id ? 'text-amber-400' : 'text-gray-300'
                                  }
                                />
                                <div className="flex flex-col text-left truncate mx-4">
                                <div className="font-semibold tracking-tight text-base">
                                    {s.name}
                                </div>
                                <div className="text-xs">{s.locations[0].address1}, {s.locations[0].city} {s.locations[0].zipcode}</div>
                                </div>
                                </div>
                                </button>
                            )
                            )}
                        </div>
                        </div>
                    </div>
              </motion.div>)}
              {chooseLocation && user && (            
              <motion.div
                initial={{ opacity: 0, y: 160 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 160 }}
                transition={{
                  duration: 1,
                  delay: 1,
                  type: 'spring',
                }}
                className="flex space-x-4 p-4 bg-white"
              >
                    <div ref={ref} className="px-4">
                      <div className="flex flex-col w-full bg-white">    
                        <LocationsList />
                      </div>
                    </div>
              </motion.div>)}
              {!user && chooseLocation &&
              (<motion.div
                initial={{ opacity: 0, y: 160 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 160 }}
                transition={{
                  duration: 1,
                  delay: 1,
                  type: 'spring',
                }}
                className="flex space-x-4 p-4 bg-white"
              >
                  <div className="h-24 flex flex-col items-center justify-center">
                  <div>
                    <div className="text-sm tracking-tight mx-2 mb-4">
                      {t('must_sign_in')}
                    </div>
                  </div>
                  <div className="hidden md:flex space-x-2 pl-4">
                  <NavLink
                    to="/login"
                    onClick={() => {
                      setOpen(false);
                      setChooseStore(false);
                    }}
                    className="border rounded-md border-accent bg-accent text-white h-auto items-center w-auto px-4 py-2"
                  >
                    {t('signin')}
                  </NavLink>
                  <NavLink
                    to="/register"
                    onClick={() => {
                      setOpen(false);
                      setChooseStore(false);
                    }}
                    className="ml-10 border rounded-md border-accent bg-accent text-white h-auto items-center w-auto px-4 py-2"
                  >
                    {t('signup')}
                  </NavLink>
                  </div>
                  </div>
               </motion.div>
              )}
             </AnimatePresence>
            </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default ServicesDrawer;

import React from 'react';

function Price({ price, hasRandomWeight }) {
  if (hasRandomWeight) {
    return (
      <div className="font-extrabold font-light tracking-tight py-2">
        <div className="">${parseFloat(price).toFixed(2)} lb.</div>
      </div>
    );
  }

  return (
    <div className="font-extrabold font-light tracking-tight py-2">
      ${parseFloat(price).toFixed(2)}
    </div>
  );
}

export default Price;

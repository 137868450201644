import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  mapOrderStatus,
  mapOrderType,
  toLocalTime
} from '../../../../../../../../functions';
import { orderDetailsModalAtom, orderDetailsAtom } from '../../../../../../../../atoms/Atoms';
import SubstituteRequests from './SubstituteRequests';
import OrderProducts from './OrderProducts';
import AddProducts from './AddProducts';

dayjs.locale('en');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function OrderDetails() {
  const { i18n, t } = useTranslation();
  const orderDetailsModal = useRecoilValue(orderDetailsModalAtom);
  const orderDetails = useRecoilValue(orderDetailsAtom);
  const [order, setOrder] = useState(null);
  const [current, setCurrent] = useState(`${t('products')}`);

  useEffect(() => {
    if (orderDetailsModal) {
      setOrder(orderDetailsModal);
    } else if (orderDetails) {
      setOrder(orderDetails);
    }
  }, [orderDetailsModal, orderDetails]);

  return (
    <section className="flex flex-1 flex-col overflow-y-auto rounded-b-xl">
      {/* Details */}
      <div className="grid grid-cols-1 md:grid-cols-3 text-gray-600 text-sm tracking-tight divide-y md:divide-y-0 md:divide-x divide-gray-200 bg-gray-50 ">
        <div className="divide-y divide-gray-200">
          <div className="py-2 px-4">
            <div className="text-xs">{t('store')}:</div>
            <div className="text-gray-800">{order && order.businessName}</div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('order_client')}:</div>
            <div className="text-gray-800">{order && order.customerName}</div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('order_service')}:</div>
            <div className="text-gray-800">
              {order && t(mapOrderType(order.type))}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('order_date')}</div>
            <div className="text-gray-800 truncate">
              {order &&
                toLocalTime(order.endSlotTime)
                  .locale(i18n.language)
                  .format('dddd, D [de] MMMM, YYYY h:mm A')}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('order_payment')}:</div>
            <div className="text-gray-800">
              {order && (
                <span>
                  {order.nickName ? order.nickName : order.paymentName}
                </span>
              )}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('status')}:</div>
            <div className="text-gray-800">
              {order && t(mapOrderStatus(order.status))}
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200">
          <div className="py-2 px-4">
            <div className="text-xs">{t('order_code')}:</div>
            <div className="text-gray-800">
              {order && order.confirmationCode}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('items')}:</div>
            <div className="text-gray-800">{order && order.itemCount}</div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('subtotal')}:</div>
            <div className="text-gray-800">
              ${order && order.productsTotal.toFixed(2)}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('municipal_tax')}:</div>
            <div className="text-gray-800">
              ${order && order.municipalTax.toFixed(2)}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('state_tax')}:</div>
            <div className="text-gray-800">
              ${order && order.stateTax.toFixed(2)}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('order_service_charge')}:</div>
            <div className="text-gray-800">
              ${order && (order.processingFee + order.deliveryTotal).toFixed(2)}
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200">
          <div className="py-2 px-4">
            <div className="text-xs font-bold">{t('total')}:</div>
            <div className="text-gray-800 font-bold">
              ${order && order.orderTotal.toFixed(2)}
            </div>
          </div>
          <div className="py-2 px-4">
            <div className="text-xs">{t('note')}:</div>
            <div className="text-gray-800">
              {order && order.specialInstruction
                ? order.specialInstruction
                : `${t('none')}`}
            </div>
          </div>
        </div>
      </div>
      {/* Tabs Nav */}
      <div className="md:sticky md:top-0 md:z-50 grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x divide-gray-200 bg-gray-100 shadow-lg">
        <button
          type="button"
          className={`${
            current === t('products')}
              ? 'bg-gray-500 hover:bg-gray-400 text-white'
              : 'bg-gray-100 hover:bg-gray-200 text-gray-600'
          } h-14 uppercase flex items-center justify-between md:justify-center space-x-1 p-4 text-xs font-semibold outline-none focus:outline-none ring-0 focus-ring-0 transition duration-500 ease-linear`}
          onClick={() => setCurrent(`${t('products')}`)}
        >
          <div>{t('products')}</div>
          <div className="flex items-center justify-center h-6 w-6 text-white bg-accent rounded-full">
            {order && order.items.length}
          </div>
        </button>
        {/* <button
          type="button"
          className={`${
            current === 'Sustitutos'
              ? 'bg-gray-500 hover:bg-gray-400 text-white'
              : 'bg-gray-100 hover:bg-gray-200 text-gray-600'
          } h-14 uppercase flex items-center justify-between
          md:justify-center space-x-1 p-4 text-xs font-semibold
          outline-none focus:outline-none ring-0 focus-ring-0
          transition duration-500 ease-linear`}
          onClick={() => setCurrent('Sustitutos')}
        >
          Sustitutos
        </button> */}
        <button
          type="button"
          className={`${
            current === `${t('add_products')}`
              ? 'bg-gray-500 hover:bg-gray-400 text-white'
              : 'bg-gray-100 hover:bg-gray-200 text-gray-600'
          } h-14 uppercase flex items-center justify-between md:justify-center space-x-1 p-4 text-xs font-semibold outline-none focus:outline-none ring-0 focus-ring-0 transition duration-500 ease-linear
          ${order?.canEditItems ? '' : 'hidden'}`}
          onClick={() => setCurrent(`${t('add_products')}`)}
        >
         {t('add_products')}
        </button>
      </div>
      {/* Tabs Content */}
      {current === `${t('products')}` && <OrderProducts />}
      {/* {current === 'Sustitutos' && <SubstituteRequests />} */}
      {current === `${t('add_products')}` && <AddProducts />}
    </section>
  );
}

export default OrderDetails;
